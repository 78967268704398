import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Masonry from '@mui/lab/Masonry';
import { Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './i18n.js';



const items = [
  { height: 350, imageUrl: '/images/jachting_photos/4.jpeg', link: "/country/croatia", text: 'Croatia' },
  { height: 160, imageUrl: '/images/countries/greece.jpg', link: '/country/greece', text: 'Greece' },
  { height: 350, imageUrl: '/images/countries/turkey.jpg', link: '/country/Turkey', text: 'Turkey' },
  { height: 160, imageUrl: '/images/countries/carribean.jpg', link: '/country/Caribbean', text: 'Caribbean' },
  { height: 350, imageUrl: '/images/countries/spain.jpg', link: '/country/Spain', text: 'Spain' },
  { height: 350, imageUrl: '/images/jachting_photos/1.jpeg', link: '/country/norway', text: 'Norway' },
  { height: 160, imageUrl: '/images/countries/italy.jpg', link: '/country/Italy', text: 'Italy' },
  { height: 160, imageUrl: '/images/countries/slovenia.jpg', link: '/country/Slovenia', text: 'Slovenia' },
  // Add more items as needed...
];

const Item = styled(Box)(({ imageUrl }) => ({
  backgroundColor: '#fff',
  backgroundPosition: 'center',
  padding: 0,
  textAlign: 'center',
  color: '#000',
  position: 'relative',
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: 'cover',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  borderRadius: 8, // Adding rounded corners
  '@media (max-width:600px)': {
    height: 160, // Fixed height for mobile screens
  },
}));

const TextOverlay = styled('div')({
  position: 'absolute',
  top: 8,
  right: 8,
  color: 'white',
  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
  fontSize: '1.5rem', // Adjust font size as needed
});

export function Mansonry() {
  const isXs = useMediaQuery('(max-width:600px)');
  const isSm = useMediaQuery('(max-width:960px)');
  const isMd = useMediaQuery('(max-width:1280px)');
  const { t } = useTranslation();

  const getColumns = () => {
    if (isXs) return 1;
    if (isSm) return 2;
    if (isMd) return 3;
    return 4;
  };

  return (
    <Box sx={{ width: '90%', minHeight: 600, margin: '0 auto' }}>
      <Masonry columns={getColumns()} spacing={2}>
        {items.map((item, index) => (
          <a href={item.link} key={index} style={{ textDecoration: 'none' }}>
            <Item sx={{ height: isXs ? 160 : item.height }} imageUrl={item.imageUrl}>
              <Typography sx={{ fontSize: 30 }}>
                <TextOverlay>{t(item.text)}</TextOverlay>
              </Typography>
            </Item>
          </a>
        ))}
      </Masonry>
    </Box>
  );
}
