import React, { useState, useEffect, useRef, Fragment } from 'react';

import Slider, { slickGoTo } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';

import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import axios from 'axios';
import { useParams } from 'react-router-dom'; // Import the useParams hook
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import FmdGoodTwoToneIcon from '@mui/icons-material/FmdGoodTwoTone';
//import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css'; // Import lightbox's CSS

import SailingTwoToneIcon from '@mui/icons-material/SailingTwoTone';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import WcIcon from '@mui/icons-material/Wc';
import BathroomTwoToneIcon from '@mui/icons-material/BathroomTwoTone';
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';

import Button from '@mui/material/Button';

import dayjs, { Dayjs } from 'dayjs';

import { useNavigate } from 'react-router-dom';

import Textarea from '@mui/joy/Textarea';

import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';

//table

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';


import TextField from '@mui/material/TextField';

//favorites

import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
//toast
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';




import Chip from '@mui/material/Chip';


import { Helmet, HelmetProvider } from 'react-helmet-async';


import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


import CardContent from '@mui/material/CardContent';

//translate
import { useTranslation } from 'react-i18next';
import './i18n.js';
import i18n from 'i18next';


import { InputAdornment, MenuItem, Select, FormControl, InputLabel } from '@mui/material';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

//icons
import HotelIcon from '@mui/icons-material/Hotel';
import HotelTwoToneIcon from '@mui/icons-material/HotelTwoTone';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import MeetingRoomTwoToneIcon from '@mui/icons-material/MeetingRoomTwoTone';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import BoltIcon from '@mui/icons-material/Bolt';
import OfflineBoltTwoToneIcon from '@mui/icons-material/OfflineBoltTwoTone';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import LocalGasStationTwoToneIcon from '@mui/icons-material/LocalGasStationTwoTone';
import StraightenTwoToneIcon from '@mui/icons-material/StraightenTwoTone';
import AssignmentReturnedTwoToneIcon from '@mui/icons-material/AssignmentReturnedTwoTone';

//translate 
import translateText from './translate';

import EventBus from './EventBus';



const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};




//const datesCarousel = ["cena 1", "cena 2", "cena 3", "cena 4", "cena 5", "cena 6", "cena 7", "cena 8", "cena 9", "cena 10"];

const getNextSaturday = (date) => {
  let nextSaturday = dayjs(date);
  while (nextSaturday.day() !== 6) {
    nextSaturday = nextSaturday.add(1, 'day');
  }
  return nextSaturday;
}

const generateWeeks = () => {
  const weeks = [];
  let startWeek = getNextSaturday(new Date());
  const endWeek = startWeek.add(12, 'month');

  while (startWeek.isBefore(endWeek)) {
    const endOfWeek = startWeek.add(6, 'day');
    const correctEndOfWeek = endOfWeek.add(1, 'day');
    weeks.push(startWeek.format('DD.MM.YYYY') + ' - ' + correctEndOfWeek.format('DD.MM.YYYY'));
    startWeek = endOfWeek.add(1, 'day');
  }
  console.log(weeks)
  return weeks;
}

const datesCarousel = generateWeeks();



const BoatDetailsNoDate = (props) => {



  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleOpenBusinessModal = () => {
    EventBus.emit('openBusinessModal');
  };

  const handleOpenGDPRModal = () => {
    EventBus.emit('openGDPRModal');
  };

  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  console.log("zvoleny jazyk" + currentLanguage)
  const [nameClient, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [notes, setNotes] = useState('');
  const [prices, setPrices] = useState({});
  const countryCodes = [
    { code: '+421', country: 'Slovakia' },
    { code: '+420', country: 'Czech Republic' },
    { code: '+49', country: 'Deutschland' },
    { code: '+43', country: 'Österreich' },
    { code: '+1', country: 'USA' },
    { code: '+44', country: 'UK' },
    { code: '+', country: 'other' },

    // ... add more countries here
  ];
  const [mobile, setMobile] = useState('');
  const [emailInput, setEmail] = useState('');
  const [paymentWay, setPaymentWay] = useState("bankTransfer");

  const [countryCode, setCountryCode] = useState(countryCodes[0].code);
  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };

  const steps = [t('Personal Details'), t('Selected'), t('Final step')];
  // State for the name



  // Function to handle changes to the name input
  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleSurnameChange = (event) => {
    setSurname(event.target.value);
  };
  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };
  const handleMobileChange = (event) => {
    setMobile(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePaymentWayChange = (event) => {
    setPaymentWay(event.target.value);
  };


  const [paymentPlanAPI, setPaymentPlanAPI] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false); //GDPR

  const [boat, setBoat] = useState({});
  const { boat_id } = useParams(); // Use the useParams hook to get route parameters
  const { start_date } = useParams(); // Use the useParams hook to get route parameters
  const { end_date } = useParams(); // Use the useParams hook to get route parameters
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    if (props.email) {
      setOpen(true);
    } else {
      setShowLoginPrompt(true);
      setTimeout(() => setShowLoginPrompt(false), 3000); // Hide the popup after 3 seconds
      //setOpen(true); //TODO toto nedavaj na PROD ! lebo to pojde bez loginu!
    }
  }; 
  
  const styleMaps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '60%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 6,
  };
  const [openMaps, setOpenMaps] = React.useState(false);
  const handleOpenMaps = () => setOpenMaps(true);
  const handleCloseMaps = () => setOpenMaps(false);

  const handleClose = () => setOpen(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '20%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowX: 'auto' // Add horizontal scrollbar when needed
  };


  const [isLoading, setIsLoading] = useState(true);
  const [paymentPlanSelected, setPaymentPlan] = useState([]);


  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const dateStart = new Date(start_date * 1);
  const dayStart = dayjs(dateStart).format('DD.MM.YYYY');
  //const selectedDateIndex = datesCarousel.findIndex(dateRange => dateRange.startsWith(dayStart));

  const dateEnd = new Date(end_date * 1);
  const dayEnd = dayjs(dateEnd).format('DD.MM.YYYY');


  const getNextSaturday = (date) => {
    let nextSaturday = dayjs(date);
    // Ak je dnes sobota (day() vracia 6 pre sobotu), vráťte dnešný deň,
    // inak pridajte počet dní, ktoré chýbajú do nasledujúcej soboty
    if (nextSaturday.day() !== 6) {
      nextSaturday = nextSaturday.add(6 - nextSaturday.day(), 'day');
    }
    return nextSaturday;
  };

  // Použitie funkcie getNextSaturday na získanie nasledujúcej soboty (alebo dneška, ak je dnes sobota)
  const nextSaturday = getNextSaturday(new Date());

  // Generovanie týždňov ako v predchádzajúcom kóde
  const weeks = generateWeeks(); // Predpokladáme, že táto funkcia už existuje a generuje pole týždňov

  // Získanie indexu pre nextSaturday v poli weeks
  const initialDateIndex = weeks.findIndex(week => {
    const [start] = week.split(' - ').map(day => dayjs(day, 'DD.MM.YYYY'));
    return start.isSame(nextSaturday, 'day');
  });


  const [selectedDateIndex, setSelectedDateIndex] = useState(initialDateIndex);
  const [currentSlide, setCurrentSlide] = useState(initialDateIndex);

  const fetchPricesForVisibleSlides = async (currentIndex) => {
    const indicesToFetch = [currentIndex - 2, currentIndex - 1, currentIndex, currentIndex + 1, currentIndex + 2];

    indicesToFetch.forEach(async (index) => {
      if (!prices[index] && datesCarousel[index]) {
        try {
          const [startDate, endDate] = datesCarousel[index].split(" - ");
          const response = await axios.post('/getPriceAPI', { boat_id, startDate, endDate }).then(response => {
            console.log("Axios response:", response.data);
            const priceData = response.data.split(";");
            const clientPrice = priceData[0];
            const priceListPrice = priceData[1];
            const paymentPlanString = priceData[2].replace(/'/g, '"');
            //const paymentPlanString = BoatData.paymentPlan.replace(/'/g, '"');
            let paymentPlanSelected;
            try {

              paymentPlanSelected = JSON.parse(paymentPlanAPI[0].replace(/'/g, '"'));
              //setPaymentPlan(paymentPlanSelected); // Update the payment plan state
              setPaymentPlan(paymentPlanSelected)
            } catch (error) {
              console.error("Error parsing payment plan:", error);
              setPaymentPlan([]); // Reset to an empty array or a default value
            }




            console.log("Price Data:", priceData);
            console.log("Client Price:", clientPrice);
            console.log("Price List Price:", priceListPrice);
            console.log("Payment Plan:", paymentPlanSelected);
            setPrices(prevPrices => ({ ...prevPrices, [index]: clientPrice }));
          }).catch(error => {
            console.error("Axios error:", error);
            setPrices(prevPrices => ({ ...prevPrices, [index]: "Unavailable" }));
          });




        } catch (error) {
          console.error("Error fetching price for index", index, error);
          setPrices(prevPrices => ({ ...prevPrices, [index]: "Unavailable" }));
        }
      }
    });
  };

  // Call this function initially and when selectedDateIndex changes
  useEffect(() => {
    fetchPricesForVisibleSlides(selectedDateIndex);
  }, [selectedDateIndex]);


  const sliderRef = useRef(null);



  const handleNextArrowClick = () => {
    const newIndex = selectedDateIndex + 1;

    setSelectedDateIndex(newIndex, true);

    sliderRef.current.slickGoTo(newIndex, true);
    setCardClicked(false);
  };

  const handlePrevArrowClick = () => {
    console.log("Prev Arrow Clicked");
    const newIndex = selectedDateIndex - 1;
    setSelectedDateIndex(newIndex);
    sliderRef.current.slickGoTo(newIndex, true);
    setCardClicked(false);


  };



  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ArrowForwardIosIcon
        className={className}
        style={{ ...style, display: "block", color: "black", marginRight: "30px", color: "#035675", fontSize: 50 }}
        onClick={() => {
          handleNextArrowClick();
          onClick && onClick();
        }}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ArrowBackIosIcon
        className={className}
        style={{ ...style, display: "block", color: "black", marginLeft: "30px", zIndex: 9999, color: "#035675", fontSize: 50 }}
        onClick={() => {
          handlePrevArrowClick();
          onClick && onClick();
        }}
      />
    );
  }


  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 300,
    centerMode: true,
    swipe: false,
    draggable: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: selectedDateIndex,
    arrows: true, // Set to true to show arrows
    nextArrow: <SampleNextArrow handleNextArrowClick={handleNextArrowClick} />,
    prevArrow: <SamplePrevArrow handlePrevArrowClick={handlePrevArrowClick} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
          arrows: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: true,
          centerMode: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          centerMode: true,
        }
      }
    ]
  };

  const [cardClicked, setCardClicked] = useState(false);

  function srcset(image, size, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
  }



  useEffect(() => {
    const fetchBoatDetailsAndTranslate = async () => {
      try {
        const response = await axios.get(`/BoatDetailsNoDate/${boat_id}`);
        const boatData = response.data;
        console.log("!!!!!!@@@@@@@@@@@@@@@@@ " + boatData.paymentPlan);
        setPaymentPlanAPI(boatData.paymentPlan)

        setIsLoading(false);
        setBoat(response.data);
        setClickedCardIndex(initialDateIndex); // Highlight the card corresponding to the initial date index
        setSelectedPrice(response.data.price); // Set the selected price to the fetched boat's price

        // Preklad popisov pre optional_services
        const translatedOptionalDescriptions = await Promise.all(
          boatData.optional_services.map(service => translateText(service[3], currentLanguage))
        );

        // Preklad popisov pre mandatory_services
        const translatedMandatoryDescriptions = await Promise.all(
          boatData.mandatory_services.map(service => translateText(service[3], currentLanguage))
        );

        // Preklad popisov pre additional_equipment
        const translatedEquipmentDescriptions = await Promise.all(
          boatData.additional_equipment.map(equipment => translateText(equipment[3], currentLanguage))
        );

        // Aktualizácia štruktúry dát s preloženými popismi
        const updatedBoatData = {
          ...boatData,
          optional_services: boatData.optional_services.map((service, index) => ({
            ...service,
            3: translatedOptionalDescriptions[index] || service[3],
          })),
          mandatory_services: boatData.mandatory_services.map((service, index) => ({
            ...service,
            3: translatedMandatoryDescriptions[index] || service[3],
          })),
          additional_equipment: boatData.additional_equipment.map((equipment, index) => ({
            ...equipment,
            3: translatedEquipmentDescriptions[index] || equipment[3],
          })),
        };

        setBoat(updatedBoatData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching boat details:", error);
        setIsLoading(false);
      }
    };

    fetchBoatDetailsAndTranslate();
  }, [boat_id, initialDateIndex, start_date, end_date, currentLanguage]);



  // Dependency array should contain boat_id directly
  const [showPopup, setShowPopup] = useState(false);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);




  const handleSubmit = async () => {
    // Extract the selected mandatory services
    setIsButtonClicked(true);
    const selectedMandatoryServices = Object.keys(checkedRowsMandatory)
      .filter(key => checkedRowsMandatory[key])
      .map(key => boat.mandatory_services[key]);

    const selectedEquipment = Object.keys(checkedRowsEquipment)
      .filter(key => checkedRowsEquipment[key])
      .map(key => boat.additional_equipment[key]);

    // Extract the selected optional services
    const selectedOptionalServices = Object.keys(checkedRows)
      .filter(key => checkedRows[key])
      .map(key => boat.optional_services[key]);

    const priceBook = parseFloat(totalOnlinePayment || 0) + parseFloat(selectedPrice || 0);

    const bookingData = {
      name: nameClient,
      surname: surname,
      mobile: countryCode + mobile,
      email: emailInput,
      payment: paymentWay,
      price: priceBook,
      periodFrom: selectedStartDate,
      periodTo: selectedEndDate,
      boat_id: boat_id,
      picture: boat.boatPicture,
      boatName: boat.boatName,
      checkedRowsMandatory: selectedMandatoryServices,
      checkedRows: selectedOptionalServices, //optional services
      checkedRowsEquipment: selectedEquipment,
      clientLanguage: currentLanguage,




    };


    setLoading(true);


    try {
      console.log(bookingData)
      const response = await axios.post('/book', bookingData);
      console.log(response.data);



      if (response.data === 'success') {
        setShowSuccessModal(true);


      } else {
        <div style={{ position: "fixed", bottom: 70, right: 30, backgroundColor: "lightcoral", padding: 10, borderRadius: 5 }}>
          ERROR pls contact us
        </div>
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error submitting booking:", error);
      // Handle the error here (e.g., show an error message)
    }
  };

  const handleCheckboxChangeGDPR = (event) => { // Step 2
    setIsChecked(event.target.checked);
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  console.log(boat.mandatory_services)
  const totalPrice = parseFloat(boat.price);



  //optional checkboxes
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [checkedRows, setCheckedRows] = useState({});
  const handleCheckboxChange = (index) => {
    setCheckedRows(prevCheckedRows => ({
      ...prevCheckedRows,
      [index]: !prevCheckedRows[index]
    }));
  };
  const processAndDisplaySelectedData = () => {
    const selectedServices = boat.optional_services.filter((_, index) => checkedRows[index]);
    // Do something with selectedServices, like displaying it or saving it
  };

  //optional checkboxes


  //equip checkboxes
  const labelEquipment = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [checkedRowsEquipment, setCheckedRowsEquipment] = useState({});
  const handleCheckboxChangeEquipemnt = (index) => {
    setCheckedRowsEquipment(prevCheckedRowsEquipment => ({
      ...prevCheckedRowsEquipment,
      [index]: !prevCheckedRowsEquipment[index]
    }));
  };

  //equip checkboxes
  const labelMandatory = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [checkedRowsMandatory, setCheckedRowsMandatory] = useState({});

  useEffect(() => {
    if (boat.mandatory_services) {
      const allChecked = boat.mandatory_services.reduce((acc, _, index) => {
        acc[index] = true; // Set each index to true
        return acc;
      }, {});
      setCheckedRowsMandatory(allChecked);
    }
  }, [boat.mandatory_services]);

  const handleCheckboxChangeMandatory = (index) => {
    setCheckedRowsMandatory(prevCheckedRowsMandatory => ({
      ...prevCheckedRowsMandatory,
      [index]: !prevCheckedRowsMandatory[index]
    }));
  };
  const processAndDisplaySelectedDataMandatory = () => {
    const selectedServicesMandatory = boat.mandatory_services.filter((_, index) => checkedRowsMandatory[index]);
    // Do something with selectedServices, like displaying it or saving it
  };


  const [clickedCardIndex, setClickedCardIndex] = useState(null);



  const [totalOnlinePayment, setTotalOnlinePayment] = useState(0);
  const [totalBasePayment, setTotalBasePayment] = useState(0);

  useEffect(() => {
    let newOnlineTotal = 0;
    let newBaseTotal = 0;

    Object.keys(checkedRows).forEach(index => {
      if (checkedRows[index]) {
        const price = parseFloat(boat.optional_services[index][0]);
        if (boat.optional_services[index][1] === 'ADVANCE_PAYMENT') {
          newOnlineTotal += price;
        } else if (boat.optional_services[index][1] === 'SEPARATE_PAYMENT') {
          newBaseTotal += price;
        }
      }
    });

    // Calculate totals for mandatory services
    Object.keys(checkedRowsMandatory).forEach(index => {
      if (checkedRowsMandatory[index]) {
        const price = parseFloat(boat.mandatory_services[index][0]);
        if (boat.mandatory_services[index][1] === 'ADVANCE_PAYMENT') {
          newOnlineTotal += price;
        } else if (boat.mandatory_services[index][1] === 'SEPARATE_PAYMENT') {
          newBaseTotal += price;
        }
      }
    });

    // Calculate totals for mandatory services
    Object.keys(checkedRowsEquipment).forEach(index => {
      if (checkedRowsEquipment[index]) {
        const price = parseFloat(boat.additional_equipment[index][0]);
        if (boat.additional_equipment[index][1] === 'ADVANCE_PAYMENT') {
          newOnlineTotal += price;
        } else if (boat.additional_equipment[index][1] === 'SEPARATE_PAYMENT') {
          newBaseTotal += price;
        }
      }
    });

    setTotalOnlinePayment(newOnlineTotal);
    setTotalBasePayment(newBaseTotal);
  }, [checkedRows, checkedRowsMandatory, checkedRowsEquipment, boat.optional_services, boat.mandatory_services, boat.additional_equipment]);

  const stepContent = [

    <div>
      <br></br><br></br>
      <TextField
        id="nameClient"
        label={t("Name")}
        variant="outlined"
        value={nameClient}
        onChange={handleNameChange} // Set up the onChange handler
        required
        style={{ marginRight: 40 }}
      />
      <TextField
        id="Surname"
        label={t("Surname")}
        variant="outlined"
        value={surname}
        onChange={handleSurnameChange} // Set up the onChange handler
        required
        style={{ marginRight: 40 }}
      />

      <FormControl style={{ marginRight: 40, minWidth: 100 }}>
        <InputLabel id="country-code-label">Code</InputLabel>
        <Select
          labelId="country-code-label"
          id="country-code-select"
          value={countryCode}
          label="Code"
          onChange={handleCountryCodeChange}
          required
        >
          {countryCodes.map((item) => (
            <MenuItem key={item.code} value={item.code}>
              {item.country} ({item.code})
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        id="Mobile"
        label="Mobile"
        variant="outlined"
        value={mobile}
        required
        onChange={handleMobileChange}
        InputProps={{
          startAdornment: <InputAdornment position="start">{countryCode}</InputAdornment>,
        }}
        style={{ marginRight: 40 }}
      />

      <h3>{t("Notes for order")}</h3>
      <Textarea
        id="Notes"
        label={t("Notes")}
        variant="outlined"
        value={notes}
        required
        onChange={handleNotesChange} // Set up the onChange handler
        style={{ marginRight: 40 }}
      />



    </div>,

    <div>
      <br></br>
      <h2> {t("Mandatory Services")}</h2>
      <br></br>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("Service")}</TableCell>
              <TableCell align="right">{t("Payment")}</TableCell>
              <TableCell align="right">{t("Price")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {Object.keys(checkedRowsMandatory).map(index => (
              checkedRowsMandatory[index] && (
                <TableRow key={index}>
                  <TableCell> {boat.mandatory_services[index][2]} </TableCell>

                  <TableCell align="right">{boat.mandatory_services[index][1] === 'SEPARATE_PAYMENT' ? 'In Base' :
                    boat.mandatory_services[index][1] === 'ADVANCE_PAYMENT' ? 'Online' :
                      boat.mandatory_services[index][1]} </TableCell>
                  <TableCell align="right">  {boat.mandatory_services[index][0]} EUR </TableCell>
                </TableRow>
              )
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <h2> {t("Aditional extras")}</h2>
      <br></br>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("Service")}</TableCell>
              <TableCell align="right">{t("Payment")}</TableCell>
              <TableCell align="right">{t("Price")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {Object.keys(checkedRowsEquipment).map(index => (
              checkedRowsEquipment[index] && (
                <TableRow key={index}>
                  <TableCell> {boat.additional_equipment[index][2]} </TableCell>

                  <TableCell align="right">{boat.additional_equipment[index][1] === 'SEPARATE_PAYMENT' ? 'In Base' :
                    boat.additional_equipment[index][1] === 'ADVANCE_PAYMENT' ? 'Online' :
                      boat.additional_equipment[index][1]} </TableCell>
                  <TableCell align="right">  {boat.additional_equipment[index][0]} EUR </TableCell>
                </TableRow>
              )
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <h2> {t("Optional Services")}</h2>
      <br></br>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("Service")}</TableCell>
              <TableCell align="right">{t("Payment")}</TableCell>
              <TableCell align="right">{t("Price")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {Object.keys(checkedRows).map(index => (
              checkedRows[index] && (
                <TableRow key={index}>
                  <TableCell> {t(boat.optional_services[index][2])} </TableCell>

                  <TableCell align="right">{boat.optional_services[index][1] === 'SEPARATE_PAYMENT' ? 'In Base' :
                    boat.optional_services[index][1] === 'ADVANCE_PAYMENT' ? 'Online' :
                      boat.optional_services[index][1]} </TableCell>
                  <TableCell align="right">  {boat.optional_services[index][0]} EUR </TableCell>
                </TableRow>
              )
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </div>,
    <div>
      <br></br><br></br><br></br>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("Total Online Payment")}</TableCell>
              <TableCell >{t("Total Base Payment")}</TableCell>
              <TableCell >{t("Deposit")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                {(parseFloat(totalOnlinePayment) + parseFloat(boat.price)).toFixed(2)} EUR
              </TableCell>
              <TableCell >{totalBasePayment.toFixed(2)} EUR</TableCell>
              <TableCell >{boat.deposit} EUR</TableCell>

            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>
      <p> {t("Some of the services or additional items listed on our website are currently displayed with prices per piece or per day. Please note that these prices are provisional and may be updated in the future based on specific circumstances, the number of days, or other factors. We'll contact you in that case.")} </p>
    </div>,
    // Repeat for more steps
  ];
  function convertDateToUnix(dateString) {
    const parts = dateString.split('.'); // Split the date by '.' to get [day, month, year]
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Months are 0-indexed in JavaScript (0 = January)
    const year = parseInt(parts[2], 10);

    // Create a Date object (Note: Month is 0-indexed, January is 0)
    const date = new Date(year, month, day);

    // Return the Unix timestamp in milliseconds
    return date.getTime();
  }

  const [rowLimit, setRowLimit] = useState(3);

  //bottom menu 
  const [value, setValue] = React.useState(0);

  const [selectedStartDate, setSelectedStartDate] = useState(dayStart);
  const [selectedEndDate, setSelectedEndDate] = useState(dayEnd);
  const [selectedPrice, setSelectedPrice] = useState(boat.price);
  const handleModalClose = () => {
    setShowSuccessModal(false); // Close the modal
    // Redirect after a short delay to allow the modal to close smoothly
    setTimeout(() => {
      window.location.href = '/';
    }, 500); // Adjust the delay as needed
  };
  const [isDateSelected, setIsDateSelected] = useState(false);
  const handleCardClick = (index) => {
    const priceToShow = prices[index];
    if (priceToShow && priceToShow !== "Unavailable") {
      const [newStart, newEnd] = datesCarousel[index].split(' - ');
      setSelectedStartDate(newStart);
      setSelectedEndDate(newEnd);
      setIsDateSelected(true);
      setSelectedPrice(prices[index] || boat.price);
      setSelectedDateIndex(index); // Set the clicked card as the selected index
      sliderRef.current.slickGoTo(index, true); // Navigate to the selected slide in the carousel
      setClickedCardIndex(index); // Update the clicked card index
      const newStartUnix = convertDateToUnix(newStart);
      const newEndUnix = convertDateToUnix(newEnd);


      window.location.href = `/details/${boat_id}/${newStartUnix}/${newEndUnix}/`;
    }
  };

  const bookingRef = useRef(null);
  const scrollToBooking = () => {
    if (isDateSelected) {
      // Ak je vybraný termín, posuňte sa k rezervačnému formuláru
      bookingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      // Ak nie je vybraný žiadny termín, zobrazte upozornenie
      alert(t("Please pick dates first from available dates."));
    }
  };
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const [isFavorited, setIsFavorited] = useState(false);


  const handleFavoriteClick = ({ }) => {
    setIsFavorited(!isFavorited);

    // Prepare the data to be sent
    const favoriteData = {
      boat_id: boat_id, // Convert to string
      email: props.email,// Directly use the email as it's already a string
      mainpicture: boat.boatPicture[0], // Use the first element of the array
      location: boat.location[0], // Use the first element of the array
      boatmodel: boat.yachtModelName[0], // Use the first element of the array
      boatName: boat.boatName[0], // Use the first element of the array
      flag: boat.flag[0] // Directly use the flag as it's already a string
    };

    console.log(favoriteData)




    // Concatenate boatmodel and boatName with a '|'


    // Make the POST request to the '/favorites' endpoint
    axios.post('/favorites', favoriteData)
      .then(response => {
        // Show success toast notification

        toast.success(t('Successfully added to favorites!'));

      })
      .catch(error => {
        // Show error toast notification
        toast.error(t('Error adding to favorites. Are you logged in?'));
        console.log(favoriteData)
        console.error('Error adding to favorites:', error);
      });
  };



  const handleCopyLink = () => {
    const url = window.location.href;

    navigator.clipboard.writeText(url)
      .then(() => {
        console.log('URL copied to clipboard');
        toast.success(t('Link Copied to clipboard!'));
        // Optionally, you can show a message to the user that the URL was copied
      })
      .catch(err => {
        console.error('Failed to copy the URL: ', err);
        // Optionally, handle the error (e.g., show an error message to the user)
      });
  };

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <title>{boat.yachtModelName + " | " + boat.boatName}</title>
        <meta name="description" content={`Jachta ${boat.boatName} na prenájom. Vaša vysnívaná dovolenka je na dosah jedného kliknutia.`} />
        <meta name="keywords" content="yacht, yachting, jachta, jachting" />
        <meta name="author" content="PapaSailor s.r.o" />
        <meta property="og:title" content={boat.yachtModelName + " | " + boat.boatName} />
        <meta property="og:description" content={`Jachta ${boat.boatName} na prenájom. Vaša vysnívaná dovolenka je na dosah jedného kliknutia.`} />
        <meta property="og:image" content={boat.boatPicture} />
        <meta property="og:url" content={`https://papasailor.com/detailsNoDate/${boat_id}`} />
        <meta name="twitter:title" content={boat.yachtModelName + " | " + boat.boatName} />
        <meta name="twitter:description" content="Prenájom jácht za najnižšie ceny!" />
        <meta name="twitter:image" content={boat.boatPicture} />
        <meta name="twitter:card" content={boat.yachtModelName + " | " + boat.boatName} />
      </Helmet>


      <Grid container spacing={3} display="flex" justifyContent="center" alignItems="center">


        {showPopup && (
          <div style={{ position: "fixed", bottom: 70, right: 30, backgroundColor: "lightgreen", padding: 10, borderRadius: 5 }}>
            {t("Booking finished successfully!")}
          </div>
        )}
        {showLoginPrompt && (
          <div style={{ position: "fixed", bottom: 70, right: 30, backgroundColor: "lightcoral", padding: 10, borderRadius: 5 }}>
            Please login first.
          </div>
        )}





        <Grid item xs={12} sm={12} md={12} lg={8} xl={8} display="flex" justifyContent="center" alignItems="center" sx={{ maxWidth: 1200 }}>



          <div style={{ width: '95%', marginLeft: -10 }}>
            {isLoading ? (
              <div style={{ position: 'relative', marginTop: '50px', display: 'inline-block', height: '150px', left: '-40px' }}>
                <CircularProgress
                  style={{
                    position: 'absolute',

                    zIndex: 1
                  }}
                  size={68}
                />
                <SailingTwoToneIcon
                  color="info"
                  style={{

                    position: 'absolute',
                    left: 13,
                    top: 10,
                    fontSize: 40,

                    zIndex: 2
                  }}
                />
              </div>
            ) : (


              <Box variant="outlined" style={{ width: '97%', paddingLeft: 20, marginTop: 20 }}>




                <Box style={{ textAlign: 'left', }} >
                  <Box style={{ display: 'flex', alignItems: 'center' }} sx={{ padding: '10px' }}>
                 
                    <h2>{boat.yachtModelName} | {boat.boatName} </h2>
                    <FmdGoodTwoToneIcon style={{ paddingRight: 5, color: '#57719f', marginLeft: 20 }} />

                    <Link style={{ textDecoration: 'none' }} onClick={handleOpenMaps}>
                      <Typography sx={{ fontSize: "0.9rem !important" }}>
                        <p>{boat.location}</p>
                      </Typography>
                    </Link>

                  </Box>
                  <Box>
                    <Typography sx={{ marginLeft: 1.5, marginTop: -5 }}>
                      <h5><img src={boat.flag} style={{ marginRight: "10px" }} alt="flag" />{boat.company_name}</h5>
                    </Typography>
                    <Tooltip placement="top-start">
                      <Chip onClick={handleFavoriteClick} label={t("To Favorites")} icon={<FavoriteTwoToneIcon />} color="info" variant="soft" />
                    </Tooltip>
                    <Tooltip placement="top-start">
                      <Chip onClick={handleCopyLink} label={t("Copy Link")} icon={<ContentCopyTwoToneIcon />} color="secondary" variant="soft" sx={{marginLeft: 2}}/>
                    </Tooltip>
                  </Box>

                  <br></br>





                  <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: "#000" }}> {/* Added wrapper for centering image */}

                    <img
                      src={boat.boatPicture}
                      height="403px"
                      alt="mainPic"
                      onClick={() => setIsOpen(true)}
                    /* Added styles for responsive image */
                    />
                  </Box>



                  <ImageList
                    sx={{ width: '100%' }}
                    variant="quilted"
                    cols={10}
                    rowHeight={50}
                  >
                    {boat.boatPictures && boat.boatPictures[0].map((url, index) => (
                      <ImageListItem
                        key={url}
                        cols={1}
                        rows={1}
                        onClick={() => {
                          setIsOpen(true);
                          setPhotoIndex(index);
                        }}
                      >
                        <img
                          {...srcset(url, 121, 1, 1)}
                          alt={`Boat ${index}`}
                          loading="lazy"
                        />
                      </ImageListItem>
                    ))}

                  </ImageList>

                  <br></br>

                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    <Card sx={{ width: "23%", minWidth: "100px", backgroundColor: "#f5f5f5", boxShadow: 'none', border: '1px solid #c6c6c6' }}>
                      <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>

                          <CalendarMonthIcon style={{ marginBottom: -5, marginRight: 5, color: "#1769aa" }} />
                          <span>{boat.buildYear}</span><br></br>
                          {t("Build Year")}

                        </Typography>
                      </CardContent>
                    </Card>
                    <Card sx={{ width: "23%", minWidth: "100px", backgroundColor: "#f5f5f5", boxShadow: 'none', border: '1px solid #c6c6c6' }}>
                      <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          <HotelTwoToneIcon style={{ marginBottom: -5, marginRight: 5, color: "#1769aa" }} />
                          <span>{boat.person}</span><br></br>

                          {t("Berths")}


                        </Typography>
                      </CardContent>
                    </Card>

                    <Card sx={{ width: "23%", minWidth: "100px", backgroundColor: "#f5f5f5", boxShadow: 'none', border: '1px solid #c6c6c6' }}>
                      <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          <MeetingRoomTwoToneIcon style={{ marginBottom: -5, marginRight: 5, color: "#1769aa" }} />
                          <span>{boat.cabins}</span><br></br>

                          {t("Cabins")}


                        </Typography>
                      </CardContent>
                    </Card>


                    <Card sx={{ width: "23%", minWidth: "100px", backgroundColor: "#f5f5f5", boxShadow: 'none', border: '1px solid #c6c6c6' }}>
                      <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          <BathroomTwoToneIcon style={{ marginBottom: -5, marginRight: 5, color: "#1769aa" }} />
                          <span>{boat.wc}</span><br></br>

                          {t("toilet")}


                        </Typography>
                      </CardContent>
                    </Card>
                    <Card sx={{ width: "23%", minWidth: "100px", backgroundColor: "#f5f5f5", boxShadow: 'none', border: '1px solid #c6c6c6' }}>
                      <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          <OfflineBoltTwoToneIcon style={{ marginBottom: -5, marginRight: 5, color: "#1769aa" }} />
                          <span>{boat.enginePower} hp</span><br></br>

                          {t("Engine Power")}


                        </Typography>
                      </CardContent>
                    </Card>
                    <Card sx={{ width: "23%", minWidth: "100px", backgroundColor: "#f5f5f5", boxShadow: 'none', border: '1px solid #c6c6c6' }}>
                      <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          <LocalGasStationTwoToneIcon style={{ marginBottom: -5, marginRight: 5, color: "#1769aa" }} />
                          <span>{boat.fuelTank} l</span><br></br>

                          {t("Fuel Tank")}


                        </Typography>
                      </CardContent>
                    </Card>


                    <Card sx={{ width: "23%", minWidth: "100px", backgroundColor: "#f5f5f5", boxShadow: 'none', border: '1px solid #c6c6c6' }}>
                      <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          <StraightenTwoToneIcon style={{ marginBottom: -5, marginRight: 5, color: "#1769aa" }} />
                          <span>{boat.length} {t("ft")}</span><br></br>

                          {t("Length")}


                        </Typography>
                      </CardContent>
                    </Card>


                    <Card sx={{ width: "23%", minWidth: "100px", backgroundColor: "#f5f5f5", boxShadow: 'none', border: '1px solid #c6c6c6' }}>
                      <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          <AssignmentReturnedTwoToneIcon style={{ marginBottom: -5, marginRight: 5, color: "#1769aa" }} />
                          <span>{boat.draft} m</span><br></br>

                          {t("Draft")}


                        </Typography>
                      </CardContent>
                    </Card>
                  </Box>





                  <Box sx={{ padding: '10px' }}>
                    <h2>{t("Boat equipment")}</h2>
                    <br></br>
                    {boat.equipment.map((item, index) => (
                      <Chip
                        style={{
                          fontSize: 18,
                          marginRight: 10,
                          marginBottom: 10,
                          backgroundColor: (item.toLowerCase().includes("thruster") || item.toLowerCase().includes("heating") || item.toLowerCase().includes("wi-fi") || item.toLowerCase().includes("bathing")) ? 'rgb(139, 174, 202)' : ''  // Conditional style with lowercase
                        }}
                        key={index}
                        label={t(item)}
                      />
                    ))}
                  </Box>
                  <Box style={{ width: '100%', marginTop: 30 }}>
                    <h2>{t('Google Maps')}</h2>
                    <iframe
                      title="Google Map"
                      width="100%"
                      height="200px"
                      frameBorder="0"
                      scrolling="no"
                      marginHeight="0"
                      marginWidth="0"
                      src={`https://maps.google.com/maps?q=${boat.lat},${boat.lon}&t=m&z=8&output=embed`}

                    >
                    </iframe>

                    <h2>{t('Windy.com forecast')}</h2>
                    <iframe

                      width="100%"
                      height="200px"
                      src={`https://embed.windy.com/embed2.html?lat=${boat.lat}&lon=${boat.lon}&detailLat=${boat.lat}&detailLon=${boat.lon}&width=650&height=450&zoom=8&level=surface&overlay=wind&product=ecmwfWind&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1`} frameborder="0">

                    </iframe>

                  </Box>
                  <br></br>
                  <h2>{t("Dates and booking")}</h2>

                  <Slider ref={sliderRef} {...sliderSettings}>
                    {datesCarousel.map((text, index) => {
                      const isClickedCard = index === clickedCardIndex;
                      const cardStyle = isClickedCard ? { backgroundColor: "#AED6F1" } : { backgroundColor: "#ECECEC" };

                      // Determine what to display for each price
                      let displayPrice;
                      if (prices[index] === undefined) {
                        displayPrice = t("Loading...");
                      } else if (prices[index] === "Unavailable") {
                        displayPrice = t("Unavailable");
                      } else {
                        displayPrice = `${prices[index]} €`;
                      }

                      return (
                        <Card key={index} sx={{ ...cardStyle, width: '95% !important', height: "auto", boxShadow: 'none', border: '1px solid #c6c6c6' }} onClick={() => {
                          if (displayPrice !== t("Unavailable")) {
                            handleCardClick(index);
                          }
                        }}>
                          <CardContent>
                            <Typography variant="body2" color="text.secondary" fontSize={13} sx={{ textAlign: 'center' }}>{text}</Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                color: displayPrice === t("Unavailable") ? '#FF1616 !important' :
                                  displayPrice === t("Loading...") ? 'text.secondary !important' : // Replace 'defaultColor' with your desired default color
                                    '#01831D !important',
                                marginTop: '10px',
                                textAlign: 'center',
                                fontSize: 15
                              }}

                            >
                              {displayPrice}
                            </Typography>
                          </CardContent>
                        </Card>
                      );
                    })}
                  </Slider>




                  <Grid container spacing={2} sx={{ marginTop: 1 }}>
                    <Grid item xs={7}>
                      <Card sx={{ width: '100%', height: "60px", boxShadow: 'none', border: '1px solid #c6c6c6', backgroundColor: "#F1F9FC", justifyContent: "center !important" }}>
                        <CardContent>
                          <Typography variant="body2" fontSize={15} sx={{ textAlign: 'center', color: '#1769aa !important' }}>
                            <CalendarMonthTwoToneIcon sx={{ marginBottom: "-5px !important" }} /> {selectedStartDate} - {selectedEndDate}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={5}>
                      <Card
                        sx={{
                          width: '100%',
                          height: "60px",
                          boxShadow: 'none',
                          border: '1px solid #c6c6c6',
                          backgroundColor: "#1769aa",
                          justifyContent: "center !important",
                          cursor: "pointer"
                        }}
                        onClick={scrollToBooking}
                      >
                        <CardContent>
                          <Typography
                            variant="body2"
                            color="#FFF !important"
                            fontSize={15}
                            sx={{
                              textAlign: 'center',
                              margin: '5px',
                            }}
                          >
                            {t("Book now")}
                          </Typography>
                        </CardContent>
                      </Card>


                    </Grid>
                  </Grid>



                  {/*
                  <TableContainer >
                    <Table aria-label="boat table" sx={{
                      '& .MuiTableCell-root': {  // Target all table cells
                        fontSize: {
                          xs: '0.65rem',  // Smaller font size on extra-small screens
                          sm: '0.75rem',  // Slightly larger font size on small screens
                          md: '1rem',
                          lg: '0.85rem',
                          xl: '0.85rem'   // Default font size on medium screens and above
                        },
                        padding: {
                          xs: '6px',  // Less padding on extra-small screens
                          sm: '8px', // Slightly more padding on small screens
                          md: '12px', // Default padding on medium screens and above
                        }
                      }
                    }}>

                      <TableBody>

                        <TableRow>
                          <TableCell component="th" scope="row"><PaymentsIcon style={{ marginBottom: -5, marginRight: 5, color: "#1769aa" }} /><span>{t("Payment Plan")}</span></TableCell>
                          <TableCell align="right">
                            {boat.paymentPlan && boat.paymentPlan.length > 0 ? (
                              JSON.parse(boat.paymentPlan[0].replace(/'/g, '"')).map((plan, index) => (
                                <div key={index}>
                                  {plan.date}:  {((boat.price) / 100 * plan.percentage).toFixed(2)} EUR
                                </div>
                              ))
                            ) : (
                              <div>No Payment Plan Available</div>
                            )}
                          </TableCell>
                        </TableRow>




                        <TableRow>
                          <TableCell component="th" scope="row"><CreditCardIcon style={{ marginBottom: -5, marginRight: 5, color: "#1769aa" }} /><span>{t("Deposit")}</span></TableCell>
                          <TableCell align="right">{boat.deposit} {boat.depositCurrency}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <br></br>
                            */}

                  <br></br>

                  <h2>{t("Mandatory services")}</h2>

                  <Table aria-label="boat table" sx={{
                    '& .MuiTableCell-root': {  // Target all table cells
                      fontSize: {
                        xs: '0.65rem',  // Smaller font size on extra-small screens
                        sm: '0.75rem',  // Slightly larger font size on small screens
                        md: '1rem',
                        lg: '0.85rem',
                        xl: '0.85rem'   // Default font size on medium screens and above
                      },
                      padding: {
                        xs: '6px',  // Less padding on extra-small screens
                        sm: '8px', // Slightly more padding on small screens
                        md: '12px', // Default padding on medium screens and above
                      }
                    }
                  }}>
                    <TableHead>
                      <TableRow>
                        <TableCell><b>{t("Add")}</b></TableCell>
                        <TableCell><b>{t("Service")}</b></TableCell>
                        <TableCell><b>{t("Description")}</b></TableCell>
                        <TableCell align="right"><b>{t("Payment")}</b></TableCell>
                        <TableCell align="right"><b>{t("Price")}</b></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {boat.mandatory_services.map((service, index) => (
                        <TableRow
                          key={index}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell>
                            <Checkbox
                              checked={checkedRowsMandatory[index] || false}
                              onChange={() => handleCheckboxChangeMandatory(index)}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {t(service[2])}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {service[3]}
                          </TableCell>
                          <TableCell align="right">
                            {service[1] === 'SEPARATE_PAYMENT' ? t("In Base") :
                              service[1] === 'ADVANCE_PAYMENT' ? 'Online' :
                                service[1]}
                          </TableCell>
                          <TableCell align="right">{service[0]} EUR</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  <br></br>



                  <h2>{t("Aditional extras")}</h2>

                  <Table aria-label="boat table" sx={{
                    '& .MuiTableCell-root': {  // Target all table cells
                      fontSize: {
                        xs: '0.65rem',  // Smaller font size on extra-small screens
                        sm: '0.75rem',  // Slightly larger font size on small screens
                        md: '1rem',
                        lg: '0.85rem',
                        xl: '0.85rem'   // Default font size on medium screens and above
                      },
                      padding: {
                        xs: '6px',  // Less padding on extra-small screens
                        sm: '8px', // Slightly more padding on small screens
                        md: '12px', // Default padding on medium screens and above
                      }
                    }
                  }}>
                    <TableHead>
                      <TableRow>
                        <TableCell><b>{t("Add")}</b></TableCell>
                        <TableCell><b>{t("Extras")}</b></TableCell>
                        <TableCell><b>{t("Description")}</b></TableCell>
                        <TableCell align="right"><b>{t("Payment")}</b></TableCell>
                        <TableCell align="right"><b>{t("Price")}</b></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {boat.additional_equipment.map((service, index) => (
                        <TableRow
                          key={index}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell>
                            <Checkbox

                              onChange={() => handleCheckboxChangeEquipemnt(index)}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {t(service[2])}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {service[3]}
                          </TableCell>
                          <TableCell align="right">
                            {service[1] === 'SEPARATE_PAYMENT' ? t("In Base") :
                              service[1] === 'ADVANCE_PAYMENT' ? 'Online' :
                                service[1]}
                          </TableCell>
                          <TableCell align="right">{service[0]} EUR</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  <br></br>

                  <h2>{t("Optional services")}</h2>

                  <Table aria-label="boat table" sx={{
                    '& .MuiTableCell-root': {  // Target all table cells
                      fontSize: {
                        xs: '0.65rem',  // Smaller font size on extra-small screens
                        sm: '0.75rem',  // Slightly larger font size on small screens
                        md: '1rem',
                        lg: '0.85rem',
                        xl: '0.85rem'   // Default font size on medium screens and above
                      },
                      padding: {
                        xs: '6px',  // Less padding on extra-small screens
                        sm: '8px', // Slightly more padding on small screens
                        md: '12px', // Default padding on medium screens and above
                      }
                    }
                  }}>
                    <TableHead>
                      <TableRow>
                        <TableCell><b>{t("Add")}</b></TableCell>
                        <TableCell><b>{t("Service")}</b></TableCell>
                        <TableCell><b>{t("Description")}</b></TableCell>
                        <TableCell align="right"><b>{t("Payment")}</b></TableCell>
                        <TableCell align="right"><b>{t("Price")}</b></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {boat.optional_services.slice(0, rowLimit).map((services, index) => (

                        <TableRow
                          key={index}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                        >
                          <TableCell component="th" scope="row">
                            <Checkbox
                              checked={checkedRows[index] || false}
                              onChange={() => handleCheckboxChange(index)}
                            />

                          </TableCell>
                          <TableCell>
                            {t(services[2])}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {services[3]}
                          </TableCell>
                          <TableCell align="right">
                            {services[1] === 'SEPARATE_PAYMENT' ? t("In Base") :
                              services[1] === 'ADVANCE_PAYMENT' ? 'Online' :
                                services[1]}
                          </TableCell>
                          <TableCell align="right">{services[0]} EUR</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>

                  </Table>





                  <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, marginBottom: 2, padding: 2 }}>
                    {rowLimit < 50 && (
                      <Button color="info" onClick={() => setRowLimit(rowLimit + 50)} variant="outlined">
                        {t("Show More")}
                      </Button>
                    )}
                    {rowLimit > 3 && (
                      <Button color="info" onClick={() => setRowLimit(rowLimit - 50)} variant="outlined">
                        {t("Hide")}
                      </Button>
                    )}
                  </Box>
                  <Grid>
                    {isDateSelected && (
                      <h2 ref={bookingRef}>{t("Booking")}</h2>
                    )}
                  </Grid>

                  <Grid>
                    {isDateSelected && (




                      <Box sx={{ width: '100%', minHeight: "500px", boxShadow: 'none', border: '1px solid #c6c6c6', backgroundColor: "#e6f2fc", borderRadius: '4px !important' }}>

                        <Grid container spacing={1}>
                          <Grid container alignItems="center">
                            <Grid item xs={12} md>
                              <Typography sx={{ color: "#262F5B !important", marginLeft: 3 }}>
                                <h1>{boat.yachtModelName} | {boat.boatName}</h1>
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography sx={{ color: "#5c709b !important", display: 'flex', alignItems: 'center', marginLeft: 3 }}>
                                <CalendarMonthTwoToneIcon sx={{ marginRight: 1 }} />
                                {selectedStartDate} - {selectedEndDate}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography sx={{ color: "#5c709b !important", fontSize: "17 !important", display: 'flex', alignItems: 'center', marginLeft: 3 }}>
                                <FmdGoodTwoToneIcon sx={{ marginRight: 1 }} />
                                {boat.location}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid container alignItems="center">
                            <Grid item xs={12} md>
                              <Typography sx={{ color: "#262F5B !important", marginLeft: 3 }}>
                                <h3>{t("Charter company")}: {boat.company_name}</h3>

                              </Typography>

                            </Grid>

                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>



                          <Grid lg={4}>
                            <Typography sx={{ color: "#262F5B !important", margin: 3 }}>


                              {t("Total Base Payment")}
                              <p>{t("Deposit")}: {boat.deposit} €</p>
                              <p>{t("Services")}: {totalBasePayment.toFixed(2)} €</p>

                            </Typography>


                          </Grid>
                          <Grid lg={4}>
                            <Typography sx={{ color: "#262F5B !important", margin: 3 }}>

                              {t("Payment plan online")}
                              {paymentPlanSelected && paymentPlanSelected.length > 0 ? (
                                paymentPlanSelected.map((plan, index) => (
                                  <div key={index}>
                                    <p>
                                      <CalendarMonthTwoToneIcon sx={{ marginBottom: "-2px !important", marginRight: "3px", fontSize: 17 }} />{plan.date}: {((parseFloat(selectedPrice) / 100 * plan.percentage) + (parseFloat(totalOnlinePayment) / 100 * plan.percentage)).toFixed(2)} €
                                    </p>
                                  </div>
                                ))
                              ) : (
                                <div>No Payment Plan Available</div>
                              )}





                            </Typography>


                          </Grid>
                          <Grid lg={4}>
                            <Typography sx={{ color: "#262F5B !important", marginLeft: 3, marginTop: 3 }}>


                              {t("Services")}

                              <Box sx={{ mb: 2 }}>
                                <p>
                                  {[
                                    ...boat.mandatory_services
                                      .filter((_, index) => checkedRowsMandatory[index])
                                      .map(service => t(service[2])),
                                    ...boat.optional_services
                                      .filter((_, index2) => checkedRows[index2])
                                      .map(service => t(service[2])),
                                    ...boat.additional_equipment
                                      .filter((_, index3) => checkedRowsEquipment[index3])
                                      .map(service => t(service[2]))
                                  ].join(", ")}
                                </p>
                              </Box>


                            </Typography>
                          </Grid>
                          <Grid lg={12}>
                            <Typography sx={{ color: "#262F5B !important", margin: 3 }}>

                              <TextField
                                id="nameClient"
                                label={t("Name")}
                                variant="outlined"
                                value={nameClient}
                                onChange={handleNameChange} // Set up the onChange handler
                                required
                                style={{ marginRight: 40, minWidth: 250 }}
                                InputProps={{ // Directly target the input component
                                  sx: {
                                    backgroundColor: 'white' // Set the input background color to white


                                  },
                                }}
                              />
                              <TextField
                                id="Surname"
                                label={t("Surname")}
                                variant="outlined"
                                value={surname}
                                onChange={handleSurnameChange} // Set up the onChange handler
                                required
                                style={{ marginRight: 40, minWidth: 250 }}
                                InputProps={{ // Directly target the input component
                                  sx: {
                                    backgroundColor: 'white' // Set the input background color to white


                                  },
                                }}
                              />

                              <FormControl style={{ marginRight: 40, minWidth: 250, maxWidth: 250 }}>
                                <InputLabel id="country-code-label">{t("Country code")}</InputLabel>
                                <Select
                                  labelId="country-code-label"
                                  id="country-code-select"
                                  value={countryCode}
                                  label={t("Country code")}
                                  onChange={handleCountryCodeChange}
                                  required
                                  variant="outlined"
                                  style={{ backgroundColor: "#Fff" }}
                                >
                                  {countryCodes.map((item) => (
                                    <MenuItem key={item.code} value={item.code}>
                                      {item.country} ({item.code})
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <TextField
                                id="Mobile"
                                label={t("Mobile")}
                                variant="outlined"
                                value={mobile}
                                required
                                onChange={handleMobileChange}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">{countryCode}</InputAdornment>,
                                  sx: {
                                    backgroundColor: 'white' // Set the input background color to white


                                  },
                                }}

                                style={{ marginRight: 40, maxWidth: 250 }}
                              />
                              <TextField
                                id="emailInput"
                                label="email"
                                variant="outlined"
                                value={props.email || emailInput}
                                required
                                onChange={handleEmailChange}
                                style={{ marginRight: 40, minWidth: 250 }}
                                InputProps={{ // Directly target the input component
                                  sx: {
                                    backgroundColor: 'white' // Set the input background color to white


                                  },
                                }}
                              />
                              <FormGroup>
                                <Grid container spacing={2} alignItems="center">

                                  <Grid item>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-radio-buttons-group-label"
                                      value={paymentWay}
                                      onChange={handlePaymentWayChange}
                                      name="radio-buttons-group"
                                    >
                                      <FormControlLabel disabled value={t("cardPayment")} control={<Radio />} label={<Typography sx={{ color: "#606060 !important" }}>{t("Card payment")}</Typography>} />
                                      <FormControlLabel value={t("bankTransfer")} control={<Radio />} label={<Typography sx={{ color: "#606060 !important" }}>{t("Bank transfer")}</Typography>} />
                                    </RadioGroup>
                                  </Grid>
                                </Grid>
                              </FormGroup>


                            </Typography>
                          </Grid>

                          <Grid md={4} lg={4} sx={{ marginTop: "-400px !important" }}>

                          </Grid>
                          <Grid md={4} lg={2}>

                          </Grid>
                          <Grid xs={12} md={4} lg={6} >

                            <Typography sx={{ color: "#262F5B !important", margin: 3 }}>
                              <h1>{(parseFloat(totalOnlinePayment) + parseFloat(selectedPrice)).toFixed(2)} EUR**</h1>
                              <FormControlLabel
                                control={<Checkbox checked={isChecked} onChange={handleCheckboxChangeGDPR} />}
                                label={
                                  <div>
                                    <Typography component="span" sx={{ fontSize: '0.9rem !important' }}>
                                      {t("I agree with")}
                                    </Typography>
                                    <Button onClick={handleOpenBusinessModal} sx={{ fontSize: '0.9rem !important', marginLeft: -0.5 }}>
                                      {t("business conditions")}
                                    </Button>
                                    <Typography component="span" sx={{ fontSize: '0.9rem !important', marginLeft: -0.5 }}>
                                      {t("and")}
                                    </Typography>
                                    <Button onClick={handleOpenGDPRModal} sx={{ fontSize: '0.9rem !important', marginLeft: -0.8 }}>
                                      GDPR .
                                    </Button>

                                  </div>
                                }
                              />
                              <Card
                                onClick={!isButtonClicked && isChecked && !loading ? handleSubmit : null}
                                sx={{
                                  width: { xs: '100%%' }, // 90% width on xs to sm breakpoints, 100% width from md breakpoint
                                  height: "60px",
                                  boxShadow: 'none',
                                  border: '1px solid #c6c6c6',
                                  backgroundColor: isButtonClicked || !isChecked || loading ? "#A9A9A9" : "#1769aa",
                                  justifyContent: "center !important",
                                  cursor: isButtonClicked || !isChecked || loading ? "default" : "pointer",
                                  m: 'auto', // Added to center the card when it's less than 100% width
                                }}
                              >
                                <CardContent>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: '#FFF !important', // Changed for consistency
                                      fontSize: 15,
                                      textAlign: 'center',
                                      margin: '5px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    {loading ? <CircularProgress size={24} color="inherit" /> : t("Book now")}
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Typography variant="body2">
                          <Box sx={{ margin: '15px' }}>
                            <small>{t("**Price is without services / equipment which will be paid in marina.")}</small><br></br>
                            <small>{t("**PapaSailor do not charge any booking fees.")}</small>
                          </Box>
                        </Typography>
                      </Box>
                    )}
                  </Grid>

                  {isOpen && (
                    <Lightbox
                      mainSrc={boat.boatPictures[0][photoIndex]}

                      nextSrc={boat.boatPictures[0][(photoIndex + 1) % boat.boatPictures[0].length]}
                      prevSrc={boat.boatPictures[0][(photoIndex + boat.boatPictures[0].length - 1) % boat.boatPictures[0].length]}
                      onCloseRequest={() => setIsOpen(false)}
                      onMovePrevRequest={() => setPhotoIndex((photoIndex + boat.boatPictures[0].length - 1) % boat.boatPictures[0].length)}
                      onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % boat.boatPictures[0].length)}

                    />
                  )}

                </Box>

              </Box>
            )

            }
          </div>
          <Modal
            open={showSuccessModal}
            onClose={handleModalClose}
            aria-labelledby="success-modal-title"
            aria-describedby="success-modal-description"
          >
            <Box sx={{ ...style, /* other styles as needed */ }}>
              <Typography id="success-modal-title" variant="h6" component="h2">
                {t("Booking Successful!")}
              </Typography>
              <Typography id="success-modal-description" sx={{ mt: 2 }}>
                {t("Your booking has been successfully processed. You will be redirected to the main page.")}
              </Typography>
              <Button onClick={() => {
                setShowSuccessModal(false);
                window.location.href = '/';
              }}>{t("Close")}</Button>
            </Box>
          </Modal>
        </Grid>
        {/*

        <Hidden lgDown>
          <Grid item xs={12} sm={12} lg={3} xl={2}>
            
            {isLoading ? (
              console.log("loadujem")
            ) : (
              

              <Card style={{ width: '100%', marginTop: 110, marginRight: 10, padding: 10, position: "sticky", zIndex: 1000 }}>
                
                <Box sx={{ p: 1, display: 'flex' }}>
                  <Avatar variant="circle" src={`${process.env.PUBLIC_URL}/avatar_peter.jpg`} sx={{ width: 60, height: 60, marginRight: 2 }} />
                  <Stack spacing={0.5}>
                    <Typography fontWeight="bold">Peter Agh</Typography>
                    <Typography variant="body2" color="text.secondary">
                      <small >Booking manager</small>
                    </Typography>
                    <br></br>
                    <Divider />
                    <br></br>
                    <Typography variant="body2" color="text.secondary">
                      +421 904 919 173
                    </Typography>

                    <Typography variant="body2" color="text.secondary">
                      <small >peter@papasailor.com</small>
                    </Typography>
                  </Stack>

                </Box>

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ px: 2, py: 1, bgcolor: 'background.default' }}
                >


                </Stack>
                <List sx={{ width: '100%', maxWidth: 360, marginLeft: 4, bgcolor: 'background.paper' }}>
                  <ListItem>
                    <ListItemText primary={t("Date from")} secondary={dayStart} />
                    <ListItemText primary={t("Date to")} secondary={dayEnd} />
                  </ListItem>
                </List>
                <Button onClick={handleOpen} id="book" variant="outlined" style={{ width: '90%', height: 60, marginTop: 20, marginBottom: 10, padding: 20 }} color="info"><AddShoppingCartIcon />{t("book now")} </Button>
              </Card>


            )}

            
          </Grid>
        </Hidden>
        */}


      </Grid>

      <div>

        <Modal
          open={openMaps}
          onClose={handleCloseMaps}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleMaps}>

            <iframe
              title="Google Maps"
              width="100%"
              height="100%"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src={`https://maps.google.com/maps?q=${boat.lat},${boat.lon}&t=m&z=8&output=embed`}

            >
            </iframe>
            <br>
            </br>
            <CloseIcon />
            <Button key='' onClick={handleCloseMaps} sx={{ color: '#000', marginTop: -2, marginLeft: -1 }}>{t("Close")}</Button>
          </Box>

        </Modal>

      </div>
    </HelmetProvider>

  );


};




export default BoatDetailsNoDate;
