import { useTranslation } from 'react-i18next';
import './i18n.js';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import EditIcon from '@mui/icons-material/Edit';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Masonry from '@mui/lab/Masonry';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import LockIcon from '@mui/icons-material/Lock';
import VerifiedIcon from '@mui/icons-material/Verified';
import SailingTwoToneIcon from '@mui/icons-material/SailingTwoTone';


import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';

import IconButton, { IconButtonProps } from '@mui/material/IconButton';

import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const MAX_CHARS = 300;
const COLOR = '#1976d2';






const Profile = (props) => {
    const { t } = useTranslation();
    const { user_id } = useParams();
    const [userData, setUserData] = useState(null);
    const [tabIndex, setTabIndex] = useState(0);
    const [userImages, setUserImages] = useState([]);
    const [userPosts, setUserPosts] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [profileStatus, setProfileStatus] = useState(null);
    const [isPrivate, setIsPrivate] = useState(false);
    const [followedUsers, setFollowedUsers] = useState([]);
    const [posts, setPosts] = useState([]);
    const [likedPosts, setLikedPosts] = useState([]);
    const [comments, setComments] = useState({});
    const [commentContent, setCommentContent] = useState('');
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxImage, setLightboxImage] = useState('');
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [postToEdit, setPostToEdit] = useState(null);
    const [editContent, setEditContent] = useState('');
    const [expandedPosts, setExpandedPosts] = useState([]);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const [currentUserId, setCurrentUserId] = useState(null);
    const [expanded, setExpanded] = React.useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [postToDelete, setPostToDelete] = useState(null);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const openDeleteDialog = (postId) => {
        setPostToDelete(postId);
        setDeleteDialogOpen(true);
    };

    const closeDeleteDialog = () => {
        setPostToDelete(null);
        setDeleteDialogOpen(false);
    };

    const openEditDialog = (post) => {
        setPostToEdit(post.ID);
        setEditContent(post.content);
        setEditDialogOpen(true);
    };

    const closeEditDialog = () => {
        setPostToEdit(null);
        setEditContent('');
        setEditDialogOpen(false);
    };

    const handleEditContentChange = (event) => {
        const newContent = event.target.value.slice(0, MAX_CHARS);
        setEditContent(newContent);
    };

    const handleEditSubmit = async () => {
        if (!postToEdit) return;

        try {
            await axios.post('/edit_post', {
                post_id: postToEdit,
                content: editContent,
                email: props.email,
            });

            closeEditDialog();
       
        } catch (error) {
            console.error("Error editing post:", error);
        }
    };

    const fetchCurrentUserId = async () => {
        try {
            console.log('Calling API with email:', props.email);
            const response = await axios.post('/getUserId', { email: props.email });
            console.log('API response:', response.data);
            setCurrentUserId(response.data.user_id);
        } catch (error) {
            console.error("Error fetching user ID:", error);
        }
    };

    const openLightbox = (url) => {
        setLightboxImage(url);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setLightboxOpen(false);
        setLightboxImage('');
    };

    const getNumberLikes = async (wallId) => {
        try {
            const response = await axios.post('/get_number_likes', { wall_id: wallId });
            return response.data.likes_count;
        } catch (error) {
            console.error("Error fetching number of likes:", error);
            return 0;
        }
    };

    useEffect(() => {
        if (posts.length > 0) {
         
            posts.forEach(post => fetchComments(post.ID));
        }
    }, [posts]);


    const fetchComments = async (wallId) => {
        try {
            const response = await axios.get(`/comments/${wallId}`);
            setComments((prevComments) => ({
                ...prevComments,
                [wallId]: response.data
            }));
        } catch (error) {
            console.error("Error fetching comments:", error);
        }
    };

    const handleCommentChange = (event) => {
        setCommentContent(event.target.value);
    };

    const handleCommentSubmit = async (wallId) => {
        if (!commentContent.trim()) return;

        try {
            await axios.post('/comments', {
                wall_id: wallId,
                content: commentContent,
                email: props.email,
            });

            setCommentContent('');
            fetchComments(wallId);
        } catch (error) {
            console.error("Error adding comment:", error);
        }
    };
    const handleExpandPost = (wallId) => {
        if (expandedPosts.includes(wallId)) {
            setExpandedPosts(expandedPosts.filter(id => id !== wallId));
        } else {
            setExpandedPosts([...expandedPosts, wallId]);
            fetchComments(wallId);
        }
    };

    const handleDeleteComment = async (commentId, wallId) => {
        try {
            await axios.post('/comments/delete', {
                comment_id: commentId,
                email: props.email,
            });

            fetchComments(wallId);
        } catch (error) {
            console.error("Error deleting comment:", error);
        }
    };

    const handleDeletePost = async () => {
        if (!postToDelete) return;

        try {
            await axios.post('/delete_post', {
                post_id: postToDelete,
                email: props.email,
            });

            setDeleteDialogOpen(false);
            setPostToDelete(null);
     
        } catch (error) {
            console.error("Error deleting post:", error);
        }
    };


    const fetchLikes = async () => {
        try {
            const response = await axios.post('/likes', { email: props.email });
            setLikedPosts(response.data);
        } catch (error) {
            console.error("Error fetching likes:", error);
        }
    };
    const handleLike = async (wallId) => {
        try {
            const response = await axios.post('/like', { wall_id: wallId, email: props.email });
            const updatedLikesCount = response.data.likes_count;

            setPosts((prevPosts) =>
                prevPosts.map((post) =>
                    post.ID === wallId ? { ...post, likes_count: updatedLikesCount } : post
                )
            );

            fetchLikes();
        } catch (error) {
            console.error("Error liking post:", error);
        }
    };
    const fetchFollows = async () => {
        try {
            const response = await axios.post('/follows', { email: props.email });
            console.log('Followed users response:', response.data);
            setFollowedUsers(response.data);
        } catch (error) {
            console.error("Error fetching follows:", error);
        }
    };

    const handleFollow = async (followedId) => {
        try {
            await axios.post('/follow', { email: props.email, followed_id: followedId });
            fetchFollows(); // Po zmene sledovania znovu načítame zoznam sledovaných užívateľov
        } catch (error) {
            console.error("Error following/unfollowing user:", error);
        }
    };

    const formatTimestamp = (timestamp) => {
        const now = new Date();
        const postDate = new Date(timestamp);
        const diffInSeconds = (now - postDate) / 1000;
        const diffInMinutes = diffInSeconds / 60;
        const diffInHours = diffInMinutes / 60;

        if (diffInSeconds < 180) return t('now');
        if (diffInMinutes < 60) return t('minutes_ago', { count: Math.floor(diffInMinutes) });
        if (diffInHours < 48) return t('hours_ago', { count: Math.floor(diffInHours) });

        return postDate.toLocaleString();
    };


    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`/api/getUserData/${user_id}`);
                setUserData(response.data);
                setProfileStatus(response.data.public);
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    setIsPrivate(true);
                } else {
                    console.error("Error fetching user data:", error);
                }
            }
        };

        const fetchUserImages = async () => {
            try {
                const response = await axios.get(`/api/getUserImages/${user_id}`);
                setUserImages(response.data);
            } catch (error) {
                console.error("Error fetching user images:", error);
            }
        };
        const fetchUserPosts = async () => {
            try {
                const response = await axios.get(`/api/getUserPosts/${user_id}`);
                const posts = response.data;

                const postsWithLikes = await Promise.all(
                    posts.map(async (post) => {
                        const likesCount = await getNumberLikes(post.ID);
                        return { ...post, likes_count: likesCount };
                    })
                );

                setUserPosts(postsWithLikes);
                postsWithLikes.forEach(post => fetchComments(post.ID));
            } catch (error) {
                console.error("Error fetching user posts:", error);
            }
        };


        fetchUserData();
        fetchUserImages();
        fetchUserPosts();
    }, [user_id]);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleImageClick = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
    };

    const handleProfileStatusChange = () => {
        setDialogOpen(true);
    };

    const handleConfirmChange = async () => {
        try {
            const newStatus = profileStatus === 1 ? 0 : 1;
            await axios.post(`/api/updateProfileStatus/${user_id}`, { public: newStatus });
            setProfileStatus(newStatus);
            setUserData((prevState) => ({
                ...prevState,
                public: newStatus
            }));
        } catch (error) {
            console.error("Error updating profile status:", error);
        } finally {
            setDialogOpen(false);
        }
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    if (isPrivate) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                }}
            >
                <LockIcon sx={{ fontSize: 80, mb: 2, color: '#4da0db' }} />
                <Typography variant="h6">{t('This profile is private')}</Typography>
            </Box>
        );
    }

    if (!userData) {
        return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>

            <CircularProgress />
            <SailingTwoToneIcon
                color="info"
                style={{
                    position: 'absolute',
                    fontSize: 20,
                }}
            />

        </Box>;
    }



    const email = props.email
    return (

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 2,
                width: { xs: '100%', md: '70%' },
                mx: 'auto',
            }}
        >

            {lightboxOpen && (
                <Lightbox
                    mainSrc={lightboxImage}
                    onCloseRequest={closeLightbox}
                />
            )}
            <Grid container spacing={2} alignItems="center">

                <Grid item xs={3} display="flex" flexDirection="column" alignItems="center">

                    <Avatar
                        alt="Profile Picture"
                        src={userData.profilePic}
                        sx={{ width: 100, height: 100 }}
                    />
                    <Typography variant="p" sx={{ mt: 1 }}>
                        {userData.email === 'peteragh2@gmail.com' && <VerifiedIcon sx={{ color: '#48bbe8', marginRight: '3px', fontSize: 14 }} />}
                        {userData.name}

                    </Typography>



                    {email === userData.email && (
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={handleProfileStatusChange}
                            sx={{
                                mt: 3,
                                color: profileStatus === 1 ? 'success.main' : 'error.main',
                                borderColor: profileStatus === 1 ? 'success.main' : 'error.main',
                                '&.MuiButton-outlined': {
                                    color: profileStatus === 1 ? 'success.main' : 'error.main',
                                    borderColor: profileStatus === 1 ? 'success.main' : 'error.main',
                                }
                            }}
                        >
                            {profileStatus === 1 ? t('Public') : t('Private')}
                        </Button>
                    )}

                </Grid>
                <Grid item xs={8} sx={{ marginRight: 2, marginTop: -4 }}>
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item xs={4}>
                            <Box textAlign="center">
                                <Typography variant="h6">{userData.posts}</Typography>
                                <Typography variant="body2">{t('posts')}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box textAlign="center">
                                <Typography variant="h6">{userData.followers}</Typography>
                                <Typography variant="body2">{t('followers')}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box textAlign="center">
                                <Typography variant="h6">{userData.following}</Typography>
                                <Typography variant="body2">{t('following')}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Tabs value={tabIndex} onChange={handleTabChange} aria-label="profile tabs" sx={{ marginTop: 4 }}>
                <Tab label={t('Images')} />
                <Tab label={t('Posts')} />
                <Tab label={t('Trips')} />

            </Tabs>

            {tabIndex === 0 && isDesktop && (
                <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={2} sx={{ mt: 2 }}>
                    {userImages.map((image, index) => (
                        <Box key={image.ID} onClick={() => handleImageClick(index)}>
                            <img
                                src={image.pictureUrl}
                                alt="User Image"
                                style={{ width: '100%', display: 'block', cursor: 'pointer' }}
                            />
                        </Box>
                    ))}
                </Masonry>
            )}
            {tabIndex === 0 && !isDesktop && (
                <Grid container spacing={0} sx={{ mt: 2, marginRight: 4, width: '95%' }}>
                    {userImages.map((image, index) => (
                        <Grid item xs={3} key={image.ID} sx={{ p: 0 }} onClick={() => handleImageClick(index)}>
                            <Box sx={{ width: '100%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img
                                    src={image.pictureUrl}
                                    alt="User Image"
                                    style={{ width: '100%', height: '100px', objectFit: 'cover', display: 'block', cursor: 'pointer' }}
                                />
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            )}
            {tabIndex === 1 && (
                <Box>
                    {userPosts.map((post, index) => (
                        <Card sx={{ maxWidth: 745, marginTop: 3, marginRight: 4 }} key={index}>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe" src={post.profilePic}>
                                        {post.profilePic}
                                    </Avatar>
                                }
                                action={
                                    <IconButton size="small" onClick={props.email ? () => handleFollow(post.user_id) : () => toast.error(t('Error adding to favorites. Are you logged in?'))}>
                                        {followedUsers.includes(post.user_id) ? (
                                            <FavoriteTwoToneIcon sx={{ color: COLOR }} />
                                        ) : (
                                            <FavoriteBorderIcon sx={{ color: COLOR }} />
                                        )}
                                    </IconButton>
                                }
                                title={post.meno + " " + post.priezvisko}
                                subheader={formatTimestamp(post.timestamp)}
                            />
                            {post.pictureUrl && (
                                <CardMedia
                                    component="img"
                                    image={post.pictureUrl}
                                    alt="Post image"
                                    sx={{ width: '100%', cursor: 'pointer' }}
                                    onClick={() => openLightbox(post.pictureUrl)}
                                />
                            )}
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                    {post.content}
                                </Typography>
                            </CardContent>
                            <CardActions disableSpacing>
                                <IconButton size="small" onClick={props.email ? () => handleLike(post.ID) : () => toast.error(t('Error adding to favorites. Are you logged in?'))}>
                                    {likedPosts.includes(post.ID) ? (
                                        <ThumbUpIcon sx={{ color: COLOR }} />
                                    ) : (
                                        <ThumbUpOffAltIcon />
                                    )}
                                </IconButton>
                                <Typography variant="body2" sx={{ color: COLOR, marginLeft: 1 }}>
                                    {post.likes_count}
                                </Typography>

                                <Box>
                                    
                                        <>
                                        {post.user_id === currentUserId && (
                                        <>
                                            <IconButton
                                                onClick={() => openEditDialog(post)}
                                                sx={{ marginLeft: 1 }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => openDeleteDialog(post.ID)}
                                                sx={{ marginLeft: 1 }}
                                            >
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        </>
                                    )}
                      
                                        </>
                              
                                </Box>

                                {/*  <IconButton aria-label="share">
                                    <ShareIcon />
                                </IconButton>
                                */}


                                <Box sx={{ marginLeft: 'auto' }}>
                                    <IconButton size="small" onClick={props.email ? () => handleExpandPost(post.ID) : () => toast.error(t('Error adding to favorites. Are you logged in?'))}>
                                        <ChatOutlinedIcon />
                                        <Typography variant="body2" sx={{ color: COLOR, marginLeft: 1 }}>
                                        {comments[post.ID] ? comments[post.ID].length : 0}
                                    </Typography>
                                    </IconButton>
                              
                                </Box>

                            </CardActions>
                            <Collapse in={expandedPosts.includes(post.ID)} timeout="auto" unmountOnExit>
                                {expandedPosts.includes(post.ID) && (
                                    <Box sx={{ bgcolor: '#f0f0f0', p: 2 }}>
                                        {comments[post.ID] && comments[post.ID].map((comment) => (
                                            <React.Fragment key={comment.id}>
                                                <Box display="flex" flexDirection="column" alignItems="flex-start" mb={2} mt={1}>
                                                    <Box display="flex" justifyContent="space-between" width="100%">
                                                        <Box display="flex" alignItems="center">
                                                            <Typography variant="body2" sx={{ color: COLOR, marginRight: 1 }}>
                                                                {comment.meno} {comment.priezvisko}
                                                            </Typography>
                                                            <Typography variant="caption" color="textSecondary">
                                                                {formatTimestamp(comment.timestamp)}
                                                            </Typography>
                                                        </Box>
                                                        {comment.email === props.email && (
                                                            <IconButton onClick={() => handleDeleteComment(comment.id, post.ID)} sx={{ color: 'red' }}>
                                                                <DeleteForeverIcon />
                                                            </IconButton>
                                                        )}
                                                    </Box>
                                                    <Typography variant="caption" sx={{ mt: 1 }}>{comment.content}</Typography>
                                                </Box>
                                                <Divider />
                                            </React.Fragment>
                                        ))}
                                        <TextField
                                            variant="outlined"
                                            placeholder={t('write your comment...')}
                                            value={commentContent}
                                            onChange={handleCommentChange}
                                            multiline
                                            rows={2}
                                            maxRows={4}
                                            sx={{ width: '100%', marginTop: 2 }}
                                        />
                                        <Button
                                            onClick={() => handleCommentSubmit(post.ID)}
                                            variant="outlined"
                                            endIcon={<SendIcon />}
                                            sx={{ marginTop: 1 }}
                                        >
                                            {t('send')}
                                        </Button>
                                    </Box>
                                )}
                            </Collapse>
                        </Card>
                    ))}
                </Box>

            )}


            {tabIndex === 2 && (
                <Typography sx={{ mt: 2 }}>
                    {t('User trips under construction.')}
                </Typography>
            )}





            {isOpen && (
                <Lightbox
                    mainSrc={userImages[photoIndex].pictureUrl}
                    nextSrc={userImages[(photoIndex + 1) % userImages.length].pictureUrl}
                    prevSrc={userImages[(photoIndex + userImages.length - 1) % userImages.length].pictureUrl}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + userImages.length - 1) % userImages.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % userImages.length)}
                    imageCaption={userImages[photoIndex].content}
                />
            )}

            <Dialog
                open={dialogOpen}
                onClose={handleCloseDialog}
            >
                <DialogTitle>{t('Change Profile Status')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('Are you sure you want to change the profile status?')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        {t('Cancel')}
                    </Button>
                    <Button onClick={handleConfirmChange} color="primary">
                        {t('Confirm')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={editDialogOpen}
                onClose={closeEditDialog}
            >
                <DialogTitle>{t('edit post')}</DialogTitle>
                <DialogContent>
                    <TextField
                        variant="outlined"
                        placeholder={t('write your post...')}
                        value={editContent}
                        onChange={handleEditContentChange}
                        multiline
                        rows={4}
                        maxRows={4}
                        inputProps={{ maxLength: MAX_CHARS }}
                        sx={{ width: '100%', marginBottom: 2, maxWidt: '500px' }}
                    />
                    <Typography variant="caption" color="textSecondary" align="left" sx={{ width: '100%' }}>
                        {editContent.length}/{MAX_CHARS} {t('characters')}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeEditDialog} color="primary">
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleEditSubmit} color="primary">
                        {t('save')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={deleteDialogOpen}
                onClose={closeDeleteDialog}
            >
                <DialogTitle>{t('confirm delete')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('are you sure you want to delete this post?')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} color="primary">
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleDeletePost} color="primary">
                        {t('delete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Profile;
