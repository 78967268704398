import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useState } from 'react';

//translate
import { useTranslation } from 'react-i18next';
import './i18n.js';

export function ManufacturerSelect({ selectedManufacturer, setSelectedManufacturer }) {
  const [inputValue, setInputValue] = useState("");
  const { t } = useTranslation();
  return (
    <Box id="cSlider">
      <Autocomplete

        multiple
        id="tags-outlined"
        options={manufacturer}
        getOptionLabel={(option) => option.title}
        value={selectedManufacturer}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        onChange={(event, newValue) => {
          setSelectedManufacturer(newValue);
          if (!newValue.length) {
            setInputValue("");
          }
        }}
        filterSelectedOptions
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            {option.title}
          </Box>
        )}
        renderInput={(params) => (
          <TextField {...params} label={t("Manufacturer")} placeholder={t("Manufacturer")}  sx={{
            "& .MuiInputBase-root": {
              backgroundColor: "white", // Set the background color of the input field
              '& fieldset': {
                borderColor: '#B2E0FA', // Default border color
              },
              '&:hover fieldset': {
                borderColor: '#D8D9DA', // Hover border color
              },
              '&.Mui-focused fieldset': {
                borderColor: '#D8D9DA', // Border color when the input is focused
              },
            },
          }} />
        )}
      />
    </Box>
  );
}

const manufacturer = [
  { title: 'Bavaria', value: 'Bavaria' },
  { title: 'Lagoon', value: 'Lagoon' },
  { title: 'Sun', value: 'Sun' },
  { title: 'Elan', value: 'Elan' },
  { title: 'Bali', value: 'Bali' },
  { title: 'Lavezzi', value: 'Lavezzi' },
  { title: 'Salona', value: 'Salona' },
  { title: 'Oceanis', value: 'Oceanis' },
  { title: 'Nautitech', value: 'Nautitech' },
  { title: 'Dufour', value: 'Dufour' },
  { title: 'Athena', value: 'Athena' },
];












