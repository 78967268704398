import React, { useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/sk';
import 'dayjs/locale/hu';
import 'dayjs/locale/cs';
import 'dayjs/locale/de';

//translate
import { useTranslation } from 'react-i18next';
import './i18n.js';

const today = dayjs();

export function DateRangeSelect({ dateRange, setDateRange, error, setError }) {
  const { t } = useTranslation();
  const shouldDisableDate = (date) => {
    // Enable Saturdays (index 6) and disable all other days
    return date.day() !== 6;
  };

  if (error == true) {
    setError('Does it seems valid date for ya?!');
  }
  const handleChange = (newValue) => {
    if (newValue) {
      setDateRange(newValue);
      setError(''); // Clear any previous error
    }
  };

  // Ensure that the error state is updated when the parent component changes the error prop
  useEffect(() => {
    setError(error);
  }, [error, setError]);

  return (
    <Box id="mPicker">
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={t("en-gb")}>
        <DateRangePicker
          minDate={today}
          className="my-date-range-picker"
          localeText={{ start: t('Check-in'), end: t('Check-out') }}
          value={dateRange}
          disablePast
          onChange={handleChange}
          shouldDisableDate={shouldDisableDate}
          sx={{
            '& .MuiOutlinedInput-root': {
              backgroundColor: '#fff', // Input background
              '& fieldset': {
                borderColor: '#B2E0FA', // Default border color
              },
              '&:hover fieldset': {
                borderColor: '#D8D9DA', // Hover border color
              },
              '&.Mui-focused fieldset': {
                borderColor: '#D8D9DA', // Border color when the input is focused
              },
            },
          }}
        
          
        />
        {error && <small style={{ color: '#606060', position: 'fixed' }}>{error}</small>}

      </LocalizationProvider>

    </Box>

  );
}
