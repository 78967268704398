import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import { 
  Paper, 
  TextField, 
  InputAdornment,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import { DataGrid } from '@mui/x-data-grid';

const Favoritesweb = ({ email }) => {
  const [favorites, setFavorites] = useState([]);
  const [filter, setFilter] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedBoatId, setSelectedBoatId] = useState(null);

  useEffect(() => {
    fetchFavorites();
  }, [email]);

  const fetchFavorites = async () => {
    try {
      const response = await axios.post('/favoritesList', { email });
      setFavorites(response.data);
    } catch (error) {
      console.error('Error fetching favorites:', error);
    }
  };

  const deleteFavorite = async (boat_id) => {
    try {
      await axios.post('/favoritesDelete', { email, boat_id });
      fetchFavorites(); // Reload favorites after deletion
    } catch (error) {
      console.error('Error deleting favorite:', error);
    }
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleRowClick = (boat_id) => {
    window.open(`https://papasailor.com/detailsnodate/${boat_id}`, '_blank');
  };

  const handleDeleteClick = (boat_id) => {
    setSelectedBoatId(boat_id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (selectedBoatId !== null) {
      deleteFavorite(selectedBoatId);
    }
    setDeleteDialogOpen(false);
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };

  const filteredFavorites = favorites.filter(favorite =>
    favorite.name.toLowerCase().includes(filter.toLowerCase()) ||
    favorite.location.toLowerCase().includes(filter.toLowerCase())
  );

  const columns = [
    {
      field: 'picture',
      headerName: 'Picture',
      flex: 1,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="boat"
          width="50"
          style={{ cursor: 'pointer' }}
          onClick={() => handleRowClick(params.row.boat_id)}
        />
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
      renderCell: (params) => (
        <Box
          display="flex"
          alignItems="center"
          onClick={() => handleRowClick(params.row.boat_id)}
          style={{ cursor: 'pointer' }}
        >
          <img src={params.row.flag} alt="flag" width="20" style={{ marginRight: '10px' }} />
          <Box component="span" sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
            {params.value}
          </Box>
        </Box>
      ),
    },
    {
      field: 'location',
      headerName: 'Location',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <IconButton color="secondary" onClick={() => handleDeleteClick(params.row.boat_id)}>
          <DeleteForeverTwoToneIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Paper style={{ height: '100vh', width: '100%' }}>
      <Box sx={{ backgroundColor: 'rgb(41, 112, 184)' }}>
        <TextField
          sx={{
            width: '90%',
            '& .MuiInputBase-root': {
              backgroundColor: 'rgb(204, 230, 255)',
            },
          }}
          variant="outlined"
          placeholder="Search…"
          value={filter}
          onChange={handleFilterChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          margin="normal"
        />
      </Box>
      <DataGrid
        rows={filteredFavorites}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowId={(row) => row.boat_id}
        autoHeight
        sx={{
          '& .MuiDataGrid-cell': {
            whiteSpace: 'normal',
            wordWrap: 'break-word',
          },
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: 'white',
            color: 'black',
          },
        }}
      />
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
      >
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this favorite?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default Favoritesweb;
