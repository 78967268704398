import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Masonry from '@mui/lab/Masonry';
import SailingTwoToneIcon from '@mui/icons-material/SailingTwoTone';
import './i18n.js';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';


const items = [
  { height: 350, imageUrl: 'https://ws.nausys.com/CBMS-external/rest/yachtModel/259/pictures/main.jpg', link: "#", text: '' },
  { height: 160, imageUrl: 'https://ws.nausys.com/CBMS-external/rest/yacht/8341160/pictures/main.jpg', link: '#', text: '' },
  { height: 350, imageUrl: 'https://ws.nausys.com/CBMS-external/rest/yacht/39295250/pictures/main.jpg', link: '#', text: '' },
  { height: 160, imageUrl: 'https://ws.nausys.com/CBMS-external/rest/yacht/32014746/pictures/main.jpg', link: '#', text: '' },
  { height: 350, imageUrl: 'https://ws.nausys.com/CBMS-external/rest/yacht/5243171/pictures/main.jpeg', link: '#', text: '' },
  { height: 350, imageUrl: 'https://ws.nausys.com/CBMS-external/rest/yacht/23380490/pictures/main.jpg', link: '#', text: '' },
  { height: 160, imageUrl: 'https://ws.nausys.com/CBMS-external/rest/yacht/10122626/pictures/main.jpg', link: '#', text: '' },
  { height: 160, imageUrl: 'https://ws.nausys.com/CBMS-external/rest/yacht/30937961/pictures/main.jpeg', link: '#', text: '' },
  // Pridajte ďalšie objekty podľa potreby...
];

const Item = styled(Box)(({ theme, imageUrl }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  backgroundPosition: 'center',
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  position: 'relative', 
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: 'cover',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
}));

const TextOverlay = styled('div')({
  position: 'absolute', // Absolútna pozícia pre text, aby prekryl obrázok
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white', // Zmena farby textu pre lepšiu čitateľnosť
  //backgroundColor: 'rgba(0,0,0,0.5)', // Mierne zatemnenie obrázka pre lepšiu viditeľnosť textu
  textDecoration: 'none',
});

export function LastView() {
    const { t } = useTranslation();
  return (
    <Box sx={{ width: "80%", minHeight: 600 }}>
        <SailingTwoToneIcon sx={{ fontSize: 60, color: "#1D3A58 !important" }}></SailingTwoToneIcon>
        <Typography sx={{ color: "#1D3A58 !important" }}>   
        <h1 >{t("Last viewed yachts")}</h1>
        </Typography>
 
      <Masonry columns={4} spacing={2}>
        {items.map((item, index) => (
          <Item
            key={index}
            sx={{ height: item.height }}
            imageUrl={item.imageUrl}
          >
            <a href={item.link} style={{ textDecoration: 'none' }}>
              <TextOverlay>{item.text}</TextOverlay>
            </a>
          </Item>
        ))}
      </Masonry>
    </Box>
  );
}
