import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useState } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';


//translate
import { useTranslation } from 'react-i18next';
import './i18n.js';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;





export function EquipSelect({ selectedEquip, setSelectedEquip }) {
  const [inputValue, setInputValue] = useState("");
  const { t } = useTranslation();
  const equip = [
    { title: t("Heating"), value: 20, equipment_id: 20, type: 'equipment' },
    { title: t("Air Conditioning"), value: 4, equipment_id: 4, type: 'equipment' },
    { title: t("Bathing platform"), value: 100626, equipment_id: 100626, type: 'equipment' },
    { title: t("Bow thruster"), value: 2, equipment_id: 2, type: 'equipment' },
    { title: t("GPS chart plotter - cockpit"), value: 107379, equipment_id: 107379, type: 'equipment' },
    { title: t("Electric toilet"), value: 107381, equipment_id: 107381, type: 'equipment' }
  ];

  const handleOnChange = (event, newValue) => {
    // Determine the item that was clicked
    const clickedItem = newValue.find(
      option => !selectedEquip.includes(option)
    );

    // If the clicked item was not previously selected, add it to the array
    // Otherwise, remove it from the array
    if (selectedEquip.some(equip => equip.equipment_id === clickedItem.equipment_id)) {
      // Item is already selected, so remove it
      const newSelectedEquip = selectedEquip.filter(
        equip => equip.equipment_id !== clickedItem.equipment_id
      );
      setSelectedEquip(newSelectedEquip);
    } else {
      // New selection, add it
      setSelectedEquip([...selectedEquip, clickedItem]);
    }

    if (!newValue.length) {
      setInputValue("");
    }
  };

  return (
    <Box id="cSlider">
      <Autocomplete
        multiple
        limitTags="1"
        id="tags-outlined"
        options={equip}
        disableCloseOnSelect
        getOptionLabel={(option) => option.title}
        value={selectedEquip}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        onChange={handleOnChange}
        renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option.title}
          
                // Explicitly omitting onDelete prop to remove the "X" icon
              />
            ))
          }

        renderOption={(props, option) => {
          const isSelected = selectedEquip.some(equip => equip.equipment_id === option.equipment_id);

          return (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={isSelected} // Check if the current option is in the selectedEquip array
              />
              {option.title}
            </li>
          );
        }}
        

        renderInput={(params) => (
          <TextField {...params} label={t("Equipment")} placeholder={t("Equipment")} 
          sx={{
            "& .MuiInputBase-root": {
              backgroundColor: "white", // Set the background color of the input field
              '& fieldset': {
                borderColor: '#B2E0FA', // Default border color
              },
              '&:hover fieldset': {
                borderColor: '#D8D9DA', // Hover border color
              },
              '&.Mui-focused fieldset': {
                borderColor: '#D8D9DA', // Border color when the input is focused
              },
            },
          }}  />
        )}
        clearIcon={null}

      />
    </Box>

  );

}





