import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import './i18n.js';
import { TextField, Button, Box, Typography, Modal, Card, CircularProgress, CardContent, CardActions, CardMedia, IconButton, Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { toast } from 'react-toastify';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import SendIcon from '@mui/icons-material/Send';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import SailingTwoToneIcon from '@mui/icons-material/SailingTwoTone';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Checkbox, FormControlLabel } from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import heic2any from "heic2any";
import EXIF from 'exif-js';
import { Link } from 'react-router-dom';


const MAX_CHARS = 300;
const COLOR = '#1976d2';




const isValidUrl = (string) => {
    try {
        new URL(string);
        return true;
    } catch (_) {
        return false;
    }
};

const getYoutubeEmbedUrl = (url) => {
    const match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
    return match ? `https://www.youtube.com/embed/${match[1]}` : null;
};

const getDomainFromUrl = (url) => {
    try {
        const newUrl = new URL(url);
        return newUrl.hostname;
    } catch (_) {
        return url;
    }
};

const Thewall = (props) => {
    console.log('Props:', props);
    const { t, i18n } = useTranslation();
    const [uploading, setUploading] = useState(false);
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState('');
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState('');
    const [likedPosts, setLikedPosts] = useState([]);
    const [comments, setComments] = useState({});
    const [commentContent, setCommentContent] = useState('');
    const [expandedPosts, setExpandedPosts] = useState([]);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [postToDelete, setPostToDelete] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [postToEdit, setPostToEdit] = useState(null);
    const [editContent, setEditContent] = useState('');
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [lightboxImage, setLightboxImage] = useState('');
    const [followedUsers, setFollowedUsers] = useState([]);
    const [showOnlyFollowed, setShowOnlyFollowed] = useState(false);
    // Funkce pro změnu checkboxu
    const handleShowOnlyFollowedChange = (event) => {
        setShowOnlyFollowed(event.target.checked);
        fetchPosts(true, event.target.checked);
    };

    const formatTimestamp = (timestamp) => {
        const now = new Date();
        const postDate = new Date(timestamp);
        const diffInSeconds = (now - postDate) / 1000;
        const diffInMinutes = diffInSeconds / 60;
        const diffInHours = diffInMinutes / 60;

        if (diffInSeconds < 180) return t('now');
        if (diffInMinutes < 60) return t('minutes_ago', { count: Math.floor(diffInMinutes) });
        if (diffInHours < 48) return t('hours_ago', { count: Math.floor(diffInHours) });

        return postDate.toLocaleString();
    };


    // Funkce pro stáhnutí sledování
    const fetchFollows = async () => {
        try {
            const response = await axios.post('/follows', { email: props.email });
            console.log('Followed users response:', response.data);
            setFollowedUsers(response.data);
        } catch (error) {
            console.error("Error fetching follows:", error);
        }
    };

    // Při načtení komponenty stáhneme sledování
    useEffect(() => {
        if (props.email) {
            console.log('Fetching user ID for email:', props.email);
            fetchCurrentUserId();
            fetchFollows();
            fetchPosts(true);
        } else {
            console.log('Email not available yet.');
        }
    }, [props.email]);

    // Funkcia pre sledovanie/odsledovanie užívateľov
    const handleFollow = async (followedId) => {
        try {
            await axios.post('/follow', { email: props.email, followed_id: followedId });
            fetchFollows(); // Po zmene sledovania znovu načítame zoznam sledovaných užívateľov
        } catch (error) {
            console.error("Error following/unfollowing user:", error);
        }
    };

    const handleOpen = () => {
        setYoutubeLinkDetected(false); // Reset the youtubeLinkDetected state
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setContent('');
        setSelectedFile(null);
        setPreview('');
        setYoutubeLinkDetected(false); // Reset the youtubeLinkDetected state
    };
    const handleContentChange = (event) => {
        const newContent = event.target.value.slice(0, MAX_CHARS);
        setContent(newContent);
        setYoutubeLinkDetected(!!getYoutubeEmbedUrl(newContent));
    };

    const [youtubeLinkDetected, setYoutubeLinkDetected] = useState(false);


    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        const supportedFormats = ['image/jpeg', 'image/png', 'image/gif', 'image/heif', 'image/heic'];

        if (!supportedFormats.includes(file.type)) {
            toast.error(t('unsupported file format'));
            return;
        }

        setUploading(true);

        if (file.type === 'image/heif' || file.type === 'image/heic') {
            try {
                const convertedBlob = await heic2any({ blob: file, toType: 'image/jpeg' });
                const convertedFile = new File([convertedBlob], file.name.replace(/\.[^.]+$/, '.jpg'), { type: 'image/jpeg' });
                setSelectedFile(convertedFile);
                setPreview(URL.createObjectURL(convertedFile));
            } catch (error) {
                console.error('Error converting HEIC image:', error);
                toast.error(t('failed to convert HEIC image'));
            }
        } else {
            setSelectedFile(file);
            setPreview(URL.createObjectURL(file));
        }

        setUploading(false);
    };




    const handleDeleteImage = () => {
        setSelectedFile(null);
        setPreview('');
    };

    const fetchCurrentUserId = async () => {
        try {
            console.log('Calling API with email:', props.email);
            const response = await axios.post('/getUserId', { email: props.email });
            console.log('API response:', response.data);
            setCurrentUserId(response.data.user_id);
        } catch (error) {
            console.error("Error fetching user ID:", error);
        }
    };


    const handleSubmit = async () => {
        let pictureUrl = '';
        setUploading(true); // Spusti nahrávanie

        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('user_id', currentUserId); // Replace with actual user ID

            try {
                console.log("Uploading file:", selectedFile); // Logovanie pre kontrolu
                const response = await axios.post('/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                console.log("Upload response:", response.data);

                pictureUrl = response.data.url;
            } catch (error) {
                console.error('Failed to upload the image:', error);
                toast.error(t('Failed to upload the image. Please try again.'));
                setUploading(false); // Zastavi nahrávanie ak nastane chyba
                return; // Exit the function if the image upload fails
            }
        }

        await axios.post('/add_post', {
            content,
            pictureUrl,
            userId: currentUserId, // Replace with actual user ID
        });

        console.log("currentUserId:", currentUserId);

        handleClose();
        fetchPosts(true);
        setUploading(false); // Zastavi nahrávanie po úspešnom nahraní
    };



    const fetchPosts = async (reset = false, onlyFollowed = showOnlyFollowed) => {
        setLoading(true);
        try {
            const response = await axios.post('/wall', { email: props.email, offset: reset ? 0 : offset, onlyFollowed });
            const postsWithLikes = await Promise.all(response.data.map(async (post) => {
                const likes_count = await getNumberLikes(post.ID);
                return { ...post, likes_count };
            }));
            if (reset) {
                setPosts(postsWithLikes);
                setOffset(10);
            } else {
                setPosts((prevPosts) => [...prevPosts, ...postsWithLikes]);
                setOffset((prevOffset) => prevOffset + 10);
            }
            if (response.data.length < 10) {
                setAllPostsLoaded(true);
            }
        } catch (error) {
            console.error("Error fetching posts:", error);
        } finally {
            setLoading(false);
        }
    };


    const [allPostsLoaded, setAllPostsLoaded] = useState(false);


    const fetchLikes = async () => {
        try {
            const response = await axios.post('/likes', { email: props.email });
            setLikedPosts(response.data);
        } catch (error) {
            console.error("Error fetching likes:", error);
        }
    };

    const fetchComments = async (wallId) => {
        try {
            const response = await axios.get(`/comments/${wallId}`);
            setComments((prevComments) => ({
                ...prevComments,
                [wallId]: response.data
            }));
        } catch (error) {
            console.error("Error fetching comments:", error);
        }
    };

    const handleLike = async (wallId) => {
        try {
            const response = await axios.post('/like', { wall_id: wallId, email: props.email });
            const updatedLikesCount = response.data.likes_count;

            setPosts((prevPosts) =>
                prevPosts.map((post) =>
                    post.ID === wallId ? { ...post, likes_count: updatedLikesCount } : post
                )
            );

            fetchLikes();
        } catch (error) {
            console.error("Error liking post:", error);
        }
    };

    const handleCommentChange = (event) => {
        setCommentContent(event.target.value);
    };

    const handleCommentSubmit = async (wallId) => {
        if (!commentContent.trim()) return;

        try {
            await axios.post('/comments', {
                wall_id: wallId,
                content: commentContent,
                email: props.email,
            });

            setCommentContent('');
            fetchComments(wallId);
        } catch (error) {
            console.error("Error adding comment:", error);
        }
    };

    const handleDeleteComment = async (commentId, wallId) => {
        try {
            await axios.post('/comments/delete', {
                comment_id: commentId,
                email: props.email,
            });

            fetchComments(wallId);
        } catch (error) {
            console.error("Error deleting comment:", error);
        }
    };

    const handleDeletePost = async () => {
        if (!postToDelete) return;

        try {
            await axios.post('/delete_post', {
                post_id: postToDelete,
                email: props.email,
            });

            setDeleteDialogOpen(false);
            setPostToDelete(null);
            fetchPosts(true);
        } catch (error) {
            console.error("Error deleting post:", error);
        }
    };

    const handleExpandPost = (wallId) => {
        if (expandedPosts.includes(wallId)) {
            setExpandedPosts(expandedPosts.filter(id => id !== wallId));
        } else {
            setExpandedPosts([...expandedPosts, wallId]);
            fetchComments(wallId);
        }
    };



    useEffect(() => {
        fetchPosts(true);
        fetchCurrentUserId();
    }, []);

    useEffect(() => {
        if (posts.length > 0) {
            fetchLikes();
            posts.forEach(post => fetchComments(post.ID));
        }
    }, [posts]);

    const openDeleteDialog = (postId) => {
        setPostToDelete(postId);
        setDeleteDialogOpen(true);
    };

    const closeDeleteDialog = () => {
        setPostToDelete(null);
        setDeleteDialogOpen(false);
    };

    const openEditDialog = (post) => {
        setPostToEdit(post.ID);
        setEditContent(post.content);
        setEditDialogOpen(true);
    };

    const closeEditDialog = () => {
        setPostToEdit(null);
        setEditContent('');
        setEditDialogOpen(false);
    };

    const handleEditContentChange = (event) => {
        const newContent = event.target.value.slice(0, MAX_CHARS);
        setEditContent(newContent);
    };

    const handleEditSubmit = async () => {
        if (!postToEdit) return;

        try {
            await axios.post('/edit_post', {
                post_id: postToEdit,
                content: editContent,
                email: props.email,
            });

            closeEditDialog();
            fetchPosts(true);
        } catch (error) {
            console.error("Error editing post:", error);
        }
    };

    const openLightbox = (url) => {
        setLightboxImage(url);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setLightboxOpen(false);
        setLightboxImage('');
    };
    const getNumberLikes = async (wallId) => {
        try {
            const response = await axios.post('/get_number_likes', { wall_id: wallId });
            return response.data.likes_count;
        } catch (error) {
            console.error("Error fetching number of likes:", error);
            return 0;
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-start"
            minHeight="100vh"
            bgcolor="#f5f5f5"
            p={2}
            mt={3}
        >

            <Button
                variant="outlined"
                startIcon={<ControlPointIcon />}
                onClick={props.email ? handleOpen : () => toast.error(t('Error adding to favorites. Are you logged in?'))}
                sx={{ width: { xs: '90%', md: '500px' }, marginBottom: 2 }}
            >
                {t('add post')}
            </Button>
            <FormControlLabel
                control={<Checkbox checked={showOnlyFollowed} onChange={handleShowOnlyFollowedChange} />}
                label={t('show only followed')}


                sx={{ width: { xs: '90%', md: '500px' }, marginBottom: 2 }}
            />


            <Modal open={open} onClose={handleClose}>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    bgcolor="#fff"
                    boxShadow={1}
                    borderRadius={2}
                    p={3}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '90%', md: '50%' },
                    }}
                >
                    {uploading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
                            <CircularProgress />
                            <SailingTwoToneIcon
                                color="info"
                                style={{
                                    position: 'absolute',
                                    fontSize: 20,
                                }}
                            />
                        </Box>
                    ) : (
                        <>
                            <TextField
                                variant="outlined"
                                placeholder={t('write your post...')}
                                value={content}
                                onChange={handleContentChange}
                                multiline
                                rows={4}
                                maxRows={4}
                                inputProps={{ maxLength: MAX_CHARS }}
                                sx={{ width: '100%', marginBottom: 2 }}
                            />
                            <Typography variant="caption" color="textSecondary" align="left" sx={{ width: '100%' }}>
                                {content.length}/{MAX_CHARS} {t('characters')}
                            </Typography>
                            {preview && (
                                <Box
                                    sx={{
                                        position: 'relative',
                                        maxHeight: 200,
                                        maxWidth: '100%',
                                        marginBottom: 2
                                    }}
                                >
                                    <Box
                                        component="img"
                                        src={preview}
                                        alt="Selected image"
                                        sx={{
                                            maxHeight: 200,
                                            maxWidth: '100%'
                                        }}
                                    />
                                    <IconButton
                                        onClick={handleDeleteImage}
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            color: 'red',
                                        }}
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </Box>
                            )}
                            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mt={2}>
                                {!youtubeLinkDetected && (
                                    <Button
                                        variant="outlined"
                                        component="label"
                                        startIcon={<AddAPhotoIcon />}
                                        sx={{ marginRight: 2 }}
                                    >
                                        {t('FOTO')}
                                        <input type="file" hidden onChange={handleFileChange} />
                                    </Button>
                                )}
                                <Box flexGrow={1} />
                                <Button variant="outlined" endIcon={<SendIcon />} onClick={handleSubmit}>
                                    {t('send')}
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
            </Modal>



            {lightboxOpen && (
                <Lightbox
                    mainSrc={lightboxImage}
                    onCloseRequest={closeLightbox}
                />
            )}

            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{ width: '100%', maxWidth: '500px' }}
            >
                {posts.map((post, index) => {
                    const youtubeEmbedUrl = getYoutubeEmbedUrl(post.content);
                    const contentParts = post.content.split(' ').filter(part => !isValidUrl(part) || !getYoutubeEmbedUrl(part)).map((part, i) => {
                        if (isValidUrl(part)) {
                            return (
                                <a key={i} href={part} target="_blank" rel="noopener noreferrer">
                                    {getDomainFromUrl(part)}
                                </a>
                            );
                        }
                        return part + ' ';
                    });


                    return (
                        <Card key={index} sx={{ width: '100%', margin: '10px 0', position: 'relative' }}>
                            <CardContent sx={{ paddingTop: 2, paddingLeft: 0, paddingRight: 0 }}>
                                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 2 }}>
                                    <Box display="flex" alignItems="center">
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                color: COLOR,
                                                marginRight: 1,
                                                marginLeft: 2
                                            }}
                                        >
                                            <Link to={`/profile/${post.user_id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                {post.meno} {post.priezvisko}
                                            </Link>
                                        </Typography>
                                        <Box display="flex" alignItems="center">
                                            <IconButton size="small" onClick={props.email ? () => handleFollow(post.user_id) : () => toast.error(t('Error adding to favorites. Are you logged in?'))}>
                                                {followedUsers.includes(post.user_id) ? (
                                                    <FavoriteTwoToneIcon sx={{ color: COLOR }} />
                                                ) : (
                                                    <FavoriteBorderIcon sx={{ color: COLOR }} />
                                                )}
                                            </IconButton>

                                        </Box>
                                    </Box>
                                    <Typography variant="caption" color="textSecondary" sx={{ marginRight: 2 }}>
                                        {formatTimestamp(post.timestamp)}
                                    </Typography>
                                </Box>
                                {post.pictureUrl && !youtubeEmbedUrl && (
                                    <CardMedia
                                        component="img"
                                        image={post.pictureUrl}
                                        alt="Post image"
                                        sx={{ width: '100%', cursor: 'pointer' }}
                                        onClick={() => openLightbox(post.pictureUrl)}
                                    />
                                )}
                                {youtubeEmbedUrl && (
                                    <CardMedia
                                        component="iframe"
                                        height="250"
                                        src={youtubeEmbedUrl}
                                        title="YouTube video"
                                        sx={{ width: '100%', border: 0 }}
                                    />
                                )}
                                <Typography variant="body1" sx={{ marginTop: 1, marginLeft: 2, marginRight: 2, textAlign: 'left', fontFamily: 'Arial, sans-serif' }}>
                                    {contentParts}
                                </Typography>



                            </CardContent>
                            <CardActions sx={{ justifyContent: 'space-between' }}>
                                <Box display="flex" alignItems="center">
                                    <IconButton size="small" onClick={props.email ? () => handleLike(post.ID) : () => toast.error(t('Error adding to favorites. Are you logged in?'))}>
                                        {likedPosts.includes(post.ID) ? (
                                            <ThumbUpIcon sx={{ color: COLOR }} />
                                        ) : (
                                            <ThumbUpOffAltIcon />
                                        )}
                                    </IconButton>
                                    <Typography variant="body2" sx={{ color: COLOR, marginLeft: 1 }}>
                                        {post.likes_count}
                                    </Typography>
                                </Box>

                                <Box>
                                    {post.user_id === currentUserId && (
                                        <>
                                            <IconButton
                                                onClick={() => openEditDialog(post)}
                                                sx={{ marginLeft: 1 }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                onClick={() => openDeleteDialog(post.ID)}
                                                sx={{ marginLeft: 1 }}
                                            >
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        </>
                                    )}
                                </Box>
                                <Box display="flex" alignItems="center">
                                    <IconButton size="small" onClick={props.email ? () => handleExpandPost(post.ID) : () => toast.error(t('Error adding to favorites. Are you logged in?'))}>
                                        <ChatOutlinedIcon />
                                    </IconButton>

                                    <Typography variant="body2" sx={{ color: COLOR, marginLeft: 1 }}>
                                        {comments[post.ID] ? comments[post.ID].length : 0}
                                    </Typography>

                                </Box>
                            </CardActions>
                            {expandedPosts.includes(post.ID) && (
                                <Box sx={{ bgcolor: '#f0f0f0', p: 2 }}>
                                    {comments[post.ID] && comments[post.ID].map((comment) => (
                                        <React.Fragment key={comment.id}>
                                            <Box display="flex" flexDirection="column" alignItems="flex-start" mb={2} mt={1}>
                                                <Box display="flex" justifyContent="space-between" width="100%">
                                                    <Box display="flex" alignItems="center">
                                                        <Typography variant="body2" sx={{ color: COLOR, marginRight: 1 }}>
                                                            {comment.meno} {comment.priezvisko}
                                                        </Typography>
                                                        <Typography variant="caption" color="textSecondary">
                                                            {formatTimestamp(comment.timestamp)}
                                                        </Typography>
                                                    </Box>
                                                    {comment.email === props.email && (
                                                        <IconButton onClick={() => handleDeleteComment(comment.id, post.ID)} sx={{ color: 'red' }}>
                                                            <DeleteForeverIcon />
                                                        </IconButton>
                                                    )}
                                                </Box>
                                                <Typography variant="caption" sx={{ mt: 1 }}>{comment.content}</Typography>
                                            </Box>
                                            <Divider />
                                        </React.Fragment>
                                    ))}
                                    <TextField
                                        variant="outlined"
                                        placeholder={t('write your comment...')}
                                        value={commentContent}
                                        onChange={handleCommentChange}
                                        multiline
                                        rows={2}
                                        maxRows={4}
                                        sx={{ width: '100%', marginTop: 2 }}
                                    />
                                    <Button
                                        onClick={() => handleCommentSubmit(post.ID)}
                                        variant="outlined"
                                        endIcon={<SendIcon />}
                                        sx={{ marginTop: 1 }}
                                    >
                                        {t('send')}
                                    </Button>
                                </Box>
                            )}
                        </Card>
                    );
                })}
            </Box>
            {!allPostsLoaded && (
                <Button
                    variant="outlined"
                    onClick={() => fetchPosts()}
                    disabled={loading}
                    sx={{ marginTop: 2 }}
                >
                    {loading ? t('loading...') : t('load more')}
                </Button>
            )}


            <Dialog
                open={deleteDialogOpen}
                onClose={closeDeleteDialog}
            >
                <DialogTitle>{t('confirm delete')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('are you sure you want to delete this post?')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} color="primary">
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleDeletePost} color="primary">
                        {t('delete')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={editDialogOpen}
                onClose={closeEditDialog}
            >
                <DialogTitle>{t('edit post')}</DialogTitle>
                <DialogContent>
                    <TextField
                        variant="outlined"
                        placeholder={t('write your post...')}
                        value={editContent}
                        onChange={handleEditContentChange}
                        multiline
                        rows={4}
                        maxRows={4}
                        inputProps={{ maxLength: MAX_CHARS }}
                        sx={{ width: '100%', marginBottom: 2, maxWidt: '500px' }}
                    />
                    <Typography variant="caption" color="textSecondary" align="left" sx={{ width: '100%' }}>
                        {editContent.length}/{MAX_CHARS} {t('characters')}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeEditDialog} color="primary">
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleEditSubmit} color="primary">
                        {t('save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Thewall;
