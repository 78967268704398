import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import FmdGoodTwoToneIcon from '@mui/icons-material/FmdGoodTwoTone';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import Tooltip from '@mui/material/Tooltip';
import { Hidden, Switch } from '@mui/material';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import MeetingRoomTwoToneIcon from '@mui/icons-material/MeetingRoomTwoTone';
import SingleBedIcon from '@mui/icons-material/SingleBed';
import BedroomParentTwoToneIcon from '@mui/icons-material/BedroomParentTwoTone';
import AssignmentReturnedTwoToneIcon from '@mui/icons-material/AssignmentReturnedTwoTone';
import ExpandIcon from '@mui/icons-material/Expand';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import SailingIcon from '@mui/icons-material/Sailing';
import SailingTwoToneIcon from '@mui/icons-material/SailingTwoTone';
import PaymentsTwoToneIcon from '@mui/icons-material/PaymentsTwoTone';
import WcIcon from '@mui/icons-material/Wc';
import Button from '@mui/material/Button';
import StoreIcon from '@mui/icons-material/Store';
import StoreTwoToneIcon from '@mui/icons-material/StoreTwoTone';
import GroupsIcon from '@mui/icons-material/Groups';
import PaymentIcon from '@mui/icons-material/Payment';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import BathroomTwoToneIcon from '@mui/icons-material/BathroomTwoTone';
import StraightenTwoToneIcon from '@mui/icons-material/StraightenTwoTone';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import IconButton from '@mui/material/IconButton';
import BookmarkAddTwoToneIcon from '@mui/icons-material/BookmarkAddTwoTone';
import axios from 'axios';
import Badge from '@mui/material/Badge';
import GppGoodTwoToneIcon from '@mui/icons-material/GppGoodTwoTone';


//toast
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

//translate
import { useTranslation } from 'react-i18next';
import './i18n.js';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '60%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 6,
};

export const BoatCard = ({ boatData, startDate, endDate, email }) => {
  const { t } = useTranslation();
  const [isFavorited, setIsFavorited] = useState(false);


  const country = countries.find(c => c.title.trim() === boatData.country.trim());
  const flagUrl = country ? `https://papasailor.com/w20/${country.code.toLowerCase()}.png` : null;
  const chipColor = boatData.charterType === 'CREWED' ? 'error' : 'success';
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleFavoriteClick = ({ }) => {
    setIsFavorited(!isFavorited);

    // Prepare the data to be sent
    const favoriteData = {
      boat_id: boatData.id, // Replace 'boat_id' with the actual variable that holds this value
      email: email, // Replace 'email' with the actual variable
      mainpicture: boatData.boatPicture, // Replace 'mainpicture' with the actual variable
      location: boatData.location, // Replace 'location' with the actual variable
      boatmodel: boatData.yachtModelName, // Replace 'boatmodel' with the actual variable
      boatName: boatData.boatName, // Replace 'boatName' with the actual variable
      flag: flagUrl
    };




    // Concatenate boatmodel and boatName with a '|'


    // Make the POST request to the '/favorites' endpoint
    axios.post('/favorites', favoriteData)
      .then(response => {
        // Show success toast notification

        toast.success(t('Successfully added to favorites!'));

      })
      .catch(error => {
        // Show error toast notification
        toast.error(t('Error adding to favorites. Are you logged in?'));
        console.error('Error adding to favorites:', error);
      });
  };



  const companyFreeDinghyMotor = ['Dream Yacht Charter', 
  'Nautic Alliance',
  'Sailing club Vranjic',
  'Nova Eurospectra',
  'Istion Yachting',
  'Nautički centar Pina i Mare - NCP',
  'Vernicos Yachts',
  'Ban Tours',
  'WayPoint',
  'Under The Heavens',
  'Adriatic Sailing',
  'Noa Jedrenje',
  'Puresailing Yachting',
  'Orvas Yachting',
  'Sunturist Charter',
  'Bruneko Yacht Charter',
  'Sea and More Yachting',
  'Anemos Yachting',
  'Chilla Sailing',
  'Ocean Sailing House',
  'Isalos Yachting',
  'Smart Sail',
  'Ediba Sailing',
  'E-Sailing',
  'Vastardis Yachting',
  'Sailing Ionian Sea',
  'ALL-Seas',
  'Blue Sails',
  'ND Sails',
  'Sail Emerald',
  'Zen Yacht Charter',
  'Fancy Lobster',
  'Black&Soul',
  'Ionian Spirit',
  'Anyachting',
  'Mentis Yachting',
  'MGV Yachts Cruises',
  'Sail the Cyclades',
  'Anasa Experiences',
  'Anassa',
  'Serafina',
  'Chalkidiki Yachts',
  'Dream Charter Seychelles', 
  'Niriides Cruises',
  'Latria-Charter',
  'Eazyblue',
  'Sail in Grrece',
  'Thalassa Sailing Travels'
];
  const isCompanyEligible = companyFreeDinghyMotor.includes(boatData.company_name);
  const discountPercentage = boatData.listPrice > 0 ? ((boatData.listPrice - boatData.price) / boatData.listPrice) * 100 : 0;

  return (


    <div>


    

      <Card variant="outlined" sx={{
        position: 'relative', width: '97%', maxWidth: '97%', display: 'flex', '&:hover': {
          backgroundColor: '#fafafa'
        }
      }}>

        <Box sx={{ position: 'relative', width: '40%', height: '320px', marginLeft: '5px', marginTop: '5px', marginBottom: '5px' }}>
          <CardMedia
            component="img"
            sx={{ height: '100%', minWidth: '150px', objectFit: 'cover', borderRadius: '3px' }}
            image={boatData.boatPicture}
            alt="Boat Picture"


          />




          <Box sx={{ position: 'absolute', top: 10, left: 10, justifyContent: 'flex-end' }}>

            <Tooltip placement="top-end">
              <Chip onClick={handleFavoriteClick} label={t("To Favorites")} icon={<FavoriteTwoToneIcon />} color="info" variant="soft" />
            </Tooltip>
            <br></br>
            <Chip
              label={t(boatData.charterType)}
              color={chipColor}
              variant="soft"
              style={{
                marginTop: 5
              }}
              icon={<GroupsIcon />}
            />
            <br></br>

            {isCompanyEligible && (
        <Badge
          color="warning"
          badgeContent={t("free")}
          max={9999999}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Chip
            label={t("Dinghy motor")}
            color="secondary"
            variant="soft"
            style={{
              marginTop: 5
            }}
            icon={<GppGoodTwoToneIcon />}
          />
        </Badge>
      )}


          </Box>



        </Box>







        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '70%', position: 'relative' }} >
          <Hidden smDown>
            <CardHeader

              title={

                <div style={{ display: 'flex', alignItems: 'center' }}   >
                  {flagUrl && <img src={flagUrl} alt={boatData.country} style={{ marginRight: 10 }} />}
                  <Link
                    to={"/details/" + boatData.id + "/" + startDate + "/" + endDate}

                    target='_blank'
                    style={{ textDecoration: 'none' }}
                  >
                    <Typography variant='h6' sx={{ width: '40vw' }} noWrap>
                      {boatData.yachtModelName + " | " + boatData.boatName + " " + boatData.buildYear}
                    </Typography>

                  </Link>

                </div>


              }
              style={{ textAlign: 'left' }}
              subheader={
                <div style={{ display: 'flex', alignItems: 'center', paddingRight: 20, marginBottom: 0 }}>
                  <FmdGoodTwoToneIcon style={{ paddingRight: 5, color: '#57719f', marginBottom: 0 }} />
                  <Link style={{ textDecoration: 'none' }} onClick={handleOpen}>
                    <Typography sx={{ fontSize: "0.9rem !important" }}>
                      <p>{boatData.location}</p>
                    </Typography>
                  </Link>

                </div>
              }
              titleTypographyProps={{ variant: 'h6' }}
            />
          </Hidden>

          <Hidden smUp>
            <CardHeader
              title={

                <div style={{ display: 'flex', alignItems: 'center' }}>

                  {flagUrl && <img src={flagUrl} alt={boatData.country} style={{ marginRight: 10 }} />}
                  <Link
                    to={"/details/" + boatData.id + "/" + startDate + "/" + endDate}
                    style={{ textDecoration: 'none' }}
                    target='_blank'

                  >
                    <Typography variant='h6' sx={{ minWidth: '150px', maxWidth: '40vw' }} noWrap>
                      {boatData.yachtModelName + " | " + boatData.boatName + " " + boatData.buildYear}
                    </Typography>

                  </Link>
                </div>


              }
              style={{ textAlign: 'left' }}
              subheader={
                <div style={{ display: 'flex', alignItems: 'center', paddingRight: 20, marginBottom: 0 }}>

                  <Link style={{ textDecoration: 'none', fontSize: '1rem' }} onClick={handleOpen}>
                    <Typography sx={{ minWidth: '150px', maxWidth: '45vw' }} noWrap>
                      <FmdGoodTwoToneIcon style={{ paddingRight: 5, color: '#57719f', marginBottom: -5 }} />
                      {boatData.location} </Typography>
                  </Link>

                </div>
              }
              titleTypographyProps={{ variant: 'h6' }}
            />
          </Hidden>
          <CardContent sx={{ textAlign: 'left', position: 'relative' }}>



            <div className='tabulka'>
              <Hidden smUp>



                <table>
                  <tr><td style={{ width: 200 }}><b><StoreTwoToneIcon style={{ verticalAlign: 'middle', marginRight: 10 }} /> </b> <small>{boatData.company_name}</small></td></tr>
                  <tr><td style={{ width: 200 }}><b><SailingTwoToneIcon style={{ verticalAlign: 'middle', marginRight: 10 }} /> </b> <small>{t(boatData.sailTypeId)} </small></td></tr>
                  <td style={{ width: 200 }}><b><PaymentsTwoToneIcon style={{ verticalAlign: 'middle', marginRight: 10 }} /> </b> <small> {boatData.deposit} EUR</small></td>
                  <tr><td style={{ width: 200 }}><b><BedroomParentTwoToneIcon style={{ verticalAlign: 'middle', marginRight: 10 }} /> </b> <small>{boatData.berths} {t("Berths")}</small></td></tr>
                  <tr><td style={{ width: 200 }}><b><AssignmentReturnedTwoToneIcon style={{ verticalAlign: 'middle', marginRight: 10 }} /> </b> <small>{boatData.draft + "m "}</small></td></tr>
                  <tr><td style={{ width: 200 }}><b><StraightenTwoToneIcon style={{ verticalAlign: 'middle', marginRight: 10 }} /> </b> <small> {boatData.length + " ft / " + boatData.lengthMeters + " m"} </small></td></tr>




                </table>
              </Hidden>
              <Hidden smDown>



                <table>
                  <tr><td style={{ textAlign: 'left', paddingRight: '40px', verticalAlign: 'middle', paddingTop: '0px' }}><b><StoreTwoToneIcon style={{ verticalAlign: 'middle', paddingRight: 10 }} /> </b> <small>{boatData.company_name}</small></td><td style={{ textAlign: 'left', paddingRight: '20px', paddingTop: '5px' }}><b><SailingTwoToneIcon style={{ verticalAlign: 'middle', paddingRight: 10 }} /> </b> <small>{t(boatData.sailTypeId)} </small></td></tr>
                  <tr><td style={{ textAlign: 'left', paddingRight: '40px', verticalAlign: 'middle', paddingTop: '10px' }}><b><BedroomParentTwoToneIcon style={{ verticalAlign: 'middle', paddingRight: 10 }} /> </b> <small>{boatData.berths} {t("Berths")}</small></td><td style={{ textAlign: 'left', paddingRight: '20px', paddingTop: '5px' }}><b><PaymentsTwoToneIcon style={{ verticalAlign: 'middle', paddingRight: 10 }} /> </b> <small> {boatData.deposit} EUR</small></td></tr>
                  <tr><td style={{ textAlign: 'left', paddingRight: '40px', verticalAlign: 'middle', paddingTop: '10px' }}><b><MeetingRoomTwoToneIcon style={{ verticalAlign: 'middle', paddingRight: 10 }} /> </b> <small>{boatData.cabins} {t("cabins")}</small></td><td style={{ textAlign: 'left', paddingRight: '20px', paddingTop: '5px' }}><b><BathroomTwoToneIcon style={{ verticalAlign: 'middle', paddingRight: 10 }} /> </b> <small> {boatData.wc} {t("toilet")}</small></td></tr>
                  <tr><td style={{ textAlign: 'left', paddingRight: '40px', verticalAlign: 'middle', paddingTop: '10px' }}><b><AssignmentReturnedTwoToneIcon style={{ verticalAlign: 'middle', paddingRight: 10 }} /> </b> <small>{boatData.draft + "m "}{t("draft")}</small></td><td style={{ textAlign: 'left', paddingRight: '20px', paddingTop: '5px' }}><b><StraightenTwoToneIcon style={{ verticalAlign: 'middle', paddingRight: 10 }} /> </b> <small> {boatData.length + " ft / " + boatData.lengthMeters + " m"} </small></td></tr>




                </table>
              </Hidden>

            </div>



          </CardContent>


        </Box>





        <Link
          to={"/details/" + boatData.id + "/" + startDate + "/" + endDate}
          style={{
            textDecoration: 'none',

            position: 'absolute',
            bottom: 15,
            right: 15
          }}
          target='_blank'
        >
          {discountPercentage > 0 && (
            <Badge
              color="secondary"
              badgeContent={`-${Math.round(discountPercentage)}% ${t("discount")}`}
              max={9999999}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Button
                style={{
                  textDecoration: 'none',
                  width: 140,
                }}
                color='info'
                variant="outlined"
                id="more"
              >
                {boatData.price} €
              </Button>
            </Badge>
          )}

          {discountPercentage <= 0 && (
            <Button
              style={{
                textDecoration: 'none',
                width: 140,
              }}
              color='info'
              variant="outlined"
              id="more"
            >
              {boatData.price} €
            </Button>
          )}

        </Link>




      </Card>


      <br />

      <div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>

            <iframe
              title="Google Maps"
              width="100%"
              height="100%"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src={`https://maps.google.com/maps?q=${boatData.lat},${boatData.lon}&t=m&z=8&output=embed`}

            >
            </iframe>
            <br>
            </br>
            <CloseIcon />
            <Button key='' onClick={handleClose} sx={{ color: '#000', marginTop: -2, marginLeft: -1 }}>{t("Close")}</Button>
          </Box>

        </Modal>

      </div>

    </div>


  );
}



const countries = [
  { code: 'AF', title: 'Afghanistan', value: 100184 },
  { code: 'AL', title: 'Albania ', value: 100125 },
  { code: 'DZ', title: 'Algeria ', value: 100111 },
  { code: 'AS', title: 'American Samoa', value: 1221674 },
  { code: 'AD', title: 'Andorra ', value: 100137 },
  { code: 'AO', title: 'Angola', value: 1221676 },
  { code: 'AI', title: 'Anguilla', value: 103651 },
  { code: 'AQ', title: 'Antarctica', value: 1221680 },
  { code: 'AG', title: 'Antigua and Barbuda', value: 1221684 },
  { code: 'AR', title: 'Argentina', value: 100163 },
  { code: 'AM', title: 'Armenia', value: 100135 },
  { code: 'AW', title: 'Aruba', value: 1221693 },
  { code: 'AU', title: 'Australia', value: 100101 },
  { code: 'AT', title: 'Austria', value: 100102 },
  { code: 'AZ', title: 'Azerbaijan', value: 100205 },
  { code: 'BS', title: 'Bahamas', value: 491658 },
  { code: 'BH', title: 'Bahrain', value: 1221686 },
  { code: 'BD', title: 'Bangladesh', value: 100207 },
  { code: 'BB', title: 'Barbados', value: 1221705 },
  { code: 'BY', title: 'Belarus', value: 100131 },
  { code: 'BE', title: 'Belgium', value: 100106 },
  { code: 'BZ', title: 'Belize', value: 100218 },
  { code: 'BJ', title: 'Benin', value: 100221 },
  { code: 'BM', title: 'Bermuda', value: 100213 },
  { code: 'BT', title: 'Bhutan', value: 100225 },
  { code: 'BO', title: 'Bolivia', value: 100226 },
  { code: 'BA', title: 'Bosnia and Herzegovina', value: 100124 },
  { code: 'BW', title: 'Botswana', value: 100228 },
  { code: 'BR', title: 'Brazil', value: 100107 },
  { code: 'IO', title: 'British Indian Ocean Territory', value: 491656 },
  { code: 'VG', title: 'British Virgin Islands', value: 1221716 },
  { code: 'BN', title: 'Brunei', value: 100229 },
  { code: 'BG', title: 'Bulgaria', value: 100108 },
  { code: 'BF', title: 'Burkina Faso', value: 100231 },
  { code: 'BI', title: 'Burundi', value: 100233 },
  { code: 'KH', title: 'Cambodia', value: 100242 },
  { code: 'CM', title: 'Cameroon', value: 100239 },
  { code: 'CA', title: 'Canada', value: 100109 },
  { code: 'CV', title: 'Cape Verde', value: 100243 },
  { code: 'KY', title: 'Cayman Islands', value: 100216 },
  { code: 'CF', title: 'Central African Republic', value: 100244 },
  { code: 'TD', title: 'Chad', value: 100245 },
  { code: 'CL', title: 'Chile', value: 1001092 },
  { code: 'CN', title: 'China', value: 100110 },
  { code: 'CX', title: 'Christmas Island', value: 206671 },
  { code: 'CC', title: 'Cocos [Keeling] Islands', value: 206672 },
  { code: 'CO', title: 'Colombia', value: 100250 },
  { code: 'KM', title: 'Comoros', value: 100251 },
  { code: 'CK', title: 'Cook Islands', value: 100249 },
  { code: 'CR', title: 'Costa Rica', value: 100254 },
  { code: 'HR', title: 'Croatia', value: 1 },
  { code: 'CU', title: 'Cuba', value: 100255 },
  { code: 'CW', title: 'Curaçao', value: 168301 },
  { code: 'CY', title: 'Cyprus', value: 100126 },
  { code: 'CZ', title: 'Czech Republic', value: 100112 },
  { code: 'CD', title: 'Democratic Republic of the Congo', value: 100257 },
  { code: 'DK', title: 'Denmark', value: 100113 },
  { code: 'DJ', title: 'Djibouti', value: 100258 },
  { code: 'DM', title: 'Dominica', value: 100262 },
  { code: 'DO', title: 'Dominican Republic', value: 100263 },
  { code: 'EC', title: 'Ecuador', value: 100267 },
  { code: 'EG', title: 'Egypt', value: 100114 },
  { code: 'SV', title: 'El Salvador', value: 100274 },
  { code: 'GQ', title: 'Equatorial Guinea', value: 100268 },
  { code: 'ER', title: 'Eritrea', value: 100270 },
  { code: 'EE', title: 'Estonia', value: 100115 },
  { code: 'ET', title: 'Ethiopia', value: 100271 },
  { code: 'FK', title: 'Falkland Islands', value: 100277 },
  { code: 'FO', title: 'Faroe Islands', value: 100118 },
  { code: 'FJ', title: 'Fiji', value: 100276 },
  { code: 'FI', title: 'Finland', value: 100119 },
  { code: 'FR', title: 'France', value: 100120 },
  { code: 'GF', title: 'French Guiana', value: 100280 },
  { code: 'PF', title: 'French Polynesia', value: 100278 },
  { code: 'GA', title: 'Gabon', value: 100282 },
  { code: 'GM', title: 'Gambia', value: 100286 },
  { code: 'GE', title: 'Georgia', value: 100128 },
  { code: 'DE', title: 'Germany', value: 100122 },
  { code: 'GH', title: 'Ghana', value: 100287 },
  { code: 'GI', title: 'Gibraltar', value: 100288 },
  { code: 'GR', title: 'Greece', value: 100123 },
  { code: 'GL', title: 'Greenland', value: 100124 },
  { code: 'GD', title: 'Grenada', value: 100292 },
  { code: 'GP', title: 'Guadeloupe', value: 100293 },
  { code: 'GU', title: 'Guam', value: 100294 },
  { code: 'GT', title: 'Guatemala', value: 100298 },
  { code: 'GG', title: 'Guernsey', value: 100296 },
  { code: 'GN', title: 'Guinea', value: 100299 },
  { code: 'GW', title: 'Guinea-Bissau', value: 100301 },
  { code: 'GY', title: 'Guyana', value: 100303 },
  { code: 'HT', title: 'Haiti', value: 100304 },
  { code: 'HN', title: 'Honduras', value: 100307 },
  { code: 'HK', title: 'Hong Kong', value: 100125 },
  { code: 'HU', title: 'Hungary', value: 100126 },
  { code: 'IS', title: 'Iceland', value: 100129 },
  { code: 'IN', title: 'India', value: 100130 },
  { code: 'ID', title: 'Indonesia', value: 100131 },
  { code: 'IR', title: 'Iran', value: 100132 },
  { code: 'IQ', title: 'Iraq', value: 100133 },
  { code: 'IE', title: 'Ireland', value: 100134 },
  { code: 'IM', title: 'Isle of Man', value: 100135 },
  { code: 'IL', title: 'Israel', value: 100136 },
  { code: 'IT', title: 'Italy', value: 100147 },
  { code: 'JM', title: 'Jamaica', value: 100138 },
  { code: 'JP', title: 'Japan', value: 100139 },
  { code: 'JE', title: 'Jersey', value: 100140 },
  { code: 'JO', title: 'Jordan', value: 100141 },
  { code: 'KZ', title: 'Kazakhstan', value: 100142 },
  { code: 'KE', title: 'Kenya', value: 100143 },
  { code: 'KI', title: 'Kiribati', value: 100144 },
  { code: 'KW', title: 'Kuwait', value: 100145 },
  { code: 'KG', title: 'Kyrgyzstan', value: 100146 },
  { code: 'LA', title: 'Lao Peoples Democratic Republic', value: 100147 },
  { code: 'LV', title: 'Latvia', value: 100148 },
  { code: 'LB', title: 'Lebanon', value: 100149 },
  { code: 'LS', title: 'Lesotho', value: 100150 },
  { code: 'LR', title: 'Liberia', value: 100151 },
  { code: 'LY', title: 'Libya', value: 100152 },
  { code: 'LI', title: 'Liechtenstein', value: 100153 },
  { code: 'LT', title: 'Lithuania', value: 100154 },
  { code: 'LU', title: 'Luxembourg', value: 100155 },
  { code: 'MO', title: 'Macao', value: 100156 },
  { code: 'MG', title: 'Madagascar', value: 100157 },
  { code: 'MW', title: 'Malawi', value: 100158 },
  { code: 'MY', title: 'Malaysia', value: 100159 },
  { code: 'MV', title: 'Maldives', value: 100160 },
  { code: 'ML', title: 'Mali', value: 100161 },
  { code: 'MT', title: 'Malta', value: 100162 },
  { code: 'MH', title: 'Marshall Islands', value: 100163 },
  { code: 'MQ', title: 'Martinique', value: 100164 },
  { code: 'MR', title: 'Mauritania', value: 100165 },
  { code: 'MU', title: 'Mauritius', value: 100166 },
  { code: 'YT', title: 'Mayotte', value: 100167 },
  { code: 'MX', title: 'Mexico', value: 100168 },
  { code: 'FM', title: 'Micronesia', value: 100169 },
  { code: 'MD', title: 'Moldova', value: 100170 },
  { code: 'MC', title: 'Monaco', value: 100171 },
  { code: 'MN', title: 'Mongolia', value: 100172 },
  { code: 'ME', title: 'Montenegro', value: 100173 },
  { code: 'MS', title: 'Montserrat', value: 1221920 },
  { code: 'MA', title: 'Morocco', value: 100175 },
  { code: 'MZ', title: 'Mozambique', value: 100176 },
  { code: 'MM', title: 'Myanmar', value: 100177 },
  { code: 'NA', title: 'Namibia', value: 100178 },
  { code: 'NR', title: 'Nauru', value: 100179 },
  { code: 'NP', title: 'Nepal', value: 100180 },
  { code: 'NL', title: 'Netherlands', value: 100181 },
  { code: 'NC', title: 'New Caledonia', value: 100182 },
  { code: 'NZ', title: 'New Zealand', value: 100183 },
  { code: 'NI', title: 'Nicaragua', value: 100184 },
  { code: 'NE', title: 'Niger', value: 100185 },
  { code: 'NG', title: 'Nigeria', value: 100186 },
  { code: 'NU', title: 'Niue', value: 100187 },
  { code: 'NF', title: 'Norfolk Island', value: 100188 },
  { code: 'KP', title: 'North Korea', value: 100189 },
  { code: 'MK', title: 'North Macedonia', value: 100190 },
  { code: 'NO', title: 'Norway', value: 100191 },
  { code: 'OM', title: 'Oman', value: 100192 },
  { code: 'PK', title: 'Pakistan', value: 100193 },
  { code: 'PW', title: 'Palau', value: 100194 },
  { code: 'PS', title: 'Palestine', value: 100195 },
  { code: 'PA', title: 'Panama', value: 100196 },
  { code: 'PG', title: 'Papua New Guinea', value: 100197 },
  { code: 'PY', title: 'Paraguay', value: 100198 },
  { code: 'PE', title: 'Peru', value: 100199 },
  { code: 'PH', title: 'Philippines', value: 100200 },
  { code: 'PN', title: 'Pitcairn Islands', value: 100201 },
  { code: 'PL', title: 'Poland', value: 100202 },
  { code: 'PT', title: 'Portugal', value: 100203 },
  { code: 'QA', title: 'Qatar', value: 100204 },
  { code: 'RO', title: 'Romania', value: 100205 },
  { code: 'RU', title: 'Russia', value: 100206 },
  { code: 'RW', title: 'Rwanda', value: 100207 },
  { code: 'RE', title: 'Réunion', value: 100208 },
  { code: 'BL', title: 'Saint Barthélemy', value: 100209 },
  { code: 'SH', title: 'Saint Helena, Ascension and Tristan da Cunha', value: 100210 },
  { code: 'KN', title: 'Saint Kitts and Nevis', value: 100211 },
  { code: 'LC', title: 'Saint Lucia', value: 100212 },
  { code: 'MF', title: 'Saint Martin', value: 100213 },
  { code: 'PM', title: 'Saint Pierre and Miquelon', value: 100214 },
  { code: 'VC', title: 'Saint Vincent and the Grenadines', value: 100215 },
  { code: 'WS', title: 'Samoa', value: 100216 },
  { code: 'SM', title: 'San Marino', value: 100217 },
  { code: 'ST', title: 'São Tomé and Príncipe', value: 100218 },
  { code: 'SA', title: 'Saudi Arabia', value: 100219 },
  { code: 'SN', title: 'Senegal', value: 100220 },
  { code: 'RS', title: 'Serbia', value: 100221 },
  { code: 'SC', title: 'Seychelles', value: 100222 },
  { code: 'SL', title: 'Sierra Leone', value: 100223 },
  { code: 'SG', title: 'Singapore', value: 100224 },
  { code: 'SX', title: 'Sint Maarten', value: 100225 },
  { code: 'SK', title: 'Slovakia', value: 100226 },
  { code: 'SI', title: 'Slovenia', value: 100227 },
  { code: 'SB', title: 'Solomon Islands', value: 100228 },
  { code: 'SO', title: 'Somalia', value: 100229 },
  { code: 'ZA', title: 'South Africa', value: 100230 },
  { code: 'GS', title: 'South Georgia and the South Sandwich Islands', value: 100231 },
  { code: 'SS', title: 'South Sudan', value: 100232 },
  { code: 'ES', title: 'Spain', value: 100233 },
  { code: 'LK', title: 'Sri Lanka', value: 100234 },
  { code: 'SD', title: 'Sudan', value: 100235 },
  { code: 'SR', title: 'Suriname', value: 100236 },
  { code: 'SJ', title: 'Svalbard and Jan Mayen', value: 100237 },
  { code: 'SE', title: 'Sweden', value: 100238 },
  { code: 'CH', title: 'Switzerland', value: 100239 },
  { code: 'SY', title: 'Syria', value: 100240 },
  { code: 'TW', title: 'Taiwan', value: 100241 },
  { code: 'TJ', title: 'Tajikistan', value: 100242 },
  { code: 'TH', title: 'Thailand ', value: 100244 },
  { code: 'TZ', title: 'Tanzania', value: 100243 },
  { code: 'TL', title: 'Timor-Leste', value: 100245 },
  { code: 'TG', title: 'Togo', value: 100246 },
  { code: 'TK', title: 'Tokelau', value: 100247 },
  { code: 'TO', title: 'Tonga', value: 100248 },
  { code: 'TT', title: 'Trinidad and Tobago', value: 100249 },
  { code: 'TN', title: 'Tunisia', value: 100250 },
  { code: 'TR', title: 'Turkey', value: 100251 },
  { code: 'TM', title: 'Turkmenistan', value: 100252 },
  { code: 'TC', title: 'Turks and Caicos Islands', value: 100253 },
  { code: 'TV', title: 'Tuvalu', value: 100254 },
  { code: 'UG', title: 'Uganda', value: 100255 },
  { code: 'UA', title: 'Ukraine', value: 100256 },
  { code: 'AE', title: 'United Arab Emirates', value: 100257 },
  { code: 'GB', title: 'United Kingdom', value: 100258 },
  { code: 'US', title: 'USA' },
  { code: 'UM', title: 'United States Minor Outlying Islands', value: 100260 },
  { code: 'UY', title: 'Uruguay', value: 100261 },
  { code: 'UZ', title: 'Uzbekistan', value: 100262 },
  { code: 'VU', title: 'Vanuatu', value: 100263 },
  { code: 'VA', title: 'Vatican City', value: 100264 },
  { code: 'VE', title: 'Venezuela', value: 100265 },
  { code: 'VN', title: 'Vietnam', value: 100266 },
  { code: 'VI', title: 'U.S. Virgin Islands', value: 100268 },
  { code: 'WF', title: 'Wallis and Futuna', value: 100269 },
  { code: 'EH', title: 'Western Sahara', value: 100270 },
  { code: 'YE', title: 'Yemen', value: 100271 },
  { code: 'ZM', title: 'Zambia', value: 100272 },
  { code: 'ZW', title: 'Zimbabwe', value: 100273 },
  { code: 'AX', title: 'Åland Islands', value: 100274 }
];
