import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Card, CardContent, Typography, Accordion, AccordionSummary, AccordionDetails, Table, TableBody, TableCell, TableRow, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Autocomplete, MenuItem, Select, FormControl, InputLabel, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const StyledAccordion = styled(Accordion)({
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
  width: '100%'
});

const countries = [
  { code: 'HR', name: 'Croatia', value: 1, type: 'country' },
  { code: 'GR', name: 'Greece', value: 100116, type: 'country' },
  { code: 'TR', name: 'Turkey', value: 100181, type: 'country' },
  { code: 'IT', name: 'Italy', value: 100147, type: 'country' },
  { code: 'ES', name: 'Spain', value: 100119, type: 'country' },
  { code: 'NO', name: 'Norway', value: 100156, type: 'country' },
];

const getFlagUrl = (countryName) => {
  const country = countries.find((c) => c.name === countryName);
  return country ? `https://papasailor.com/w20/${country.code.toLowerCase()}.png` : null;
};

const Resume = ({ email }) => {
  const [voyages, setVoyages] = useState([]);
  const [userData, setUserData] = useState({});
  const [open, setOpen] = useState(false);
  const [currentVoyage, setCurrentVoyage] = useState(null);
  const [totalNm, setTotalNm] = useState(0);
  const [nmAsSkipper, setNmAsSkipper] = useState(0);
  const [isEditingProfile, setIsEditingProfile] = useState(false);

  useEffect(() => {
    fetchData();
  }, [email]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`/voyage/${email}`);
      const { user, voyages } = response.data;
      const sortedVoyages = voyages.sort((a, b) => new Date(b.yearMonth) - new Date(a.yearMonth));
      setUserData(user);
      setVoyages(sortedVoyages);
      calculateTotals(sortedVoyages);
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };

  const calculateTotals = (voyages) => {
    const totalNm = voyages.reduce((sum, voyage) => sum + (voyage.nm || 0), 0);
    const nmAsSkipper = voyages.reduce((sum, voyage) => sum + (voyage.skipper === 1 ? voyage.nm || 0 : 0), 0);
    setTotalNm(totalNm);
    setNmAsSkipper(nmAsSkipper);
  };

  const handleOpen = (voyage = null) => {
    setCurrentVoyage(voyage || {
      country: '',
      charter: '',
      yearMonth: dayjs(),
      skipper: 0,
      boatName: '',
      boatSize: '',
      area: '',
      comment: '',
      nm: ''
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentVoyage(null);
  };

  const handleSave = async () => {
    try {
      const saveData = {
        ...currentVoyage,
        email,
        skipper: currentVoyage.skipper === 1 ? 1 : 0,
        yearMonth: dayjs(currentVoyage.yearMonth).format('YYYY-MM-DD')
      };
      if (currentVoyage.id) {
        await axios.put(`/voyage/${currentVoyage.id}?email=${email}`, saveData);
      } else {
        await axios.post(`/voyage?email=${email}`, saveData);
      }
      fetchData();
      handleClose();
    } catch (error) {
      console.error('Error saving voyage', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/voyage/${id}?email=${email}`);
      fetchData();
    } catch (error) {
      console.error('Error deleting voyage', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name === 'boatSize') {
      newValue = value.replace(/\D/g, ''); // Remove non-digit characters
    }
    setCurrentVoyage({ ...currentVoyage, [name]: newValue });
  };

  const handleCountryChange = (event, value) => {
    setCurrentVoyage({ ...currentVoyage, country: value ? value.name : '' });
  };

  const handleSkipperChange = (event) => {
    setCurrentVoyage({ ...currentVoyage, skipper: event.target.value });
  };

  const handleExportPdf = async () => {
    try {
      const response = await axios.get(`/voyage/${email}/export-pdf?email=${email}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'sailingResume.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error exporting PDF', error);
    }
  };

  const handleProfileEdit = () => {
    setIsEditingProfile(true);
  };

  const handleProfileSave = async () => {
    try {
      await axios.post(`/resumeProfileDataEdit?email=${email}`, {
        meno: userData.meno,
        priezvisko: userData.priezvisko,
        licencia: userData.licencia,
      });
      setIsEditingProfile(false);
    } catch (error) {
      console.error('Error saving profile data', error);
    }
  };

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          Yachting Resume
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="center">
          {isEditingProfile ? (
            <>
              <TextField
                margin="dense"
                name="meno"
                label="Name"
                fullWidth
                value={userData.meno || ''}
                onChange={handleProfileChange}
              />
              <TextField
                margin="dense"
                name="priezvisko"
                label="Surname"
                fullWidth
                value={userData.priezvisko || ''}
                onChange={handleProfileChange}
              />
              <TextField
                margin="dense"
                name="licencia"
                label="License"
                fullWidth
                value={userData.licencia || ''}
                onChange={handleProfileChange}
              />
              <Button onClick={handleProfileSave} color="primary">
                Save Profile
              </Button>
            </>
          ) : (
            <>
              <Typography variant="body2" color="textSecondary">
                Name: {userData.meno || '_________________'}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Surname: {userData.priezvisko || '_______________'}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                License: {userData.licencia || '_______________'}
              </Typography>
              <Button onClick={handleProfileEdit} color="primary">
                Edit Profile
              </Button>
            </>
          )}
        </Box>
        <Typography variant="body2" color="textSecondary">
          Email: {email}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Nautical Miles: {totalNm}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Nm as skipper: {nmAsSkipper}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          No. of Trips: {voyages.length}
        </Typography>
        <Box display="flex" justifyContent="center" gap={2}>
          <Button 
            variant="outlined" 
            color="primary" 
            onClick={() => handleOpen()} 
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            Add Voyage
          </Button>
          <Button 
            variant="outlined" 
            color="primary" 
            onClick={handleExportPdf} 
            style={{ marginTop: '20px', marginBottom: '20px' }}
          >
            Export as PDF
          </Button>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" maxWidth={400} margin="auto">
          {voyages.map((voyage, index) => (
            <React.Fragment key={voyage.id}>
              <StyledAccordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box display="flex" alignItems="center" style={{ flex: 1 }}>
                    {(() => {
                      const flagUrl = getFlagUrl(voyage.country);
                      return flagUrl ? <img src={flagUrl} alt={voyage.country} style={{ marginRight: 8, width: 24, height: 16 }} /> : null;
                    })()}
                    <Typography>{voyage.boatName}</Typography>
                  </Box>
                  <Typography>{dayjs(voyage.yearMonth).format('YYYY-MM')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>Index</TableCell>
                        <TableCell>{index + 1}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Country</TableCell>
                        <TableCell>
                          {(() => {
                            const flagUrl = getFlagUrl(voyage.country);
                            return flagUrl ? <img src={flagUrl} alt={voyage.country} style={{ marginRight: 8, width: 24, height: 16 }} /> : null;
                          })()}
                          {voyage.country}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Charter</TableCell>
                        <TableCell>{voyage.charter}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>YearMonth</TableCell>
                        <TableCell>{dayjs(voyage.yearMonth).format('YYYY-MM')}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Skipper</TableCell>
                        <TableCell>{voyage.skipper === 1 ? 'Yes' : 'No'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Boat Name</TableCell>
                        <TableCell>{voyage.boatName}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Boat Size</TableCell>
                        <TableCell>{voyage.boatSize}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Area</TableCell>
                        <TableCell>{voyage.area}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Comment</TableCell>
                        <TableCell>{voyage.comment}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Nm</TableCell>
                        <TableCell>{voyage.nm}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Actions</TableCell>
                        <TableCell>
                          <Box display="flex" gap={2}>
                            <Button variant="outlined" onClick={() => handleOpen(voyage)}>Edit</Button>
                            <Button variant="outlined" onClick={() => handleDelete(voyage.id)}>Delete</Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </StyledAccordion>
              {index < voyages.length - 1 && <Divider style={{ width: '100%' }} />}
            </React.Fragment>
          ))}
        </Box>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{currentVoyage?.id ? 'Edit Voyage' : 'Add Voyage'}</DialogTitle>
          <DialogContent>
            <Autocomplete
              options={countries}
              getOptionLabel={(option) => option.name}
              value={countries.find((country) => country.name === currentVoyage?.country) || null}
              onChange={handleCountryChange}
              renderOption={(props, option) => (
                <li {...props}>
                  <img
                    src={`https://papasailor.com/w20/${option.code.toLowerCase()}.png`}
                    alt={option.name}
                    style={{ marginRight: 8, width: 24, height: 16 }}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        {params.InputProps.startAdornment}
                        {currentVoyage?.country && (
                          <img
                            src={getFlagUrl(currentVoyage.country)}
                            alt={currentVoyage.country}
                            style={{ marginRight: 8, width: 24, height: 16 }}
                          />
                        )}
                      </>
                    )
                  }}
                />
              )}
            />
            <TextField
              margin="dense"
              name="charter"
              label="Charter"
              fullWidth
              value={currentVoyage?.charter || ''}
              onChange={handleChange}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={['year', 'month']}
                label="YearMonth"
                value={dayjs(currentVoyage?.yearMonth) || null}
                onChange={(newValue) => {
                  setCurrentVoyage({ ...currentVoyage, yearMonth: newValue });
                }}
                renderInput={(params) => <TextField {...params} margin="dense" fullWidth />}
              />
            </LocalizationProvider>
            <FormControl margin="dense" fullWidth>
              <InputLabel>Skipper</InputLabel>
              <Select
                name="skipper"
                value={currentVoyage?.skipper}
                onChange={handleSkipperChange}
                label="Skipper"
              >
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              name="boatName"
              label="Boat Name"
              fullWidth
              value={currentVoyage?.boatName || ''}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="boatSize"
              label="Boat Size"
              fullWidth
              value={currentVoyage?.boatSize || ''}
              onChange={handleChange}
              inputProps={{ maxLength: 4 }} // Only allow up to 4 digits
            />
            <TextField
              margin="dense"
              name="nm"
              label="Nm"
              fullWidth
              value={currentVoyage?.nm || ''}
              onChange={handleChange}
            />
            <FormControl margin="dense" fullWidth>
              <InputLabel>Area</InputLabel>
              <Select
                name="area"
                value={currentVoyage?.area || ''}
                onChange={handleChange}
                label="Area"
              >
                <MenuItem value="A">A - Ocean</MenuItem>
                <MenuItem value="B">B - Up to 200nm</MenuItem>
                <MenuItem value="C">C - Coastal</MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin="dense"
              name="comment"
              label="Comment"
              fullWidth
              value={currentVoyage?.comment || ''}
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};

Resume.propTypes = {
  email: PropTypes.string.isRequired,
};

export default Resume;
