// translate.js
import axios from 'axios';

const translateText = async (text, targetLang) => {
  try {
    const response = await axios.post('/translate', {
      text: text,
      target_lang: targetLang
    });
    return response.data.translations[0].text;
  } catch (error) {
    console.error("Chyba pri preklade:", error);
    return ""; // Vrátiť prázdny reťazec alebo nejakú chybovú správu
  }
};

export default translateText;
