import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend) // Load translations using http backend
  .use(LanguageDetector) // Detect language from the browser
  .use(initReactI18next) // Initialize react-i18next
  .init({
    fallbackLng: 'en', // Use English if the detected language is not available
    debug: true,
    interpolation: {
      escapeValue: false, // Not needed for React
    },
  });

export default i18n;
