import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

//translate
import { useTranslation } from 'react-i18next';
import './i18n.js';




export function RangeHeads({ value, onChange, minHeads, maxHeads, minHeadsInit, maxHeadsInit }) {
  const { t } = useTranslation();
  const minHeadsFromData = minHeadsInit;
  const maxHeadsFromData = maxHeadsInit;




  const handleChange = (event, newValue) => {
    onChange(newValue); // Notify parent of the change



  };

  return (
    <Box id="cSlider">
      <Typography id="non-linear-slider" gutterBottom align="start">
        <p>{t("Heads")} </p>
      </Typography>

      <Slider
        getAriaLabel={() => 'Length range'}
        value={value}
        min={minHeadsFromData}
        max={maxHeadsFromData}
        step={1}
        onChange={handleChange}
        getAriaValueText={(val) => `${val} ft`}
        //valueLabelDisplay="auto"
        marks={false}
        size="small"
        color="info"
      />
      <small style={{ float: 'left', color: 'grey' }}> {value[0]}</small>
      <small style={{ float: 'right', color: 'grey' }}> {value[1]}</small>
    </Box>
  );
}
