import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useState } from 'react';

//translate
import { useTranslation } from 'react-i18next';
import './i18n.js';


export function CompanySelect({ selectedCompany, setSelectedCompany }) {
  const [inputValue, setInputValue] = useState("");
  const { t } = useTranslation();
  return (
    <Box id="cSlider">
      <Autocomplete

        multiple
        id="tags-outlined"
        options={company}
        getOptionLabel={(option) => option.title}
        value={selectedCompany}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        onChange={(event, newValue) => {
          setSelectedCompany(newValue);
          if (!newValue.length) {
            setInputValue("");
          }

        }}
        filterSelectedOptions
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            {option.title}
          </Box>

        )}

        renderInput={(params) => (
          <TextField {...params} label={t("Charter Company")} placeholder={t("Charter Company")} 
          sx={{
            "& .MuiInputBase-root": {
              backgroundColor: "white", // Set the background color of the input field
              '& fieldset': {
                borderColor: '#B2E0FA', // Default border color
              },
              '&:hover fieldset': {
                borderColor: '#D8D9DA', // Hover border color
              },
              '&.Mui-focused fieldset': {
                borderColor: '#D8D9DA', // Border color when the input is focused
              },
            },
          }} />
        )}

      />
    </Box>

  );

}


const company = [
  { title: 'Sail Club Croatia', value: '51', company_id:51, type: 'company' },
  { title: 'Nautički centar Pina i Mare - NCP', value: '151', company_id:151, type: 'company' },
  { title: 'Dalmatia Charter', value: '201', company_id:201, type: 'company' },
  { title: 'Sailing Europe Charter', value: '100551', company_id:100551, type: 'company' },
  { title: 'Way Point Rovinj - Pula/Marine Media', value: '100553', company_id:100553, type: 'company' },
  { title: 'Nautika Centar Nava', value: '100554', company_id:100554, type: 'company' },
  { title: 'Ultra Sailing', value: '100556', company_id:100556, type: 'company' },
  { title: 'Nautic Alliance', value: '100558', company_id:100558, type: 'company' },
  { title: 'Adria Sail Charter', value: '103601', company_id:103601, type: 'company' },
  { title: 'Aba Vela', value: '105313', company_id:105313, type: 'company' },
  { title: 'LM Yachting', value: '107061', company_id:107061, type: 'company' },
  { title: 'Manos Yachting', value: '107952', company_id:107952, type: 'company' },
  { title: 'Euronautic', value: '112021', company_id:112021, type: 'company' },
  { title: 'Noa Yachting - Nautic Alliance', value: '112747', company_id:112747, type: 'company' },
  { title: 'BavAdria', value: '113704', company_id:113704, type: 'company' },
  { title: 'BomiShip Yachting', value: '116788', company_id:116788, type: 'company' },
  { title: 'Azimuth Charter vela', value: '119153', company_id:119153, type: 'company' },
  { title: 'Vala Charter - Studio Tim', value: '120704', company_id:120704, type: 'company' },
  { title: 'Navigare Yachting', value: '122957', company_id:122957, type: 'company' },
  { title: 'Reful Yachting', value: '125289', company_id:125289, type: 'company' },
  { title: 'Yacht4You', value: '126152', company_id:126152, type: 'company' },
  { title: 'Angelina Yachtcharter', value: '126227', company_id:126227, type: 'company' },
  { title: 'Adria Service Yachting', value: '126228', company_id:126228, type: 'company' },
  { title: 'Olympic Yachting', value: '460402', company_id:460402, type: 'company' },
  { title: 'Astarea', value: '471247', company_id:471247, type: 'company' },
  { title: 'Croatia Yachting', value: '482136', company_id:482136, type: 'company' },
  { title: 'Istion Yachting', value: '482137', company_id:482137, type: 'company' },
  { title: 'Kavas Yachting', value: '482138', company_id:482138, type: 'company' },
  { title: 'Kekeris Yachts', value: '482139', company_id:482139, type: 'company' },
  { title: 'Asta Yachting', value: '482145', company_id:482145, type: 'company' },
  { title: 'Euromarine', value: '482147', company_id:482147, type: 'company' },
  { title: 'Jadranka Yachting', value: '482148', company_id:482148, type: 'company' },
  { title: 'Marina Hramina', value: '482149', company_id:482149, type: 'company' },
  { title: 'Torete', value: '482151', company_id:482151, type: 'company' },
  { title: 'Val Yachting', value: '482152', company_id:482152, type: 'company' },
  { title: 'Aladar Sail', value: '482157', company_id:482157, type: 'company' },
  { title: 'Europe Sail Yachting Charter', value: '482163', company_id:482163, type: 'company' },
  { title: 'Jonio Yachting', value: '482164', company_id:482164, type: 'company' },
  { title: 'Seafolk Travel', value: '482176', company_id:482176, type: 'company' },
  { title: 'BB Jezera', value: '482177', company_id:482177, type: 'company' },
  { title: 'First Class Sailing Spain', value: '482192', company_id:482192, type: 'company' },
  { title: 'Sailfleet Yat Isletmeciligi Lt.', value: '482230', company_id:482230, type: 'company' },
  { title: 'Vishe Radugi Yachting (VRS Sailing TLS)', value: '496433', company_id:496433, type: 'company' },
  { title: 'BBS Yachting', value: '515782', company_id:515782, type: 'company' },
  { title: 'Yelkenli Yachting', value: '516851', company_id:516851, type: 'company' },
  { title: 'Omegasail', value: '516896', company_id:516896, type: 'company' },
  { title: 'Tehnomont - Marina Veruda Charter', value: '516906', company_id:516906, type: 'company' },
  { title: 'BVI Yacht Charters', value: '521000', company_id:521000, type: 'company' },
  { title: 'Pelsys Charter', value: '521360', company_id:521360, type: 'company' },
  { title: 'Sunturist Charter', value: '521801', company_id:521801, type: 'company' },
  { title: 'Ban Tours', value: '521813', company_id:521813, type: 'company' },
  { title: 'WayPoint', value: '521871', company_id:521871, type: 'company' },
  { title: 'ProgettOceano', value: '522045', company_id:522045, type: 'company' },
  { title: 'Orvas Yachting', value: '522073', company_id:522073, type: 'company' },
  { title: 'Master Yachting ', value: '522222', company_id:522222, type: 'company' },
  { title: 'Inter Yachting', value: '522635', company_id:522635, type: 'company' },
  { title: 'Adriatic Sailing', value: '523117', company_id:523117, type: 'company' },
  { title: 'Octopus Sailing', value: '523254', company_id:523254, type: 'company' },
  { title: 'Suda Sailing', value: '523308', company_id:523308, type: 'company' },
  { title: 'DN Nautica', value: '558579', company_id:558579, type: 'company' },
  { title: 'Na pola puta / Sail-Charter-In-Croatia', value: '563060', company_id:563060, type: 'company' },
  { title: 'Multihull Yachting Greece', value: '565572', company_id:565572, type: 'company' },
  { title: 'Magna Yacht Charter', value: '572181', company_id:572181, type: 'company' },
  { title: 'Isalos Yachting', value: '576669', company_id:576669, type: 'company' },
  { title: 'Fancy Sailing', value: '577151', company_id:577151, type: 'company' },
  { title: 'Providenca Charter & Travel', value: '579119', company_id:579119, type: 'company' },
  { title: 'FreeWave', value: '600853', company_id:600853, type: 'company' },
  { title: 'Nova Eurospectra', value: '612801', company_id:612801, type: 'company' },
  { title: 'Albatros Yachting (Croatia)', value: '613389', company_id:613389, type: 'company' },
  { title: 'Toscanacharter di Maurizio Villani', value: '619249', company_id:619249, type: 'company' },
  { title: 'Baotić Yachting', value: '827028', company_id:827028, type: 'company' },
  { title: 'Bruneko Yacht Charter', value: '827034', company_id:827034, type: 'company' },
  { title: 'Four Seasons Yachting', value: '827770', company_id:827770, type: 'company' },
  { title: 'Sail Your Soul', value: '828270', company_id:828270, type: 'company' },
  { title: 'Odysseus Yachting Holidays', value: '832578', company_id:832578, type: 'company' },
  { title: 'HDM Sailing', value: '832591', company_id:832591, type: 'company' },
  { title: 'Yacht IN', value: '836376', company_id:836376, type: 'company' },
  { title: 'Miceli Vela', value: '837863', company_id:837863, type: 'company' },
  { title: 'CV Yachts', value: '840017', company_id:840017, type: 'company' },
  { title: 'DMS Sailing', value: '840024', company_id:840024, type: 'company' },
  { title: 'Brama Catamarans', value: '840026', company_id:840026, type: 'company' },
  { title: 'Vastardis Yachting', value: '840040', company_id:840040, type: 'company' },
  { title: 'Albatross Yachting (Greece)', value: '840714', company_id:840714, type: 'company' },
  { title: 'Tsirigotis Yacht Charter', value: '841739', company_id:841739, type: 'company' },
  { title: 'Sail in Greek waters', value: '842279', company_id:842279, type: 'company' },
  { title: 'First-Class-Sailing d.o.o.', value: '842499', company_id:842499, type: 'company' },
  { title: 'Hermes Yachting', value: '843226', company_id:843226, type: 'company' },
  { title: 'Blue Lagoon', value: '843599', company_id:843599, type: 'company' },
  { title: 'SP Travel', value: '844056', company_id:844056, type: 'company' },
  { title: 'Exadas Yachts', value: '844332', company_id:844332, type: 'company' },
  { title: 'Ionian Catamarans', value: '844377', company_id:844377, type: 'company' },
  { title: 'El Yachting', value: '845172', company_id:845172, type: 'company' },
  { title: 'Greek Isles Yachting ', value: '846057', company_id:846057, type: 'company' },
  { title: 'Morska lastavica', value: '849861', company_id:849861, type: 'company' },
  { title: 'Race&Cruise di Giorgio Zennaro', value: '850641', company_id:850641, type: 'company' },
  { title: 'MidAdria Yachting', value: '928289', company_id:928289, type: 'company' },
  { title: 'MG Yachts', value: '932995', company_id:932995, type: 'company' },
  { title: 'Prime Yachting', value: '934560', company_id:934560, type: 'company' },
  { title: 'MDM', value: '943278', company_id:943278, type: 'company' },
  { title: 'Dragut Sailing', value: '951451', company_id:951451, type: 'company' },
  { title: 'Perfect-Charter', value: '955966', company_id:955966, type: 'company' },
  { title: 'T.O. Mate', value: '972139', company_id:972139, type: 'company' },
  { title: 'Arctic Cruise In Norway As', value: '977183', company_id:977183, type: 'company' },
  { title: 'Aeolian Yachts', value: '982519', company_id:982519, type: 'company' },
  { title: 'Fyly Yachting and Travel', value: '988432', company_id:988432, type: 'company' },
  { title: 'Imbat Yacht', value: '993538', company_id:993538, type: 'company' },
  { title: 'Ortsa Sailing - DML', value: '1027246', company_id:1027246, type: 'company' },
  { title: 'Wind Rose & Sailing', value: '1078554', company_id:1078554, type: 'company' },
  { title: 'Denebola', value: '1080654', company_id:1080654, type: 'company' },
  { title: 'Smart Sail', value: '1090771', company_id:1090771, type: 'company' },
  { title: 'Bloem Yachts / GuletHoliday.com', value: '1093158', company_id:1093158, type: 'company' },
  { title: 'Charter Novak', value: '1093578', company_id:1093578, type: 'company' },
  { title: 'Seraphilus Marine Charter', value: '1160354', company_id:1160354, type: 'company' },
  { title: 'Cro-mar', value: '1168398', company_id:1168398, type: 'company' },
  { title: 'Solar Yachting', value: '1175761', company_id:1175761, type: 'company' },
  { title: 'Tzennet Yachting', value: '1187171', company_id:1187171, type: 'company' },
  { title: 'Anemos Yachting', value: '1210688', company_id:1210688, type: 'company' },
  { title: 'White Sails (Riginos Yachts)', value: '1224029', company_id:1224029, type: 'company' },
  { title: 'Dubrovnik Yachting', value: '1229313', company_id:1229313, type: 'company' },
  { title: 'Adria Pol Yachting', value: '1241190', company_id:1241190, type: 'company' },
  { title: 'Sail Fast Experience ', value: '1243425', company_id:1243425, type: 'company' },
  { title: 'Q-Yacht', value: '1245757', company_id:1245757, type: 'company' },
  { title: 'Sailorsbreeze', value: '1259671', company_id:1259671, type: 'company' },
  { title: 'Axion Yachts', value: '1260364', company_id:1260364, type: 'company' },
  { title: 'Blue Nautica', value: '1263531', company_id:1263531, type: 'company' },
  { title: 'Mentis Yachting', value: '1267290', company_id:1267290, type: 'company' },
  { title: 'Lagoon NEPA', value: '1267346', company_id:1267346, type: 'company' },
  { title: 'Pelagos Yacht', value: '1289790', company_id:1289790, type: 'company' },
  { title: 'AMS Yachting', value: '1294927', company_id:1294927, type: 'company' },
  { title: 'Adria Yacht Center', value: '1295219', company_id:1295219, type: 'company' },
  { title: 'ABB Charter', value: '1296399', company_id:1296399, type: 'company' },
  { title: 'Paralos', value: '1298899', company_id:1298899, type: 'company' },
  { title: 'Discovery Yachting', value: '1301081', company_id:1301081, type: 'company' },
  { title: 'Helios Stratos', value: '1308006', company_id:1308006, type: 'company' },
  { title: 'Blue Emotion', value: '1308123', company_id:1308123, type: 'company' },
  { title: 'Blue Sails', value: '1308128', company_id:1308128, type: 'company' },
  { title: 'Galazio Sailing', value: '1308151', company_id:1308151, type: 'company' },
  { title: 'Apollo Yachts', value: '1314049', company_id:1314049, type: 'company' },
  { title: 'Under The Heavens', value: '1317684', company_id:1317684, type: 'company' },
  { title: 'Ioniandreamcharter', value: '1370222', company_id:1370222, type: 'company' },
  { title: 'Chrom-Nautica', value: '1401896', company_id:1401896, type: 'company' },
  { title: 'Marea Yachting', value: '1402723', company_id:1402723, type: 'company' },
  { title: 'Jedra Turopolja', value: '1508922', company_id:1508922, type: 'company' },
  { title: 'ND Sails', value: '1519234', company_id:1519234, type: 'company' },
  { title: 'NAS Sailing Center', value: '1519781', company_id:1519781, type: 'company' },
  { title: 'triM Nautica Charter', value: '1531999', company_id:1531999, type: 'company' },
  { title: 'EGO Charter', value: '2174309', company_id:2174309, type: 'company' },
  { title: 'ALL-Seas', value: '2186841', company_id:2186841, type: 'company' },
  { title: 'Adria Turn Yachtcharter', value: '2248038', company_id:2248038, type: 'company' },
  { title: 'SeaStar Sailing', value: '2248942', company_id:2248942, type: 'company' },
  { title: 'Smolčić Yachting', value: '2252345', company_id:2252345, type: 'company' },
  { title: 'Feel Yachting', value: '2285506', company_id:2285506, type: 'company' },
  { title: 'Sail The Ionian Sea', value: '2430414', company_id:2430414, type: 'company' },
  { title: 'Sailmoments Charter', value: '2832817', company_id:2832817, type: 'company' },
  { title: 'Argola Charter', value: '2840796', company_id:2840796, type: 'company' },
  { title: 'Sea Novels', value: '2844247', company_id:2844247, type: 'company' },
  { title: 'Adria Yacht Design', value: '2854106', company_id:2854106, type: 'company' },
  { title: 'AF Yachting', value: '2901340', company_id:2901340, type: 'company' },
  { title: 'Aegean Maritime', value: '2905894', company_id:2905894, type: 'company' },
  { title: 'Octopus Yachting', value: '3675649', company_id:3675649, type: 'company' },
  { title: 'IRIS Yachtcharter', value: '4652935', company_id:4652935, type: 'company' },
  { title: 'Minas-Yachting ', value: '4775151', company_id:4775151, type: 'company' },
  { title: 'Matrix Yachting', value: '4952450', company_id:4952450, type: 'company' },
  { title: 'Mareta boats', value: '5014565', company_id:5014565, type: 'company' },
  { title: 'Aegean Wind', value: '5028337', company_id:5028337, type: 'company' },
  { title: 'LoveSail', value: '5094401', company_id:5094401, type: 'company' },
  { title: 'Stavento Sailing Greece', value: '5319301', company_id:5319301, type: 'company' },
  { title: 'Eversails', value: '5545908', company_id:5545908, type: 'company' },
  { title: 'Exclusive Vip Catamarans', value: '6030655', company_id:6030655, type: 'company' },
  { title: 'Ocean Sailing House', value: '6093703', company_id:6093703, type: 'company' },
  { title: 'Mets Yachting', value: '6167933', company_id:6167933, type: 'company' },
  { title: 'Irmak Yachting', value: '6194129', company_id:6194129, type: 'company' },
  { title: 'Lumar', value: '6429012', company_id:6429012, type: 'company' },
  { title: 'Sail Emerald', value: '6551289', company_id:6551289, type: 'company' },
  { title: 'Mystique Yachting', value: '6770570', company_id:6770570, type: 'company' },
  { title: 'Panouseris Yacht Charters', value: '6957996', company_id:6957996, type: 'company' },
  { title: 'George Vlamis Yachts & Yacht Management', value: '7126839', company_id:7126839, type: 'company' },
  { title: 'Black Pearl Charter', value: '7286292', company_id:7286292, type: 'company' },
  { title: 'JF Yachts', value: '7332436', company_id:7332436, type: 'company' },
  { title: 'WindSailing', value: '7602272', company_id:7602272, type: 'company' },
  { title: 'Aquatoria Yachting', value: '7610580', company_id:7610580, type: 'company' },
  { title: 'Commodore Yachting', value: '7650022', company_id:7650022, type: 'company' },
  { title: 'YAKO Sailing ', value: '7657002', company_id:7657002, type: 'company' },
  { title: 'Thestion Yachting', value: '8037401', company_id:8037401, type: 'company' },
  { title: 'Prima Yachting', value: '8621469', company_id:8621469, type: 'company' },
  { title: 'EuroYacht', value: '8682001', company_id:8682001, type: 'company' },
  { title: 'Flamingo Yacht Charters', value: '8801541', company_id:8801541, type: 'company' },
  { title: 'Thassos Private cruises', value: '8916899', company_id:8916899, type: 'company' },
  { title: 'Diaplous Yachting', value: '8926582', company_id:8926582, type: 'company' },
  { title: 'Sailman Yachting', value: '8938834', company_id:8938834, type: 'company' },
  { title: 'Eol Yachting', value: '9036992', company_id:9036992, type: 'company' },
  { title: 'SAYSAIL', value: '9038364', company_id:9038364, type: 'company' },
  { title: 'CG-VIllas & Yachting', value: '9048882', company_id:9048882, type: 'company' },
  { title: 'Katopodis Yachting', value: '9290852', company_id:9290852, type: 'company' },
  { title: 'Catamaran Cruises', value: '9540077', company_id:9540077, type: 'company' },
  { title: 'Laguna Yachting', value: '9561345', company_id:9561345, type: 'company' },
  { title: 'Faliro Sailing', value: '9722497', company_id:9722497, type: 'company' },
  { title: 'Cyclades Sailing', value: '9777216', company_id:9777216, type: 'company' },
  { title: 'Peppersail', value: '9795528', company_id:9795528, type: 'company' },
  { title: 'Zoom Boats', value: '9823194', company_id:9823194, type: 'company' },
  { title: 'Proversa Charter', value: '9836608', company_id:9836608, type: 'company' },
  { title: 'Dream Yacht Charter', value: '9870890', company_id:9870890, type: 'company' },
  { title: 'Despina', value: '9926550', company_id:9926550, type: 'company' },
  { title: 'Ancyra Sailing', value: '9936167', company_id:9936167, type: 'company' },
  { title: 'Christianna Yachting', value: '9963157', company_id:9963157, type: 'company' },
  { title: 'Yachtcharter Rhodes', value: '10062402', company_id:10062402, type: 'company' },
  { title: 'Y2C', value: '10090769', company_id:10090769, type: 'company' },
  { title: 'Sailing Ionian Sea', value: '10093326', company_id:10093326, type: 'company' },
  { title: 'Summer Sail', value: '10098648', company_id:10098648, type: 'company' },
  { title: 'X-Yachting Sailing Center', value: '10101066', company_id:10101066, type: 'company' },
  { title: 'Chilla Sailing', value: '10124407', company_id:10124407, type: 'company' },
  { title: 'Lycian Sail', value: '10225332', company_id:10225332, type: 'company' },
  { title: 'Hevema P.C.', value: '10364894', company_id:10364894, type: 'company' },
  { title: 'Van Dyk Investments', value: '10429907', company_id:10429907, type: 'company' },
  { title: 'Luxury Sailing', value: '10445465', company_id:10445465, type: 'company' },
  { title: 'Green Wave Yacht Charter', value: '10540671', company_id:10540671, type: 'company' },
  { title: 'Thalassa Sailing Travels', value: '10567828', company_id:10567828, type: 'company' },
  { title: 'Eazyblue', value: '10579869', company_id:10579869, type: 'company' },
  { title: 'Bodrumtour', value: '10598867', company_id:10598867, type: 'company' },
  { title: 'Chalkidiki Yachts', value: '10624586', company_id:10624586, type: 'company' },
  { title: 'Mediterra Yachts', value: '10961311', company_id:10961311, type: 'company' },
  { title: 'Sail in Kas', value: '10962284', company_id:10962284, type: 'company' },
  { title: 'Kornati Yachting', value: '11075778', company_id:11075778, type: 'company' },
  { title: 'Adriatic Wave Charter', value: '11080362', company_id:11080362, type: 'company' },
  { title: 'Timeless yacht charter', value: '11085535', company_id:11085535, type: 'company' },
  { title: 'Virada', value: '11099136', company_id:11099136, type: 'company' },
  { title: 'White Blue Seas', value: '11254470', company_id:11254470, type: 'company' },
  { title: 'Asmira Marine Yacht Charter', value: '11386066', company_id:11386066, type: 'company' },
  { title: 'Sailing Sweden', value: '11469104', company_id:11469104, type: 'company' },
  { title: 'Pearl1 Living and Yachting Hospitality', value: '11493871', company_id:11493871, type: 'company' },
  { title: 'Sailing Rhapsody', value: '11513081', company_id:11513081, type: 'company' },
  { title: 'Calypso rent a boat', value: '11519222', company_id:11519222, type: 'company' },
  { title: 'Sea Experience Sardinia', value: '11623493', company_id:11623493, type: 'company' },
  { title: 'Most Sailing', value: '11696592', company_id:11696592, type: 'company' },
  { title: 'Demor Yachts', value: '11734221', company_id:11734221, type: 'company' },
  { title: 'The Sailing Experience', value: '11972862', company_id:11972862, type: 'company' },
  { title: 'Vernicos Yachts', value: '11992328', company_id:11992328, type: 'company' },
  { title: 'Seaboater', value: '12069483', company_id:12069483, type: 'company' },
  { title: 'MJ Charter', value: '12075727', company_id:12075727, type: 'company' },
  { title: 'Charter Miri', value: '12529904', company_id:12529904, type: 'company' },
  { title: 'Heads Yachting', value: '12726062', company_id:12726062, type: 'company' },
  { title: 'Bosfor Charter', value: '12795438', company_id:12795438, type: 'company' },
  { title: 'A-Yachts', value: '12798234', company_id:12798234, type: 'company' },
  { title: 'Aiolos Sailing', value: '12836651', company_id:12836651, type: 'company' },
  { title: 'TYC Serenity', value: '12856871', company_id:12856871, type: 'company' },
  { title: 'Otium Yachts', value: '12870715', company_id:12870715, type: 'company' },
  { title: 'World Yachting Charter', value: '13022476', company_id:13022476, type: 'company' },
  { title: 'Croatia Cruises', value: '13040071', company_id:13040071, type: 'company' },
  { title: 'MK Yacht Explorer', value: '13084466', company_id:13084466, type: 'company' },
  { title: 'Sailing club Vranjic', value: '13125414', company_id:13125414, type: 'company' },
  { title: 'Apeiron Yachting', value: '13131005', company_id:13131005, type: 'company' },
  { title: 'book2sailing', value: '13154510', company_id:13154510, type: 'company' },
  { title: 'EMU Yachting', value: '13160776', company_id:13160776, type: 'company' },
  { title: 'Greek Sailing Holidays', value: '13410612', company_id:13410612, type: 'company' },
  { title: 'Dreamland Sailors', value: '13455272', company_id:13455272, type: 'company' },
  { title: 'Euroholiday', value: '13476916', company_id:13476916, type: 'company' },
  { title: 'Plan your journey', value: '13488763', company_id:13488763, type: 'company' },
  { title: 'AA Yachts', value: '13489114', company_id:13489114, type: 'company' },
  { title: 'Franjković', value: '13495601', company_id:13495601, type: 'company' },
  { title: 'Gregale Yachts', value: '13500920', company_id:13500920, type: 'company' },
  { title: 'Nonno Blue', value: '13533143', company_id:13533143, type: 'company' },
  { title: 'Mareval Charter', value: '13533148', company_id:13533148, type: 'company' },
  { title: 'KeK Yachting', value: '13566223', company_id:13566223, type: 'company' },
  { title: 'Istra Charter Trget', value: '13566225', company_id:13566225, type: 'company' },
  { title: 'Sailway', value: '13569288', company_id:13569288, type: 'company' },
  { title: 'Palmyra Yachting', value: '13614014', company_id:13614014, type: 'company' },
  { title: 'Touch Adriatic', value: '13720246', company_id:13720246, type: 'company' },
  { title: 'Luna Trips', value: '13740447', company_id:13740447, type: 'company' },
  { title: 'Cool Change', value: '13955193', company_id:13955193, type: 'company' },
  { title: 'E-Sailing', value: '14268954', company_id:14268954, type: 'company' },
  { title: 'Lebić Yachts', value: '15130280', company_id:15130280, type: 'company' },
  { title: 'Sail2Day', value: '17363870', company_id:17363870, type: 'company' },
  { title: '7 Seas Maritime', value: '17929463', company_id:17929463, type: 'company' },
  { title: 'San Blas Sailing', value: '20262541', company_id:20262541, type: 'company' },
  { title: 'Dream-Sailing', value: '20447479', company_id:20447479, type: 'company' },
  { title: 'Adriatic Boat Charter', value: '20910440', company_id:20910440, type: 'company' },
  { title: 'Mediterranean Catamarans Yachting', value: '20940321', company_id:20940321, type: 'company' },
  { title: 'Montona Vision 69', value: '20976875', company_id:20976875, type: 'company' },
  { title: 'Sailera Yacht Charter', value: '21210976', company_id:21210976, type: 'company' },
  { title: 'Pula-Yachting', value: '22153457', company_id:22153457, type: 'company' },
  { title: 'ORYX Yachting', value: '22550064', company_id:22550064, type: 'company' },
  { title: 'Lux Mediterranean Experience', value: '23069207', company_id:23069207, type: 'company' },
  { title: 'Sea Land Yacht Charter', value: '23074896', company_id:23074896, type: 'company' },
  { title: 'Almira Yachting', value: '23082473', company_id:23082473, type: 'company' },
  { title: 'Priceless Yachting', value: '23087966', company_id:23087966, type: 'company' },
  { title: 'Athenian Yachts', value: '23362337', company_id:23362337, type: 'company' },
  { title: 'Tailwind Yachting', value: '23405252', company_id:23405252, type: 'company' },
  { title: 'Braves sailors', value: '23840120', company_id:23840120, type: 'company' },
  { title: '123yachtcharter', value: '23930295', company_id:23930295, type: 'company' },
  { title: 'Joy Yachting', value: '23930296', company_id:23930296, type: 'company' },
  { title: 'ACE Yachting', value: '23938913', company_id:23938913, type: 'company' },
  { title: 'CroSail', value: '23949419', company_id:23949419, type: 'company' },
  { title: 'Kiriacoulis', value: '23970693', company_id:23970693, type: 'company' },
  { title: 'IntersailClub', value: '24055773', company_id:24055773, type: 'company' },
  { title: 'SailMax Sailing', value: '24177165', company_id:24177165, type: 'company' },
  { title: 'Nimbus Charter', value: '24432525', company_id:24432525, type: 'company' },
  { title: 'Oxeed Yachting', value: '24432531', company_id:24432531, type: 'company' },
  { title: 'Matrix Sailing Turizm', value: '24438401', company_id:24438401, type: 'company' },
  { title: 'Codezero sailing Easy Luxury Charter Team', value: '24442971', company_id:24442971, type: 'company' },
  { title: 'One Yacht Charter', value: '24464578', company_id:24464578, type: 'company' },
  { title: 'Helios Sails', value: '24468118', company_id:24468118, type: 'company' },
  { title: 'JustSail', value: '24661714', company_id:24661714, type: 'company' },
  { title: 'Book The Boat', value: '24925372', company_id:24925372, type: 'company' },
  { title: 'WestSails', value: '25126216', company_id:25126216, type: 'company' },
  { title: 'Pyxis Nautica', value: '25139655', company_id:25139655, type: 'company' },
  { title: 'Miber Sailing', value: '25247104', company_id:25247104, type: 'company' },
  { title: 'Ocean Sailing', value: '25248058', company_id:25248058, type: 'company' },
  { title: 'Calipso', value: '25330177', company_id:25330177, type: 'company' },
  { title: 'Pure Sailing', value: '25355042', company_id:25355042, type: 'company' },
  { title: 'Sail On Yachting', value: '25391395', company_id:25391395, type: 'company' },
  { title: 'Cordelia Yachting', value: '25462659', company_id:25462659, type: 'company' },
  { title: 'Orion Blue Cruise', value: '25542197', company_id:25542197, type: 'company' },
  { title: 'Charter Trogir', value: '25697584', company_id:25697584, type: 'company' },
  { title: 'Corse Voile Location', value: '25728480', company_id:25728480, type: 'company' },
  { title: 'Adriatica Transfer', value: '26260971', company_id:26260971, type: 'company' },
  { title: 'Mitis Sailing', value: '26732505', company_id:26732505, type: 'company' },
  { title: 'SoFun Yachts & Charter', value: '26809275', company_id:26809275, type: 'company' },
  { title: 'Mango Sailing', value: '26820762', company_id:26820762, type: 'company' },
  { title: 'Storm Charters', value: '26991212', company_id:26991212, type: 'company' },
  { title: 'Med Sailing Holidays', value: '27003135', company_id:27003135, type: 'company' },
  { title: 'DM Sailing', value: '27206645', company_id:27206645, type: 'company' },
  { title: 'Jolly Roger Sailing', value: '27929226', company_id:27929226, type: 'company' },
  { title: 'Cavo Yachting', value: '28375619', company_id:28375619, type: 'company' },
  { title: 'My Yachtweek', value: '28466326', company_id:28466326, type: 'company' },
  { title: 'Akhir Cruise', value: '28608830', company_id:28608830, type: 'company' },
  { title: 'Uniko Yacht Club', value: '28805578', company_id:28805578, type: 'company' },
  { title: 'Latria Charter', value: '29006504', company_id:29006504, type: 'company' },
  { title: 'MBCAT', value: '29113337', company_id:29113337, type: 'company' },
  { title: 'Royal Dalmatia Yachting', value: '29145655', company_id:29145655, type: 'company' },
  { title: 'Black&Soul', value: '29147559', company_id:29147559, type: 'company' },
  { title: 'CST Charters', value: '29209661', company_id:29209661, type: 'company' },
  { title: 'Waypoints Yacht Charters ', value: '29218216', company_id:29218216, type: 'company' },
  { title: 'Adriatic Sailing Academy', value: '29218301', company_id:29218301, type: 'company' },
  { title: 'Croatia Tours Travel Agency ', value: '29478091', company_id:29478091, type: 'company' },
  { title: 'Ghimar', value: '29595252', company_id:29595252, type: 'company' },
  { title: 'Wind Seychelles', value: '29899896', company_id:29899896, type: 'company' },
  { title: 'My Sailing Yachting', value: '29977079', company_id:29977079, type: 'company' },
  { title: 'Farmayachting', value: '30081447', company_id:30081447, type: 'company' },
  { title: 'Discover Sailing Asia', value: '30189024', company_id:30189024, type: 'company' },
  { title: 'Anassa Sailing', value: '30385654', company_id:30385654, type: 'company' },
  { title: 'Alisa Adriatic Cruise', value: '30397812', company_id:30397812, type: 'company' },
  { title: 'More Yacht Club', value: '30405072', company_id:30405072, type: 'company' },
  { title: 'Rhodes-Yachting', value: '30528262', company_id:30528262, type: 'company' },
  { title: 'Nüve Turizm', value: '30662892', company_id:30662892, type: 'company' },
  { title: 'Anasa Experiences', value: '30773407', company_id:30773407, type: 'company' },
  { title: 'Sailoe', value: '30797979', company_id:30797979, type: 'company' },
  { title: 'Holiday Charter', value: '31322501', company_id:31322501, type: 'company' },
  { title: 'Rhino', value: '31771223', company_id:31771223, type: 'company' },
  { title: 'Sirocco Turizm', value: '31787428', company_id:31787428, type: 'company' },
  { title: 'Preveza Sailing', value: '31824121', company_id:31824121, type: 'company' },
  { title: 'Ediba Sailing', value: '31834864', company_id:31834864, type: 'company' },
  { title: 'MGV Yachts Cruises', value: '31841299', company_id:31841299, type: 'company' },
  { title: 'Midi Nautisme', value: '32109499', company_id:32109499, type: 'company' },
  { title: 'Simpson Yacht Charter', value: '32109500', company_id:32109500, type: 'company' },
  { title: 'Sail Azur', value: '32126127', company_id:32126127, type: 'company' },
  { title: 'Omnia', value: '32262858', company_id:32262858, type: 'company' },
  { title: 'Catsail Catamarans', value: '32268805', company_id:32268805, type: 'company' },
  { title: 'VízenJó', value: '32469824', company_id:32469824, type: 'company' },
  { title: 'Armano Nautika', value: '32562924', company_id:32562924, type: 'company' },
  { title: 'Paper Boat Sailing', value: '32563262', company_id:32563262, type: 'company' },
  { title: 'Anassa', value: '32584597', company_id:32584597, type: 'company' },
  { title: 'Grenadines Sailing Escapade', value: '32584903', company_id:32584903, type: 'company' },
  { title: 'Yachts Society ', value: '32585104', company_id:32585104, type: 'company' },
  { title: 'Sail the Cyclades', value: '32633951', company_id:32633951, type: 'company' },
  { title: 'On y va Yachting', value: '32641472', company_id:32641472, type: 'company' },
  { title: 'Fancy Lobster', value: '32668675', company_id:32668675, type: 'company' },
  { title: 'Yachting in Sardinia', value: '32683617', company_id:32683617, type: 'company' },
  { title: 'Cruising Charter Sardinia', value: '32814016', company_id:32814016, type: 'company' },
  { title: 'Zen Yacht Charter', value: '32905540', company_id:32905540, type: 'company' },
  { title: 'KATGECKO Katamaran Charter', value: '32907099', company_id:32907099, type: 'company' },
  { title: 'StarSail', value: '32921868', company_id:32921868, type: 'company' },
  { title: 'White Sail', value: '33142671', company_id:33142671, type: 'company' },
  { title: 'Anyachting', value: '33161954', company_id:33161954, type: 'company' },
  { title: 'Canal Boats Telemark', value: '33172029', company_id:33172029, type: 'company' },
  { title: 'MS Novi Dan', value: '33172030', company_id:33172030, type: 'company' },
  { title: 'Sailing Spirit', value: '33360317', company_id:33360317, type: 'company' },
  { title: 'Dream Charter Seychelles ', value: '33378926', company_id:33378926, type: 'company' },
  { title: 'Hellenic Fantasea Sailing', value: '33386951', company_id:33386951, type: 'company' },
  { title: 'Happy Sail', value: '33438925', company_id:33438925, type: 'company' },
  { title: 'FY - Fortunal Yachting', value: '33449118', company_id:33449118, type: 'company' },
  { title: 'Puresailing Yachting', value: '33510251', company_id:33510251, type: 'company' },
  { title: 'Aenaon', value: '33510277', company_id:33510277, type: 'company' },
  { title: 'UPWIND CATAMARAN', value: '33753872', company_id:33753872, type: 'company' },
  { title: 'Aegeotissa Yachts', value: '33865057', company_id:33865057, type: 'company' },
  { title: 'Anima Maris', value: '33969797', company_id:33969797, type: 'company' },
  { title: 'Adriatic Blue Dream', value: '33970172', company_id:33970172, type: 'company' },
  { title: 'Saronikos Sailing', value: '33970832', company_id:33970832, type: 'company' },
  { title: 'Derya Yachting', value: '33992991', company_id:33992991, type: 'company' },
  { title: 'Danielis Yachting', value: '33993120', company_id:33993120, type: 'company' },
  { title: 'Beauvoir Yacht Charter', value: '33993122', company_id:33993122, type: 'company' },
  { title: 'Ionian Charter', value: '34003611', company_id:34003611, type: 'company' },
  { title: 'Alida Yacht', value: '34157145', company_id:34157145, type: 'company' },
  { title: 'Five Star Sea', value: '34248838', company_id:34248838, type: 'company' },
  { title: 'Yacht Lagaro', value: '34305233', company_id:34305233, type: 'company' },
  { title: 'rudder&moor', value: '34428810', company_id:34428810, type: 'company' },
  { title: 'Nordvaer', value: '34432392', company_id:34432392, type: 'company' },
  { title: 'La Luna Libra Star', value: '34432505', company_id:34432505, type: 'company' },
  { title: 'STA Marine', value: '34525106', company_id:34525106, type: 'company' },
  { title: 'Marina Yacht Charter', value: '34534381', company_id:34534381, type: 'company' },
  { title: 'Group Island Spirit Charters', value: '34595651', company_id:34595651, type: 'company' },
  { title: 'breezeYachting.swiss', value: '34799154', company_id:34799154, type: 'company' },
  { title: 'Aloha Sailing', value: '34836239', company_id:34836239, type: 'company' },
  { title: 'Avdiros Sailing', value: '34885654', company_id:34885654, type: 'company' },
  { title: 'Turistico yachting', value: '34933719', company_id:34933719, type: 'company' },
  { title: 'Aurelia Yachting', value: '35003137', company_id:35003137, type: 'company' },
  { title: 'Perla Catamaran', value: '35208904', company_id:35208904, type: 'company' },
  { title: 'Tempo Sailing', value: '35219759', company_id:35219759, type: 'company' },
  { title: 'Green Breeze', value: '35219762', company_id:35219762, type: 'company' },
  { title: 'Blue Magic Yachts', value: '35245666', company_id:35245666, type: 'company' },
  { title: 'Legend Of Sail', value: '35293881', company_id:35293881, type: 'company' },
  { title: 'Top Light', value: '35320565', company_id:35320565, type: 'company' },
  { title: 'Bondia Yachting', value: '35497903', company_id:35497903, type: 'company' },
  { title: 'Big Blue Sailing Events', value: '35514805', company_id:35514805, type: 'company' },
  { title: 'Blue Med Charter', value: '35547252', company_id:35547252, type: 'company' },
  { title: 'Ekvator Yachting', value: '35547529', company_id:35547529, type: 'company' },
  { title: 'Goolets', value: '35624330', company_id:35624330, type: 'company' },
  { title: 'Top Catamaran', value: '35745389', company_id:35745389, type: 'company' },
  { title: 'Cat&Go', value: '35766709', company_id:35766709, type: 'company' },
  { title: 'Pura Vida Yacht Charter', value: '35872754', company_id:35872754, type: 'company' },
  { title: 'Viya Sailing', value: '35945457', company_id:35945457, type: 'company' },
  { title: 'Victorysail', value: '36118766', company_id:36118766, type: 'company' },
  { title: 'Mirakul', value: '36119508', company_id:36119508, type: 'company' },
  { title: 'Venus Yachting', value: '36131350', company_id:36131350, type: 'company' },
  { title: 'Oceanis Yachting', value: '36195265', company_id:36195265, type: 'company' },
  { title: 'Ocean Five', value: '36208943', company_id:36208943, type: 'company' },
  { title: 'Galatzo Charter Mallorca', value: '36299613', company_id:36299613, type: 'company' },
  { title: 'Dubrovnik Boat and Yacht', value: '36326018', company_id:36326018, type: 'company' },
  { title: 'Wind Fearless', value: '36504439', company_id:36504439, type: 'company' },
  { title: 'Serafina', value: '36505575', company_id:36505575, type: 'company' },
  { title: 'Fiji', value: '36560221', company_id:36560221, type: 'company' },
  { title: 'Maldives Charters', value: '36809626', company_id:36809626, type: 'company' },
  { title: 'Pearls of Seychelles', value: '36809642', company_id:36809642, type: 'company' },
  { title: 'Phocea Yachting', value: '36809643', company_id:36809643, type: 'company' },
  { title: 'Roses Sailing', value: '37008365', company_id:37008365, type: 'company' },
  { title: 'Seagma Yachting', value: '37040938', company_id:37040938, type: 'company' },
  { title: 'Lime Charter', value: '37111457', company_id:37111457, type: 'company' },
  { title: 'Max Yacht', value: '37126351', company_id:37126351, type: 'company' },
  { title: 'Ionian Spirit', value: '37135185', company_id:37135185, type: 'company' },
  { title: 'Valentino Yachts', value: '37259644', company_id:37259644, type: 'company' },
  { title: 'A&Ž', value: '37277405', company_id:37277405, type: 'company' },
  { title: 'Boatsters Black', value: '37449901', company_id:37449901, type: 'company' },
  { title: 'Sightsea Yachting', value: '37509752', company_id:37509752, type: 'company' },
  { title: 'In2theBlue Charter', value: '37509770', company_id:37509770, type: 'company' },
  { title: 'Cata Sailing ', value: '37510252', company_id:37510252, type: 'company' },
  { title: 'Ionische Yacht Charter', value: '37519017', company_id:37519017, type: 'company' },
  { title: 'Novelty Tours', value: '37960788', company_id:37960788, type: 'company' },
  { title: 'Bayr Yachting', value: '38313672', company_id:38313672, type: 'company' },
  { title: 'Family Luxury Yachting', value: '38334415', company_id:38334415, type: 'company' },
  { title: 'YM Charter & Management', value: '38570246', company_id:38570246, type: 'company' },
  { title: 'Sea and More Yachting', value: '38625820', company_id:38625820, type: 'company' },
  { title: 'Signature Sailing Charter', value: '38867994', company_id:38867994, type: 'company' },
  { title: 'Odyssea Yachting', value: '38868000', company_id:38868000, type: 'company' },
  { title: 'Catherine Charters', value: '38948312', company_id:38948312, type: 'company' },
  { title: 'Blue Titan', value: '39034285', company_id:39034285, type: 'company' },
  { title: 'Papoulakis Yachting', value: '39192157', company_id:39192157, type: 'company' },
  { title: 'Sailines', value: '39192184', company_id:39192184, type: 'company' },
  { title: 'Ionian Challenge', value: '39274811', company_id:39274811, type: 'company' },
  { title: 'Newera Charter', value: '39337019', company_id:39337019, type: 'company' },
  { title: 'GBT Yachting', value: '39383398', company_id:39383398, type: 'company' },
  { title: 'Adda Yachting', value: '39596121', company_id:39596121, type: 'company' },
  { title: 'One Step Yachting', value: '39823188', company_id:39823188, type: 'company' },
  { title: 'Ecosail', value: '39941183', company_id:39941183, type: 'company' },
  { title: 'Patronis Sailing', value: '39951928', company_id:39951928, type: 'company' },
  { title: 'Sail in Greece', value: '40016139', company_id:40016139, type: 'company' },
  { title: 'Duende Yachts', value: '40039490', company_id:40039490, type: 'company' },
  { title: 'Niriides Cruises', value: '40051158', company_id:40051158, type: 'company' },
  { title: 'Nautika Dalmata', value: '40155923', company_id:40155923, type: 'company' },
  { title: 'Albatros Yachting (Turkey)', value: '40178416', company_id:40178416, type: 'company' },
  { title: 'Private Yacht Charter', value: '40241296', company_id:40241296, type: 'company' },
  { title: 'Ida Sailing', value: '40260545', company_id:40260545, type: 'company' },
  { title: 'Villa Jahta', value: '40284440', company_id:40284440, type: 'company' },
  { title: 'Greek Yachts', value: '40296423', company_id:40296423, type: 'company' },
  { title: 'Le Grand Bleu', value: '40305974', company_id:40305974, type: 'company' },
  { title: 'Barcando Charter', value: '40316275', company_id:40316275, type: 'company' },
  { title: 'Relax Sailing', value: '40508703', company_id:40508703, type: 'company' },
  { title: 'Sirocco Yachting', value: '40508738', company_id:40508738, type: 'company' },
  { title: 'Sunlife Charter', value: '40590204', company_id:40590204, type: 'company' },
  { title: 'Say Charter', value: '40705513', company_id:40705513, type: 'company' },
  { title: 'Phillip Yachtsun Charter ', value: '40705532', company_id:40705532, type: 'company' },
  { title: 'Charter Smarter', value: '40896013', company_id:40896013, type: 'company' },
  { title: 'Sail on Wave', value: '41251487', company_id:41251487, type: 'company' },
  { title: 'My Olivia', value: '41329173', company_id:41329173, type: 'company' },
  { title: 'Deepsea Yacht Charter', value: '41658917', company_id:41658917, type: 'company' },
  { title: 'Sea-Alliance Group', value: '41658919', company_id:41658919, type: 'company' },
  { title: 'Charter Adriatic sea Tribunj', value: '41924850', company_id:41924850, type: 'company' },
  { title: 'BlackSails-Yachting', value: '42103675', company_id:42103675, type: 'company' },
  { title: 'Charter Team', value: '42105914', company_id:42105914, type: 'company' },
];



