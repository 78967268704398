import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useTranslation } from 'react-i18next';
import './i18n.js';

export function Footer() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { t } = useTranslation();
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#F6FAFB",
        p: 6,
        border: "1px solid #A7DBEA !important"
      }}
    >
      <Container sx={{}}>
        <Grid container spacing={5}  >
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="#1D3A58 !important" gutterBottom>
              {t("About Us")}
            </Typography>
            <Typography variant="body2" color="#1D3A58 !important">
              {t("We are PapaSailor s.r.o. company providing the best possible service on the boat chartering field.")}
            </Typography>
            <br></br>
            <Typography variant="h6" color="#1D3A58 !important" gutterBottom>
              {t("Card Payment")}
            </Typography>
            <Box sx={{
              marginTop: -1,
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center', // Vertically centers the content
              justifyContent: 'center' // Horizontally centers the content
            }}>
              
              <img src="https://papasailor.com/GP.png" alt="Description" style={{ maxWidth: '100%', maxHeight: '100%' }} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="#1D3A58 !important" gutterBottom>
              {t("Contact Us")}
            </Typography>
            <Typography color="#1D3A58 !important">
              <h3>PapaSailor s.r.o.</h3>
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Pezinská 13, Nitra, Slovakia
            </Typography>
            <Typography variant="body2" color="text.secondary">
              info@papasailor.com
            </Typography>
            <Link href="https://kapitan.sk" color="inherit" target="_blank">
             kapitan.sk
            </Link>
            <Typography variant="body2" color="text.secondary">
              +421 904 919 173
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="#1D3A58 !important" gutterBottom>
              {t("Follow Us")}
            </Typography>
            <Link href="https://www.facebook.com/profile.php?id=61555783273946" color="inherit" target="_blank">
              <Facebook />
            </Link>
            <Link
              href="https://www.instagram.com/kapitan.sk/"
              color="inherit"
              sx={{ pl: 1, pr: 1 }}
              target="_blank"
            >
              <Instagram />
            </Link>
            <Link
              href="https://discord.gg/9ZQNMvtUhV"
              color="inherit"
              sx={{ pl: 1, pr: 1 }}
              target="_blank"
            >
           
                <img srcSet={`${process.env.PUBLIC_URL}/discord.svg`} width="29" sx={{marginTop: "10px !important"}}/> 
            
       
            </Link>

          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" color="text.secondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://papasailor.com/">
              PapaSailor.com
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}