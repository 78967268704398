import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Masonry from '@mui/lab/Masonry';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import SailingTwoToneIcon from '@mui/icons-material/SailingTwoTone';
import './i18n.js';
import { useTranslation } from 'react-i18next';
import HandshakeTwoToneIcon from '@mui/icons-material/HandshakeTwoTone';

const items = [
  { height: '10vw', imageUrl: '/images/charter_logos/dream.png', link: "#", text: '' },
  { height: '10vw', imageUrl: '/images/charter_logos/navigare.png', link: '#', text: '' },
  { height: '10vw', imageUrl: '/images/charter_logos/nautic.png', link: '#', text: '' },
  { height: '10vw', imageUrl: '/images/charter_logos/kiria.png', link: '#', text: '' },
  { height: '10vw', imageUrl: '/images/charter_logos/angelina.png', link: '#', text: '' },
  { height: '10vw', imageUrl: '/images/charter_logos/istion.png', link: '#', text: '' },
  { height: '10vw', imageUrl: '/images/charter_logos/croatiayachting.png', link: '#', text: '' },
  { height: '10vw', imageUrl: '/images/charter_logos/mg.png', link: '#', text: '' },
  // Pridajte ďalšie objekty podľa potreby...
];

const Item = styled(Box)(({ theme, imageUrl }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  backgroundPosition: 'center',
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  display: 'flex',
  overflow: 'hidden',
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: 'cover',
  border: 'solid 1px #DFE0E1 !important', // Corrected the typo here
  borderRadius: '5px', // Added border-radius
  minHeight: '150px'

}));

export function CharterGrid() {
  const { t } = useTranslation();
  return (
    <Grid sx={{ width: '90vw'}}>
    
        <Typography sx={{ color: "#1D3A58 !important" }}>
        <HandshakeTwoToneIcon sx={{fontSize: 60, color: "#1D3A58 !important"}}></HandshakeTwoToneIcon>
          <h1 >{t("Covered by our partners")}</h1>
          <h3>{t("Few partners from our big list. Together we are providing the best service for our customers.")}</h3>

        </Typography>
 

    
        <Masonry columns={{ xs: 1, sm: 2, md: 4 }} spacing={2}>
          {items.map((item, index) => (
            <Item
              key={index}
              sx={{ height: item.height }}
              imageUrl={item.imageUrl}
            >
  
            </Item>
          ))}
        </Masonry>
   
    </Grid>
  );
}
