import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useState } from 'react';

//translate
import { useTranslation } from 'react-i18next';
import './i18n.js';


export function SailTypeSelect({ selectedSail, setSelectedSail }) {
  const [inputValueSail, setInputValueSail] = useState("");
  const { t } = useTranslation();
  const sail = [
    { title: t('furling/roll'), value: 'furling/roll' },
    { title: t('classic/standard'), value: 'classic/standard' },
    { title: t('full batten'), value: 'full batten' },
    { title: t('None'), value: 'None' },

  ];
  return (
    <Box id="cSlider">
      <Autocomplete

        multiple
        id="tags-outlined"
        options={sail}
        getOptionLabel={(option) => option.title}
        value={selectedSail}
        inputValue={inputValueSail}
        onInputChange={(event, newInputValue) => setInputValueSail(newInputValue)}
        onChange={(event, newValue) => {
          setSelectedSail(newValue);
          if (!newValue.length) {
            setInputValueSail("");
          }
        }}
        filterSelectedOptions
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            {option.label} {option.title}
          </Box>
        )}
        renderInput={(params) => (
          <TextField {...params} label={t("Sail type")} placeholder={t("Sail type")} 
          sx={{
            "& .MuiInputBase-root": {
              backgroundColor: "white", // Set the background color of the input field
              '& fieldset': {
                borderColor: '#B2E0FA', // Default border color
              },
              '&:hover fieldset': {
                borderColor: '#D8D9DA', // Hover border color
              },
              '&.Mui-focused fieldset': {
                borderColor: '#D8D9DA', // Border color when the input is focused
              },
            },
          }} />
        )}
      />
    </Box>
  );
}














