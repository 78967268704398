import { useTranslation } from 'react-i18next';
import './i18n.js';
import SailingTwoToneIcon from '@mui/icons-material/SailingTwoTone';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

export function LandingPage() {
  const { t } = useTranslation();
  return (
    <Box sx={{ width: '80%' }}>
      <Typography sx={{ color: "#1D3A58 !important" }}>
        <SailingTwoToneIcon sx={{ fontSize: 40, color: "#1D3A58 !important" }}></SailingTwoToneIcon>
        <Typography >
          <h1>{t("Explore yachts in favorite destinations.")}</h1>
        </Typography>
        <Typography sx={{ color: "#5C5C5C !important" }}>
          <h3>{t("Yachts on our page are for the best prices and they are really available, We do not have click-bait in our DNA.")}</h3>
        </Typography>
      </Typography>
    </Box>
  );
}